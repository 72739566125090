import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getFirestore, collection as firestoreCollection, query, where, orderBy, doc, getDoc, getDocs, limit, startAfter, writeBatch, deleteDoc, onSnapshot, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { User, Building2, ChevronRight, Bell, Clock, Trash2, MoreVertical } from 'lucide-react';
import { COUNTRY_MAPPING } from '../constants/countries';
import { db } from '../config/firebase';
import { toast } from 'react-hot-toast';
import { saveMonitoredEntity, checkApiLimits } from '../services/api';
import { formatName, getResultTitle } from '../utils/formatters';
import { API_URL } from '../services/api';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import SearchFilter from "./SearchFilter";
import { debounce } from 'lodash';

const HistoryItemMenu = ({ item, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" onClick={e => e.stopPropagation()} ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-1 rounded-full hover:bg-gray-100"
      >
        <MoreVertical className="w-5 h-5 text-gray-500" />
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            <button
              onClick={() => {
                onDelete(item.id);
                setIsOpen(false);
              }}
              className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const SearchHistoryDashboard = ({ user, onHistoryItemClick }) => {
    const [searchHistory, setSearchHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');

    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const ITEMS_PER_PAGE = 10;
  
    // Add new state variables for search optimization
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [allItems, setAllItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
  
    // Add new state for search loading
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    
    // Add ref for storing unsubscribe function and state for forcing re-render
    const unsubscribeRef = useRef(null);
    const [reloadListener, setReloadListener] = useState(false);
    
    // Add state to track recently deleted IDs
    const [recentlyDeletedIds, setRecentlyDeletedIds] = useState(new Set());
  
    // Add new state variables for team functionality
    const [isTeamMember, setIsTeamMember] = useState(false);
    const [teamId, setTeamId] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
  
    const auth = getAuth();
    
    // Add new useEffect for the real-time listener
    useEffect(() => {
      if (!user?.uid || isSearchMode) {
        // Clean up previous listener if it exists
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
          unsubscribeRef.current = null;
        }
        return;
      }

      // First check if user is part of a team
      const checkTeamMembership = async () => {
        try {
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          const userIsTeamMember = userDoc.exists() && userDoc.data()?.teamId;
          const userTeamId = userIsTeamMember ? userDoc.data().teamId : null;
          
          setIsTeamMember(userIsTeamMember);
          setTeamId(userTeamId);
          
          if (userIsTeamMember) {
            // Get team data
            const teamRef = doc(db, 'teams', userTeamId);
            const teamDoc = await getDoc(teamRef);
            if (teamDoc.exists()) {
              setTeamMembers(teamDoc.data().members || []);
            }
          }
          
          // Now set up the appropriate listener
          setupHistoryListener(userIsTeamMember, userTeamId);
        } catch (error) {
          console.error('Error checking team membership:', error);
          // Fall back to regular user history
          setupHistoryListener(false, null);
        }
      };
      
      checkTeamMembership();
      
      // Clean up the listener when the component unmounts or filter changes
      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
          unsubscribeRef.current = null;
        }
      };
      
    }, [user?.uid, filter, isSearchMode, ITEMS_PER_PAGE, reloadListener]);

    // Add new function to set up the appropriate history listener
    const setupHistoryListener = (userIsTeamMember, userTeamId) => {
      setLoading(true);
      
      if (userIsTeamMember) {
        // For team members, we'll use fetchTeamHistory instead of real-time listener
        // because combining multiple collections with real-time updates is complex
        fetchTeamHistory(userTeamId);
      } else {
        // Regular user history with real-time listener
        let historyQuery;
        
        if (filter === 'all') {
          historyQuery = query(
            firestoreCollection(db, 'users', user.uid, 'searches'),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
        } else {
          historyQuery = query(
            firestoreCollection(db, 'users', user.uid, 'searches'),
            where('searchType', '==', filter),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
        }

        // Set up real-time listener
        const unsubscribe = onSnapshot(historyQuery, async (snapshot) => {
          try {
            // Filter out any recently deleted documents
            const filteredDocs = snapshot.docs.filter(doc => !recentlyDeletedIds.has(doc.id));
            
            const historyItems = filteredDocs.map(doc => {
              const data = doc.data();
              
              return {
                id: doc.id,
                ...data,
                // Simply use the direct fields
                timestamp: data.timestamp?.toDate?.() || data.timestamp,
                memberName: user.displayName || 'You'
              };
            });
            
            // All documents use direct storage structure
            setDisplayedItems(historyItems);
            setSearchHistory(historyItems);
            
            if (filteredDocs.length > 0) {
              setLastDoc(filteredDocs[filteredDocs.length - 1]);
            }
            
            setHasMore(filteredDocs.length === ITEMS_PER_PAGE);
            setLoading(false);
            
          } catch (error) {
            console.error('Error processing search history data:', error);
            setLoading(false);
          }
        }, (error) => {
          console.error('Error in search history subscription:', error);
          toast.error('Failed to load search history');
          setLoading(false);
        });

        // Store the unsubscribe function in the ref
        unsubscribeRef.current = unsubscribe;
      }
    };
    
    // Add fetchTeamHistory function for team members
    const fetchTeamHistory = async (teamId) => {
      try {
        // Get team data
        const teamRef = doc(db, 'teams', teamId);
        const teamDoc = await getDoc(teamRef);
        const teamData = teamDoc.data();
        const memberIds = teamData.members.map(member => member.id);
        
        // Create an array of promises for all members' history
        const historyPromises = memberIds.map(memberId => {
          let memberCollection;
          
          if (filter === 'all') {
            memberCollection = firestoreCollection(db, 'users', memberId, 'searches');
          } else {
            memberCollection = query(
              firestoreCollection(db, 'users', memberId, 'searches'),
              where('searchType', '==', filter)
            );
          }
    
          const memberQuery = query(
            memberCollection,
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE * 2) // Fetch more to ensure we have enough after combining
          );
          
          return getDocs(memberQuery);
        });
        
        const historySnapshots = await Promise.all(historyPromises);
        
        // Combine all members' history items
        let combinedHistory = [];
        historySnapshots.forEach((snapshot, index) => {
          const memberId = memberIds[index];
          // Filter out any recently deleted documents
          const filteredDocs = snapshot.docs.filter(doc => !recentlyDeletedIds.has(doc.id));
          
          const memberItems = filteredDocs.map(doc => {
            const data = doc.data();
            const memberInfo = teamData.members.find(m => m.id === memberId);
            return {
              id: doc.id,
              ...data,
              timestamp: data.timestamp?.toDate?.() || data.timestamp,
              memberId: memberId,
              memberName: memberInfo?.name || (memberId === user.uid ? 'You' : 'Team Member')
            };
          });
          combinedHistory = [...combinedHistory, ...memberItems];
        });
        
        // Sort combined history by timestamp
        combinedHistory.sort((a, b) => {
          if (!a.timestamp) return 1;
          if (!b.timestamp) return -1;
          return b.timestamp - a.timestamp;
        });
        
        // Store the full combined history for pagination
        const allCombinedHistory = [...combinedHistory];
        
        // Take only the first ITEMS_PER_PAGE items for display
        setDisplayedItems(combinedHistory.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(combinedHistory.slice(0, ITEMS_PER_PAGE));
        
        // Store all items for client-side pagination
        setAllItems(allCombinedHistory);
        
        // Set hasMore based on whether there are more items
        setHasMore(combinedHistory.length > ITEMS_PER_PAGE);
        
        setLoading(false);
        
        // Schedule a refresh every minute to keep team history updated
        const refreshInterval = setInterval(() => {
          setReloadListener(prev => !prev); // Toggle to force reload
        }, 60000);
        
        return () => clearInterval(refreshInterval);
      } catch (error) {
        console.error('Error fetching search history:', error);
        toast.error('Failed to load search history');
        setLoading(false);
      }
    };

    // Update fetchAllForSearch to handle the current search query
    const fetchAllForSearch = async (currentQuery) => {
      try {
        setLoading(true);
        
        const allItemsQuery = query(
          firestoreCollection(db, 'users', user.uid, 'searches'),
          orderBy('timestamp', 'desc')
        );
        
        const querySnapshot = await getDocs(allItemsQuery);
        const items = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            timestamp: data.timestamp?.toDate?.() || data.timestamp,
            searchType: data.searchType,
            individualName: data.individualName || '',
            companyName: data.companyName || '',
            organization: data.organization || '',
            designation: data.designation || '',
            dob: data.dob || '',
            gender: data.gender || '',
            country: data.country || '🌍 Global',
            matchThreshold: data.matchThreshold || 75,
            alerts: data.alerts || 0,
            hasResults: !!data.results?.length
          };
        });

        setAllItems(items);
        // Use the passed query parameter instead of searchQuery state
        const filteredItems = filterItems(items, filter, currentQuery);
        setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(filteredItems.slice(0, ITEMS_PER_PAGE));
        setHasMore(filteredItems.length > ITEMS_PER_PAGE);
        
      } catch (error) {
        console.error('Error fetching search data:', error);
        toast.error('Failed to load search data');
      } finally {
        setLoading(false);
      }
    };

    // Update loadMore to handle team pagination
    const loadMore = async () => {
      if (!hasMore || isLoadingMore) return;
      setIsLoadingMore(true);
      
      try {
        if (isSearchMode) {
          const currentLength = displayedItems.length;
          const filteredItems = filterItems(allItems);
          const nextItems = filteredItems.slice(currentLength, currentLength + ITEMS_PER_PAGE);
          
          setDisplayedItems(prev => [...prev, ...nextItems]);
          setSearchHistory(prev => [...prev, ...nextItems]); // Keep for backward compatibility
          setHasMore(currentLength + ITEMS_PER_PAGE < filteredItems.length);
        } else if (isTeamMember) {
          // For team members, we're doing client-side pagination
          const currentLength = displayedItems.length;
          const nextItems = allItems.slice(currentLength, currentLength + ITEMS_PER_PAGE);
          
          setDisplayedItems(prev => [...prev, ...nextItems]);
          setSearchHistory(prev => [...prev, ...nextItems]);
          setHasMore(currentLength + ITEMS_PER_PAGE < allItems.length);
        } else {
          // Original pagination for non-team users
          let moreHistoryQuery;
          
          if (filter === 'all') {
            moreHistoryQuery = query(
              firestoreCollection(db, 'users', user.uid, 'searches'),
              orderBy('timestamp', 'desc'),
              startAfter(lastDoc),
              limit(ITEMS_PER_PAGE)
            );
          } else {
            moreHistoryQuery = query(
              firestoreCollection(db, 'users', user.uid, 'searches'),
              where('searchType', '==', filter),
              orderBy('timestamp', 'desc'),
              startAfter(lastDoc),
              limit(ITEMS_PER_PAGE)
            );
          }
          
          const querySnapshot = await getDocs(moreHistoryQuery);
          
          // Get data directly from documents
          const moreHistory = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              timestamp: data.timestamp?.toDate?.() || data.timestamp,
              memberName: user.displayName || 'You'
            };
          });

          // All items use direct structure
          setDisplayedItems(prev => [...prev, ...moreHistory]);
          setSearchHistory(prev => [...prev, ...moreHistory]);
          
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
        }
      } catch (error) {
        console.error('Error loading more items:', error);
        toast.error('Failed to load more items');
      } finally {
        setIsLoadingMore(false);
      }
    };

    // Update handleSearchChange to handle single-letter searches
    const handleSearchChange = (query) => {
      setSearchQuery(query);
      
      // Always filter if we have data, regardless of query length
      if (isSearchMode && allItems.length > 0) {
        const filteredItems = filterItems(allItems, filter, query);
        setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(filteredItems.slice(0, ITEMS_PER_PAGE));
        setHasMore(filteredItems.length > ITEMS_PER_PAGE);
      } else if (query.length > 0) { // Trigger search for any non-empty query
        debouncedSearch(query);
      } else {
        // Reset to normal view if query is empty
        setIsSearchMode(false);
        // The real-time listener will update the data
      }
    };

    // Update debouncedSearch to pass the current query
    const debouncedSearch = useCallback(
      debounce(async (query) => {
        if (query) {
          setIsSearchLoading(true);
          try {
            if (!isSearchMode) {
              setIsSearchMode(true);
              await fetchAllForSearch(query);
            }
          } finally {
            setIsSearchLoading(false);
          }
        } else {
          setIsSearchMode(false);
          // The real-time listener will handle data loading
        }
      }, 300),
      []  // Empty dependency array since we're passing query directly
    );

    // Update handleFilterChange to reset pagination
    const handleFilterChange = async (newFilter) => {
      setFilter(newFilter);
      setLastDoc(null); // Reset pagination
      setHasMore(true);
      
      if (isSearchMode) {
        const filteredItems = filterItems(allItems, newFilter, searchQuery);
        setDisplayedItems(filteredItems.slice(0, ITEMS_PER_PAGE));
        setSearchHistory(filteredItems.slice(0, ITEMS_PER_PAGE));
        setHasMore(filteredItems.length > ITEMS_PER_PAGE);
      } else {
        // The real-time listener will update when filter changes
      }
    };

    // Add filterItems function
    const filterItems = (items, currentFilter = filter, query = searchQuery) => {
      return items.filter(item => {
        const matchesFilter = currentFilter === 'all' || item.searchType === currentFilter;
        
        if (!query) return matchesFilter;
        
        const searchLower = query.toLowerCase();
        const matchesSearch = (item.searchType === 'individual' 
          ? item.individualName?.toLowerCase().includes(searchLower)
          : item.companyName?.toLowerCase().includes(searchLower)) ||
          item.country?.toLowerCase().includes(searchLower) ||
          item.organization?.toLowerCase().includes(searchLower) ||
          item.designation?.toLowerCase().includes(searchLower);
        
        return matchesFilter && matchesSearch;
      });
    };

    // Update initial useEffect to remove fetchHistory call
    useEffect(() => {
      if (!isSearchMode && user?.uid) {
        // The real-time listener will handle data loading
        // fetchHistory();
      }
    }, [user?.uid, filter]);

    // Handle click on a search history item
    const handleHistoryItemClick = async (historyItem, e) => {
      if (e?.target?.closest('[data-menu]')) {
        return;
      }

      // Check if any of the results are being monitored
      let monitoredResults = new Set();
      if (auth.currentUser && historyItem.results?.length > 0) {
        try {
          const monitoringRef = firestoreCollection(db, 'users', auth.currentUser.uid, 'monitoring');
          const monitoringSnapshot = await getDocs(monitoringRef);
          
          // Create a set of all monitored IDs
          monitoringSnapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.selectedResultId) {
              monitoredResults.add(data.selectedResultId);
            }
          });
          
          console.log('Found monitored results:', Array.from(monitoredResults));
        } catch (error) {
          console.error('Error checking monitoring status:', error);
        }
      }

      // Format results with IDs as before, but now also add monitoring status
      const resultsWithId = (historyItem.results || []).map(result => ({
        ...result,
        searchHistoryId: historyItem.id,  // Store search history ID separately
        id: result.id || `${historyItem.id}_${Math.random().toString(36).substr(2, 9)}`, // Keep original ID or generate unique one
        isMonitored: monitoredResults.has(result.id) // Add monitoring status
      }));

      // Pass the item to the parent component
      onHistoryItemClick({
        ...historyItem,
        results: resultsWithId,
        searchTimestamp: historyItem.timestamp
      });
    };

    const handleDeleteHistoryItem = async (itemId) => {
      try {
        const toastId = toast.loading('Deleting search...');
        
        // Add this ID to the recently deleted set
        setRecentlyDeletedIds(prev => new Set([...prev, itemId]));
        
        // Find the item to determine if it belongs to the current user
        const item = displayedItems.find(item => item.id === itemId);
        const isCurrentUserItem = !item?.memberId || item.memberId === user.uid;
        
        // Update UI state immediately
        setDisplayedItems(prev => prev.filter(item => item.id !== itemId));
        setSearchHistory(prev => prev.filter(item => item.id !== itemId));
        
        // If we're in search mode or team mode, also update allItems
        if (isSearchMode || isTeamMember) {
          setAllItems(prev => prev.filter(item => item.id !== itemId));
        }
        
        // Get auth token
        const idToken = await auth.currentUser.getIdToken();
        
        // Call backend API to delete the history item
        // If it's a team member's item and not the current user's, include the memberId
        const endpoint = isCurrentUserItem 
          ? `${API_URL}/api/search-history/${itemId}`
          : `${API_URL}/api/search-history/${itemId}?memberId=${item.memberId}`;
        
        const response = await fetch(endpoint, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to delete search history item');
        }
        
        toast.success('Search deleted', { id: toastId });
        
        // Remove from recently deleted after 10 seconds
        setTimeout(() => {
          setRecentlyDeletedIds(prev => {
            const newSet = new Set([...prev]);
            newSet.delete(itemId);
            return newSet;
          });
        }, 10000);
      } catch (error) {
        console.error('Error deleting history item:', error);
        toast.error('Failed to delete search');
        // Remove from recently deleted on error
        setRecentlyDeletedIds(prev => {
          const newSet = new Set([...prev]);
          newSet.delete(itemId);
          return newSet;
        });
      }
    };

    const handleClearHistory = async () => {
      try {
        const toastId = toast.loading('Clearing search history...');
        
        // Get the current list of IDs before deletion
        // For team view, only clear the current user's searches
        const currentIds = new Set(
          isTeamMember 
            ? displayedItems
                .filter(item => !item.memberId || item.memberId === user.uid)
                .map(item => item.id)
            : displayedItems.map(item => item.id)
        );
        
        // Set these as recently deleted
        setRecentlyDeletedIds(currentIds);
        
        // Update UI immediately - remove only the current user's items in team view
        if (isTeamMember) {
          setDisplayedItems(prev => prev.filter(item => item.memberId && item.memberId !== user.uid));
          setSearchHistory(prev => prev.filter(item => item.memberId && item.memberId !== user.uid));
          
          if (isSearchMode) {
            setAllItems(prev => prev.filter(item => item.memberId && item.memberId !== user.uid));
          }
        } else {
          // Clear all items for regular users
          setDisplayedItems([]);
          setSearchHistory([]);
          
          if (isSearchMode) {
            setAllItems([]);
            setIsSearchMode(false);
            setSearchQuery('');
          }
        }
        
        // Reset pagination for non-team users
        if (!isTeamMember) {
          setLastDoc(null);
          setHasMore(false);
        } else {
          // For team users, update hasMore based on remaining items
          setHasMore(displayedItems.length > ITEMS_PER_PAGE);
        }
        
        // Get auth token and make API call
        const idToken = await auth.currentUser.getIdToken();
        const endpoint = isTeamMember 
          ? `${API_URL}/api/search-history?teamView=true` 
          : `${API_URL}/api/search-history`;
          
        const response = await fetch(endpoint, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to clear search history');
        }
        
        toast.success(isTeamMember ? 'Your search history cleared' : 'Search history cleared', { id: toastId });
        
        // Clear the tracking after 10 seconds
        setTimeout(() => {
          setRecentlyDeletedIds(new Set());
        }, 10000);
        
      } catch (error) {
        console.error('Error clearing search history:', error);
        toast.error('Failed to clear search history');
        setRecentlyDeletedIds(new Set()); // Clear on error
      }
    };

    const filteredHistory = searchHistory.filter(item => {
      const matchesFilter = filter === 'all' || item.searchType === filter;
      
      if (!searchQuery) return matchesFilter;
      
      const searchLower = searchQuery.toLowerCase();
      const matchesSearch = (item.searchType === 'individual' 
        ? item.individualName?.toLowerCase().includes(searchLower)
        : item.companyName?.toLowerCase().includes(searchLower)) ||
        item.country?.toLowerCase().includes(searchLower) ||
        item.organization?.toLowerCase().includes(searchLower) ||
        item.designation?.toLowerCase().includes(searchLower);
      
      return matchesFilter && matchesSearch;
    });
  
    return (
      <div className="bg-white rounded-2xl shadow-sm border p-6">
        <div className="opacity-0 animate-fade-in">
          {/* Header with responsive spacing */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
            <div className="flex items-center mb-4 sm:mb-0">
              <h2 className="text-2xl font-semibold">
                Search History
              </h2>
              {searchHistory.length > 0 && (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <button 
                      className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-0"
                    >
                      <Trash2 className="w-4 h-4 text-red-600" />
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Clear Search History</AlertDialogTitle>
                      <AlertDialogDescription>
                        This will permanently delete your search history. This action cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel 
                        onPointerDown={(e) => e.preventDefault()}
                        onClick={() => {
                          // Reset any state or side effects here if needed
                        }}
                      >
                        Cancel
                      </AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleClearHistory}
                        className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                      >
                        Clear History
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
            </div>
            
            {/* Filter section - updated with search bar */}
            <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
              <SearchFilter 
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search"
                isLoading={isSearchLoading}
                className="border-gray-200"
              />
              <div className="flex gap-2 overflow-x-auto sm:overflow-visible">
                <button
                  onClick={() => handleFilterChange('all')}
                  className={`flex-none px-4 py-2 rounded-lg text-sm ${
                    filter === 'all' 
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  All
                </button>
                <button
                  onClick={() => handleFilterChange('individual')}
                  className={`flex-none px-4 py-2 rounded-lg text-sm ${
                    filter === 'individual'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Individuals
                </button>
                <button
                  onClick={() => handleFilterChange('company')}
                  className={`flex-none px-4 py-2 rounded-lg text-sm ${
                    filter === 'company'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Companies
                </button>
              </div>
            </div>
          </div>
  
          {/* History Items */}
          <div className="-mx-6 sm:mx-0">
            <div className="space-y-3">
              {/* Only show loading state on initial page load, not during search */}
              {loading && !isSearchMode ? (
                <div className="space-y-4">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="animate-pulse">
                      <div className="flex items-center p-4 sm:p-4 border-b sm:border sm:rounded-xl">
                        {/* Icon skeleton */}
                        <div className="flex-shrink-0">
                          <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                        </div>
                        {/* Content skeleton */}
                        <div className="ml-4 flex-1">
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                          <div className="mt-2 space-y-2">
                            <div className="h-3 bg-gray-200 rounded w-1/4"></div>
                            <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                          </div>
                        </div>
                        {/* Actions skeleton */}
                        <div className="flex items-center gap-2">
                          <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                          <div className="w-5 h-5 bg-gray-200 rounded"></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : displayedItems.length === 0 ? (
                <div className="text-center py-8">
                  <Clock className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No search history</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Your past searches will appear here
                  </p>
                </div>
              ) : (
                displayedItems.map((search) => {
                  return (
                    <div
                      key={search.id}
                      onClick={(e) => handleHistoryItemClick(search, e)}
                      className="w-full flex items-center p-4 sm:p-4 hover:bg-gray-100 transition-colors text-left border-b sm:border sm:rounded-xl cursor-pointer"
                    >
                      {/* Icon */}
                      <div className="flex-shrink-0">
                        {search.searchType === 'individual' ? (
                          <User className="w-10 h-10 text-gray-400" />
                        ) : (
                          <Building2 className="w-10 h-10 text-gray-400" />
                        )}
                      </div>
    
                      {/* Content */}
                      <div className="ml-4 flex-1 min-w-0">
                        <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                          {search.searchType === 'individual' ? formatName(search.individualName) : formatName(search.companyName)}
                        </h3>
                        {/* Mobile layout */}
                        <div className="flex flex-col gap-1 sm:hidden mt-1 text-sm text-gray-500">
                          <time className="truncate">
                            {search.timestamp instanceof Date && search.timestamp.toLocaleDateString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric'
                            })}
                          </time>
                          <span className="px-2 py-1 bg-gray-100 rounded-lg w-fit">
                            {search.country}
                          </span>
                          {isTeamMember && search.memberName && search.memberId !== user.uid && (
                            <span className="text-blue-600 font-medium">
                              by {search.memberName}
                            </span>
                          )}
                          {search.alerts > 0 && (
                            <span className="flex items-center text-red-600 w-fit">
                              ⚠️ {search.alerts} {search.alerts === 1 ? 'alert' : 'alerts'}
                            </span>
                          )}
                        </div>
                        {/* Desktop layout */}
                        <div className="hidden sm:flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                          <time className="truncate">
                            {search.timestamp instanceof Date && search.timestamp.toLocaleDateString('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric'
                            })}
                            <span className="inline">
                              {' at ' + search.timestamp.toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                              })}
                            </span>
                          </time>
                          <span className="px-2 py-1 bg-gray-100 rounded-lg truncate">
                            {search.country}
                          </span>
                          {isTeamMember && search.memberName && search.memberId !== user.uid && (
                            <span className="px-2 py-1 bg-blue-50 text-blue-600 rounded-lg truncate">
                              by {search.memberName}
                            </span>
                          )}
                          {search.alerts > 0 && (
                            <span className="flex items-center text-red-600">
                              ⚠️ {search.alerts} {search.alerts === 1 ? 'alert' : 'alerts'}
                            </span>
                          )}
                        </div>
                      </div>
    
                      {/* Replace the existing Chevron section with this: */}
                      <div className="flex items-center">
                        <div data-menu>
                          <HistoryItemMenu 
                            item={search}
                            onDelete={handleDeleteHistoryItem}
                          />
                        </div>
                        <div className="cursor-pointer">
                          <ChevronRight className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {/* Add Load More button here */}
          {!loading && displayedItems.length > 0 && hasMore && (
            <div className="flex justify-center py-4">
              <button
                onClick={loadMore}
                disabled={isLoadingMore}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50"
              >
                {isLoadingMore ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  export default SearchHistoryDashboard;