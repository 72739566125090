import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MoreVertical, AlertCircle, User, Building2, Bell, ChevronRight, Trash2, Check, Search } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  addDoc,
  writeBatch,
  orderBy,
  startAfter,
  limit,
  getDoc
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { auth } from '../config/firebase';
import { toast } from 'react-hot-toast';
import { API_URL } from '../services/api';
import { COUNTRY_MAPPING } from '../constants/countries';
import { getResultTitle } from '../utils/formatters';
import NotificationBubble from "./ui/NotificationBubble";
import SearchFilter from "./SearchFilter";
import { debounce } from 'lodash';
// Import the new NotificationsPanel component
import NotificationsPanel from './NotificationsPanel';

const MonitoringDashboard = ({ user, onMonitorItemClick }) => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const [monitoredItems, setMonitoredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const ITEMS_PER_PAGE = 10;

  // Add new state variables for search
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);

  // Add new state for search loading
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  
  // Add unsubscribe reference and reload listener
  const unsubscribeRef = useRef(null);
  const [reloadListener, setReloadListener] = useState(false);

  // Add this state to track recently deleted IDs
  const [recentlyDeletedIds, setRecentlyDeletedIds] = useState(new Set());

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Check if user is part of a team
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const isTeamMember = userDoc.exists() && userDoc.data()?.teamId;
      const teamId = isTeamMember ? userDoc.data().teamId : null;
      
      if (isTeamMember) {
        // User is part of a team - get both user and team monitoring
        const teamRef = doc(db, 'teams', teamId);
        const teamDoc = await getDoc(teamRef);
        const teamData = teamDoc.data();
        const memberIds = teamData.members.map(member => member.id);
        
        // Create an array of promises for all members' monitoring
        const monitoringPromises = memberIds.map(memberId => {
          const memberCollection = filter === 'all'
            ? collection(db, 'users', memberId, 'monitoring')
            : query(
                collection(db, 'users', memberId, 'monitoring'),
                where('searchType', '==', filter)
              );

          return getDocs(memberCollection);
        });
        
        const monitoringSnapshots = await Promise.all(monitoringPromises);
        
        // Combine all members' monitoring items
        let combinedItems = [];
        monitoringSnapshots.forEach((snapshot, index) => {
          const memberId = memberIds[index];
          const memberItems = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              timestamp: data.timestamp?.toDate?.() || data.timestamp,
              lastChecked: data.lastChecked?.toDate?.() || data.lastChecked,
              memberId: memberId,
              memberName: teamData.members.find(m => m.id === memberId)?.name || 'Team Member'
            };
          });
          combinedItems = [...combinedItems, ...memberItems];
        });
        
        // Sort combined items by timestamp
        combinedItems.sort((a, b) => {
          if (!a.timestamp) return 1;
          if (!b.timestamp) return -1;
          return b.timestamp - a.timestamp;
        });
        
        // Sort items so that entities with notifications appear at the top
        const sortedItems = sortItemsByNotifications(combinedItems);
        
        setDisplayedItems(sortedItems);
        setMonitoredItems(sortedItems);
        
        // Set lastDoc for pagination (not used in team mode)
        setLastDoc(null);
        
        // For team monitoring, client-side pagination may be more appropriate
        setHasMore(false);
      } else {
        // Original query code for non-team users
        let monitoringQuery;
        
        if (filter === 'all') {
          monitoringQuery = query(
            collection(db, 'users', user.uid, 'monitoring'),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
        } else {
          monitoringQuery = query(
            collection(db, 'users', user.uid, 'monitoring'),
            where('searchType', '==', filter),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
        }

        const monitoringSnapshot = await getDocs(monitoringQuery);
        const items = monitoringSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort items so that entities with notifications appear at the top
        const sortedItems = sortItemsByNotifications(items);
        
        setDisplayedItems(sortedItems);
        setMonitoredItems(sortedItems);
        setLastDoc(monitoringSnapshot.docs[monitoringSnapshot.docs.length - 1]);
        setHasMore(monitoringSnapshot.docs.length === ITEMS_PER_PAGE);
      }

      // Updated notifications fetch with detailed logging
      // console.log('Starting notifications fetch for user:', user.uid);
      
      const notificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        orderBy('timestamp', 'desc')
      );
      
      const notificationsSnapshot = await getDocs(notificationsQuery);
      console.log('Raw notifications snapshot:', {
        size: notificationsSnapshot.size,
        empty: notificationsSnapshot.empty
      });
      
      const newNotifications = notificationsSnapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Raw notification data:', data);
        
        return {
          id: doc.id,
          ...data,
          entityName: data.entityName,
          entityId: data.entityId,
          read: data.read || false,
          changes: {
            hasChanges: data.changes?.hasChanges || false,
            importantUpdates: data.changes?.importantUpdates || [],
            relatedRiskFactors: data.changes?.relatedRiskFactors || [],
            significantChanges: data.changes?.significantChanges || [],
            mediaUpdates: (data.changes?.mediaUpdates || []).map(update => ({
              id: update.id,
              name: update.name,
              mediaChanges: {
                added: update.mediaChanges?.added || [],
                significantChanges: update.mediaChanges?.significantChanges || []
              }
            })),
            added: data.changes?.added || [],
            removed: data.changes?.removed || [],
            message: data.changes?.message || 'Changes detected'
          },
          timestamp: data.timestamp?.toDate?.() || 
                    (data.timestamp?.seconds ? new Date(data.timestamp.seconds * 1000) : new Date())
        };
      });

      console.log('Processed notifications:', newNotifications);
      setNotifications(newNotifications);

    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load monitoring data');
    } finally {
      setLoading(false);
    }
  };

  // Update fetchAllForSearch to handle the current search query
  const fetchAllForSearch = async (currentQuery) => {
    try {
      setLoading(true);
      
      const allItemsQuery = query(
        collection(db, 'users', user.uid, 'monitoring'),
        orderBy('timestamp', 'desc')
      );
      
      const querySnapshot = await getDocs(allItemsQuery);
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setAllItems(items);
      // Use the passed query parameter instead of searchQuery state
      const filteredItems = filterItems(items, filter, currentQuery);
      
      // Sort filtered items so that entities with notifications appear at the top
      const sortedItems = sortItemsByNotifications(filteredItems);
      
      setDisplayedItems(sortedItems.slice(0, ITEMS_PER_PAGE));
      setMonitoredItems(sortedItems.slice(0, ITEMS_PER_PAGE));
      setHasMore(filteredItems.length > ITEMS_PER_PAGE);
      
    } catch (error) {
      console.error('Error fetching search data:', error);
      toast.error('Failed to load search data');
    } finally {
      setLoading(false);
    }
  };

  // Update loadMore function
  const loadMore = async () => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    
    try {
      if (isSearchMode) {
        const currentLength = displayedItems.length;
        const filteredItems = filterItems(allItems);
        
        // Sort all filtered items to ensure consistent ordering
        const sortedFilteredItems = sortItemsByNotifications(filteredItems);
        
        const nextItems = sortedFilteredItems.slice(currentLength, currentLength + ITEMS_PER_PAGE);
        
        setDisplayedItems(prev => [...prev, ...nextItems]);
        setMonitoredItems(prev => [...prev, ...nextItems]);
        setHasMore(currentLength + ITEMS_PER_PAGE < filteredItems.length);
      } else {
        let moreMonitoringQuery;
        
        if (filter === 'all') {
          moreMonitoringQuery = query(
            collection(db, 'users', user.uid, 'monitoring'),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(ITEMS_PER_PAGE)
          );
        } else {
          moreMonitoringQuery = query(
            collection(db, 'users', user.uid, 'monitoring'),
            where('searchType', '==', filter),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(ITEMS_PER_PAGE)
          );
        }
        
        const querySnapshot = await getDocs(moreMonitoringQuery);
        const moreItems = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Sort new items before adding them
        const sortedMoreItems = sortItemsByNotifications(moreItems);
        
        // Since we're loading more items, we need to sort the combined list
        // Get current items
        const currentItems = [...displayedItems];
        // Add new items
        const combinedItems = [...currentItems, ...sortedMoreItems];
        // Sort the entire list
        const sortedCombinedItems = sortItemsByNotifications(combinedItems);
        
        setDisplayedItems(sortedCombinedItems);
        setMonitoredItems(sortedCombinedItems);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
      }
    } catch (error) {
      console.error('Error loading more items:', error);
      toast.error('Failed to load more items');
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Update debouncedSearch to pass the current query
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query) {
        setIsSearchLoading(true);
        try {
          if (!isSearchMode) {
            setIsSearchMode(true);
            await fetchAllForSearch(query);
          }
        } finally {
          setIsSearchLoading(false);
        }
      } else {
        setIsSearchMode(false);
        await fetchData();
      }
    }, 300),
    []  // Empty dependency array since we're passing query directly
  );

  // Update handleSearchChange to handle single-letter searches
  const handleSearchChange = (query) => {
    setSearchQuery(query);
    
    // Always filter if we have data, regardless of query length
    if (isSearchMode && allItems.length > 0) {
      const filteredItems = filterItems(allItems, filter, query);
      
      // Sort filtered items so that entities with notifications appear at the top
      const sortedItems = sortItemsByNotifications(filteredItems);
      
      setDisplayedItems(sortedItems.slice(0, ITEMS_PER_PAGE));
      setMonitoredItems(sortedItems.slice(0, ITEMS_PER_PAGE));
      setHasMore(filteredItems.length > ITEMS_PER_PAGE);
    } else if (query.length > 0) { // Trigger search for any non-empty query
      debouncedSearch(query);
    } else {
      // Reset to normal view if query is empty
      setIsSearchMode(false);
      fetchData();
    }
  };

  // Update filter handler
  const handleFilterChange = async (newFilter) => {
    setFilter(newFilter);
    setLastDoc(null); // Reset pagination
    setHasMore(true);
    
    if (isSearchMode) {
      const filteredItems = filterItems(allItems, newFilter, searchQuery);
      
      // Sort filtered items so that entities with notifications appear at the top
      const sortedItems = sortItemsByNotifications(filteredItems);
      
      setDisplayedItems(sortedItems.slice(0, ITEMS_PER_PAGE));
      setMonitoredItems(sortedItems.slice(0, ITEMS_PER_PAGE));
      setHasMore(filteredItems.length > ITEMS_PER_PAGE);
    } else {
      await fetchData();
    }
  };

  // Add filterItems function
  const filterItems = (items, currentFilter = filter, query = searchQuery) => {
    return items.filter(item => {
      const matchesFilter = currentFilter === 'all' || item.searchType === currentFilter;
      
      if (!query) return matchesFilter;
      
      const searchLower = query.toLowerCase();
      const matchesSearch = (item.searchType === 'individual' 
        ? item.individualName?.toLowerCase().includes(searchLower)
        : item.companyName?.toLowerCase().includes(searchLower)) ||
        item.country?.toLowerCase().includes(searchLower) ||
        item.organization?.toLowerCase().includes(searchLower) ||
        item.designation?.toLowerCase().includes(searchLower);
      
      return matchesFilter && matchesSearch;
    });
  };

  // Update initial useEffect
  useEffect(() => {
    if (!isSearchMode && user?.uid) {
      fetchData();
    }
  }, [user?.uid, filter]);

  useEffect(() => {
    fetchData();
  }, [user.uid]);

  // Add a new useEffect that sets up a real-time listener for monitored items
  // This will ensure newly added entities appear immediately without requiring a refresh
  useEffect(() => {
    if (!user?.uid || isSearchMode) return;

    setLoading(true);
    
    let monitoringQuery;
    
    if (filter === 'all') {
      monitoringQuery = query(
        collection(db, 'users', user.uid, 'monitoring'),
        orderBy('timestamp', 'desc'),
        limit(ITEMS_PER_PAGE)
      );
    } else {
      monitoringQuery = query(
        collection(db, 'users', user.uid, 'monitoring'),
        where('searchType', '==', filter),
        orderBy('timestamp', 'desc'),
        limit(ITEMS_PER_PAGE)
      );
    }

    // Set up real-time listener
    const unsubscribe = onSnapshot(monitoringQuery, (snapshot) => {
      const items = snapshot.docs
        // Filter out recently deleted IDs
        .filter(doc => !recentlyDeletedIds.has(doc.id))
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      
      // Sort items so that entities with notifications appear at the top
      const sortedItems = sortItemsByNotifications(items);
      
      setDisplayedItems(sortedItems);
      setMonitoredItems(sortedItems);
      
      if (snapshot.docs.length > 0) {
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
      
      setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);
      setLoading(false);
    }, (error) => {
      console.error('Error in monitoring subscription:', error);
      toast.error('Failed to load monitoring data');
      setLoading(false);
    });

    // Store the unsubscribe function in the ref
    unsubscribeRef.current = unsubscribe;

    // Clean up the listener when the component unmounts or filter changes
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
    };
    
  }, [user?.uid, filter, isSearchMode, ITEMS_PER_PAGE, reloadListener, recentlyDeletedIds]);

  const handleMonitoringUpdate = async (itemId, frequency) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      
      // Update the document in Firestore
      await updateDoc(docRef, {
        frequency,
        lastUpdated: new Date()
      });
      
      // Helper function to update items in a state array
      const updateItemInArray = (items) => items.map(item => 
        item.id === itemId ? {
          ...item,
          frequency,
          lastUpdated: new Date() // Also update lastUpdated in local state
        } : item
      );
      
      // Update all relevant state variables
      setDisplayedItems(updateItemInArray);
      setMonitoredItems(updateItemInArray);
      
      // Update allItems if in search mode
      if (isSearchMode) {
        setAllItems(updateItemInArray);
      }
      
      toast.success('Monitoring frequency updated');
    } catch (error) {
      console.error('Error updating monitoring frequency:', error);
      toast.error('Failed to update monitoring frequency');
      
      // Refresh data in case of error to ensure consistency
      if (isSearchMode) {
        fetchAllForSearch(searchQuery);
      } else {
        fetchData();
      }
    }
  };

  const handleDelete = async (itemId) => {
    try {
      // Add item to recently deleted
      setRecentlyDeletedIds(prev => new Set([...prev, itemId]));
      
      // Show loading toast that can be updated
      const toastId = toast.loading('Removing entity...');
      
      // Get auth token
      const idToken = await auth.currentUser.getIdToken();
      
      // Call backend API to delete the monitored entity
      const response = await fetch(`${API_URL}/api/monitored-entity/${itemId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete monitored entity');
      }

      // Update both displayedItems and monitoredItems
      setDisplayedItems(prev => prev.filter(item => item.id !== itemId));
      setMonitoredItems(prev => prev.filter(item => item.id !== itemId));
      
      // Update notifications state - removes notifications for this entity
      setNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification.entityId !== itemId)
      );
      
      // Update toast to success
      toast.success('Entity removed from monitoring', { id: toastId });
      
      // Clear the tracking for this ID after 10 seconds
      setTimeout(() => {
        setRecentlyDeletedIds(prev => {
          const newSet = new Set([...prev]);
          newSet.delete(itemId);
          return newSet;
        });
      }, 10000);

    } catch (error) {
      console.error('Error deleting monitored item:', error);
      toast.error('Failed to remove entity from monitoring');
      
      // Remove from recently deleted on error
      setRecentlyDeletedIds(prev => {
        const newSet = new Set([...prev]);
        newSet.delete(itemId);
        return newSet;
      });
      
      // Refresh the data in case of error to ensure consistency
      fetchData();
    }
  };

  const formatFrequency = (frequency) => {
    return frequency.charAt(0).toUpperCase() + frequency.slice(1);
  };

  // Menu component updated with capitalized options
  const MonitoringMenu = ({ item, onRefresh }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTestingMonitor, setIsTestingMonitor] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const testMonitoring = async () => {
      try {
        setIsTestingMonitor(true);
        const toastId = toast.loading('Running monitoring check...');
        
        const idToken = await auth.currentUser.getIdToken();
        
        const response = await fetch(`${API_URL}/test-monitoring`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ entityId: item.id })
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Response not OK:', response.status, errorText);
          throw new Error(`Failed to test monitoring: ${response.status}`);
        }
        
        const data = await response.json();

        toast.dismiss(toastId);
        toast.success(data.result?.message || 'Monitoring check completed');
        
        if (onRefresh) onRefresh();
      } catch (error) {
        console.error('Error testing monitoring:', error);
        toast.error(`Failed to test monitoring: ${error.message}`);
      } finally {
        setIsTestingMonitor(false);
        setIsOpen(false);
      }
    };

    return (
      <div className="relative" onClick={e => e.stopPropagation()} ref={menuRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-1 rounded-full hover:bg-gray-100"
        >
          <MoreVertical className="w-5 h-5 text-gray-500" />
        </button>
        
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
            <div className="py-1">
              <button
                onClick={() => {
                  handleMonitoringUpdate(item.id, 'Daily');
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Monitor Daily
              </button>
              <button
                onClick={() => {
                  handleMonitoringUpdate(item.id, 'Weekly');
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Monitor Weekly
              </button>
              {isLocalhost && (
                <button
                  onClick={testMonitoring}
                  disabled={isTestingMonitor}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isTestingMonitor 
                      ? 'text-gray-400 cursor-not-allowed' 
                      : 'text-blue-600 hover:bg-gray-100'
                  }`}
                >
                  {isTestingMonitor ? 'Testing...' : 'Test Monitor Now'}
                </button>
              )}
              <button
                onClick={() => {
                  handleDelete(item.id);
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Add this helper function at the top level of the component
  const getDisplayCountry = (countryCode) => {
    if (!countryCode || countryCode === 'zz') {
      return '🌍 Global';
    }
    return COUNTRY_MAPPING[countryCode.toLowerCase()] || countryCode;
  };

  // Add function to mark notification as read
  const markNotificationAsRead = async (notificationId) => {
    try {
      const notificationRef = doc(db, 'users', user.uid, 'notifications', notificationId);
      await updateDoc(notificationRef, {
        read: true
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllNotificationsAsReadForItem = async (entityId) => {
    try {
      const stringEntityId = String(entityId || '').trim();
      console.log('Marking notifications as read for entity:', stringEntityId);
      
      // Get all unread notifications for this specific entity
      const notificationsRef = collection(db, 'users', user.uid, 'notifications');
      const q = query(notificationsRef, where('read', '==', false));
      const notificationsSnapshot = await getDocs(q);
      
      // Find notifications with matching entity ID
      const batch = writeBatch(db);
      let count = 0;
      
      notificationsSnapshot.docs.forEach(doc => {
        const notification = doc.data();
        const notificationEntityId = String(notification.entityId || '').trim();
        
        if (notificationEntityId === stringEntityId) {
          batch.update(doc.ref, { read: true });
          count++;
        }
      });
      
      // Reset alerts count to 0 since we're marking all as read
      const monitoringRef = doc(db, 'users', user.uid, 'monitoring', entityId);
      batch.update(monitoringRef, { alerts: 0 });
      
      await batch.commit();
      console.log(`Marked ${count} notifications as read for entity: ${stringEntityId}`);
      fetchData();
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const clearAlertsForItem = async (itemId) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      await updateDoc(docRef, {
        alerts: 0
      });
      // Refresh the list to show updated alerts count
      fetchData();
    } catch (error) {
      console.error('Error clearing alerts:', error);
    }
  };

  const handleMonitorItemClick = async (item) => {
    // console.log('Handling monitor item click:', {
    //   itemId: item.id,
    //   results: item.results
    // });

    // When clicking a monitored item, pass its comments, conclusion, and monitoring ID
    onMonitorItemClick({
      ...item,
      comments: item.comments || {},
      conclusion: item.conclusion || '',
      results: item.results?.map(result => ({
        ...result,
        monitoringId: item.id,
        comments: item.comments || {},
        conclusion: item.conclusion || ''
      }))
    });
  };

  // Add a helper function to check for unread notifications
  const hasUnreadNotifications = (itemId) => {
    const stringItemId = String(itemId || '').trim();
    return notifications.some(notification => {
      const notificationEntityId = String(notification.entityId || '').trim();
      return notificationEntityId === stringItemId && !notification.read;
    });
  };

  // Add a new function to sort items so entities with notifications appear at the top
  const sortItemsByNotifications = (items) => {
    return [...items].sort((a, b) => {
      // Check for unread notifications first
      const aHasUnreadNotifications = notifications.some(notification => 
        notification.entityId === a.id && !notification.read
      );
      const bHasUnreadNotifications = notifications.some(notification => 
        notification.entityId === b.id && !notification.read
      );
      
      if (aHasUnreadNotifications && !bHasUnreadNotifications) {
        return -1; // a comes before b
      } else if (!aHasUnreadNotifications && bHasUnreadNotifications) {
        return 1;  // b comes before a
      } else {
        // If both have or don't have unread notifications, sort by timestamp (newest first)
        const aTimestamp = a.timestamp?.seconds || 0;
        const bTimestamp = b.timestamp?.seconds || 0;
        return bTimestamp - aTimestamp;
      }
    });
  };

  // Add the getLastCheckedDate function that was accidentally removed
  const getLastCheckedDate = (item) => {
    if (!item.lastChecked) {
      return 'Not yet checked';
    }
    
    try {
      // Convert Firestore timestamp to JavaScript Date
      const date = item.lastChecked?.toDate?.() || 
                  (item.lastChecked?.seconds ? new Date(item.lastChecked.seconds * 1000) : 
                  (item.lastChecked instanceof Date ? item.lastChecked : new Date(item.lastChecked)));
      
      // Get today's date at midnight (removing time component)
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      
      // Get the date of the last check at midnight
      const lastCheckedDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      
      // Calculate the difference in calendar days
      const diffTime = today - lastCheckedDay;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays === 0) {
        return 'Today';
      } else if (diffDays === 1) {
        return 'Yesterday';
      } else if (diffDays < 7) {
        return `${diffDays} days ago`;
      } else {
        return date.toLocaleDateString();
      }
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date error';
    }
  };

  // Update the handleClearMonitoring function
  const handleClearMonitoring = async () => {
    try {
      const toastId = toast.loading('Clearing monitored entities...');
      
      // Get the current list of IDs before deletion
      const currentIds = new Set(displayedItems.map(item => item.id));
      
      // Set these as recently deleted
      setRecentlyDeletedIds(currentIds);
      
      // Temporarily unsubscribe from real-time updates to prevent immediate refetch
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
      
      // Clear UI state immediately
      setMonitoredItems([]);
      setDisplayedItems([]);
      setNotifications([]);
      
      // Also clear allItems if in search mode
      if (isSearchMode) {
        setAllItems([]);
      }
      
      // Get auth token
      const idToken = await auth.currentUser.getIdToken();
      
      // Call backend API to clear all monitored entities
      const response = await fetch(`${API_URL}/api/monitored-entities`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to clear monitored entities');
      }
      
      toast.success('All monitored entities cleared', { id: toastId });
      
      // Add a small delay before resubscribing to ensure all deletions are complete
      setTimeout(() => {
        // Force a re-render by updating a state variable
        setReloadListener(prev => !prev);
      }, 1000);
      
      // Clear the tracking after 10 seconds
      setTimeout(() => {
        setRecentlyDeletedIds(new Set());
      }, 10000);
      
    } catch (error) {
      console.error('Error clearing monitoring:', error);
      toast.error('Failed to clear monitored entities');
      setRecentlyDeletedIds(new Set()); // Clear on error
    }
  };

  // Update the deleteAllNotifications function
  const deleteAllNotifications = async () => {
    try {
      const toastId = toast.loading('Deleting all notifications...');
      
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/delete-all-notifications`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete notifications');
      }

      // Wait for response completion before updating state
      await response.json();
      
      // Update local state
      setNotifications([]);
      // setShowNotifications(false); // Close notifications panel
      await fetchData(); // Refresh monitoring items to show updated alert counts
      
      toast.success('All notifications deleted', { id: toastId });
    } catch (error) {
      console.error('Error deleting all notifications:', error);
      toast.error('Failed to delete notifications');
    }
  };

  // Add escape key handler for notifications panel
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && showNotifications) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [showNotifications]);

  // Update notifications fetch to use real-time listener and filter deleted IDs
  useEffect(() => {
    if (!user?.uid) return;

    const notificationsQuery = query(
      collection(db, 'users', user.uid, 'notifications'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(notificationsQuery, {
      next: (snapshot) => {
        const newNotifications = snapshot.docs
          .map(doc => {
            try {
              const data = doc.data();
              
              return {
                id: doc.id,
                ...data,
                entityName: data.entityName || 'Unknown Entity',
                entityId: data.entityId,
                read: Boolean(data.read),
                changes: data.changes || {}, // Preserve original changes structure
                timestamp: data.timestamp?.toDate?.() || new Date()
              };
            } catch (error) {
              console.error('Error processing notification:', doc.id, error);
              return null;
            }
          })
          .filter(Boolean)
          // Filter out notifications for recently deleted entities
          .filter(notification => !recentlyDeletedIds.has(notification.entityId));

        setNotifications(newNotifications);
      },
      error: (error) => {
        console.error('Error in notifications subscription:', error);
        toast.error('Error loading notifications');
      }
    });

    return () => unsubscribe();
  }, [user?.uid, recentlyDeletedIds]);

  const markAllNotificationsAsRead = async () => {
    try {
      const batch = writeBatch(db);
      const unreadNotificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        where('read', '==', false)
      );
      
      const unreadNotifications = await getDocs(unreadNotificationsQuery);
      
      unreadNotifications.forEach((doc) => {
        batch.update(doc.ref, { read: true });
      });
      
      await batch.commit();
      
      // Update monitored items to clear alert counts
      const monitoringDocs = await getDocs(
        collection(db, 'users', user.uid, 'monitoring')
      );
      
      const alertBatch = writeBatch(db);
      monitoringDocs.forEach((doc) => {
        alertBatch.update(doc.ref, { alerts: 0 });
      });
      
      await alertBatch.commit();
      await fetchData();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-sm border p-6">
      <div className="opacity-0 animate-fade-in">
        {/* Header with responsive spacing */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
          <div className="flex items-center mb-4 sm:mb-0">
            <h2 className="text-2xl font-semibold">Monitored Entities</h2>
            {notifications.length > 0 && (
              <button 
                onClick={() => setShowNotifications(!showNotifications)}
                className={`relative ml-2 flex items-center justify-center w-8 h-8 rounded-full transition-colors ${
                  notifications.filter(n => !n.read).length > 0 
                    ? 'bg-red-50 ring-2 ring-red-500 hover:bg-red-100' 
                    : 'bg-gray-100 hover:bg-gray-200'
                } focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
              >
                <Bell className={`w-4 h-4 ${
                  notifications.filter(n => !n.read).length > 0 ? 'text-red-600' : 'text-gray-600'
                }`} />
                {notifications.filter(n => !n.read).length > 0 && (
                  <span className={`absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full min-w-[20px] h-5 flex items-center justify-center px-1 ${
                    notifications.filter(n => !n.read).length > 99 ? 'text-[10px]' : 'text-xs'
                  }`}>
                    {notifications.filter(n => !n.read).length > 99 ? '99+' : notifications.filter(n => !n.read).length}
                  </span>
                )}
              </button>
            )}
            {monitoredItems.length > 0 && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button 
                    className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-0"
                  >
                    <Trash2 className="w-4 h-4 text-red-600" />
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Clear All Monitored Entities</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will permanently delete all your monitored entities and their notifications. This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel 
                      onPointerDown={(e) => e.preventDefault()}
                    >
                      Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={handleClearMonitoring}
                      className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                    >
                      Clear All
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
          
          {/* Filter section - updated with search bar */}
          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <SearchFilter 
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
              isLoading={isSearchLoading}
              className="border-gray-200"
            />
            <div className="flex gap-2 overflow-x-auto sm:overflow-visible">
              <button
                onClick={() => handleFilterChange('all')}
                className={`flex-none px-4 py-2 rounded-lg text-sm ${
                  filter === 'all' 
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                All
              </button>
              <button
                onClick={() => handleFilterChange('individual')}
                className={`flex-none px-4 py-2 rounded-lg text-sm ${
                  filter === 'individual'
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Individuals
              </button>
              <button
                onClick={() => handleFilterChange('company')}
                className={`flex-none px-4 py-2 rounded-lg text-sm ${
                  filter === 'company'
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Companies
              </button>
            </div>
          </div>
        </div>

        {/* Monitored Items */}
        <div className="-mx-6 sm:mx-0">
          <div className="space-y-3">
            {/* Only show loading state on initial page load, not during search */}
            {loading && !isSearchMode ? (
              <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="animate-pulse">
                    <div className="flex items-center p-4 sm:p-4 border-b sm:border sm:rounded-xl">
                      {/* Icon skeleton */}
                      <div className="flex-shrink-0">
                        <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                      </div>
                      {/* Content skeleton */}
                      <div className="ml-4 flex-1">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="mt-2 space-y-2">
                          <div className="h-3 bg-gray-200 rounded w-1/4"></div>
                          <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                        </div>
                      </div>
                      {/* Actions skeleton */}
                      <div className="flex items-center gap-2">
                        <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                        <div className="w-5 h-5 bg-gray-200 rounded"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : displayedItems.length === 0 ? (
              <div className="text-center py-8">
                <Bell className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No monitored entities yet</h3>
                <p className="mt-1 text-sm text-gray-500 max-w-[250px] mx-auto">
                  Run a search and click "Monitor" to start tracking an entity
                </p>
              </div>
            ) : (
              displayedItems.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="w-full flex items-center p-4 sm:p-4 hover:bg-gray-100 transition-colors text-left border-b sm:border sm:rounded-xl"
                  >
                    {/* Icon - Now with logging to debug */}
                    <div className="flex-shrink-0">
                      {item.searchType === 'individual' ? (
                        <User className="w-10 h-10 text-gray-400" />
                      ) : (
                        <Building2 className="w-10 h-10 text-gray-400" />
                      )}
                    </div>

                    {/* Content */}
                    <div 
                      className="ml-4 flex-1 min-w-0 cursor-pointer" 
                      onClick={() => {
                        markAllNotificationsAsReadForItem(item.id);
                        clearAlertsForItem(item.id);
                        handleMonitorItemClick(item);
                      }}
                    >
                      <div className="flex items-center space-x-2">
                        <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                          {item.searchType === 'individual' ? item.individualName : item.companyName}
                        </h3>
                        {hasUnreadNotifications(item.id) && (
                          <AlertCircle className="w-5 h-5 text-amber-500" />
                        )}
                      </div>
                      <div className="flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                        <span>Frequency: {formatFrequency(item.frequency)}</span>
                        <span>Last checked: {getLastCheckedDate(item)}</span>
                        <span className="px-2 py-1 bg-gray-100 rounded-lg truncate">
                          {getDisplayCountry(item.country)}
                        </span>
                        {/* Add team member info */}
                        {item.memberId && item.memberId !== user.uid && (
                          <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-lg truncate">
                            Added by: {item.memberName}
                          </span>
                        )}
                        {item.alerts > 0 && (
                          <span className="flex items-center text-red-600">
                            ⚠️ {item.alerts} alerts
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Menu and Chevron */}
                    <MonitoringMenu 
                      item={item} 
                      onRefresh={fetchData}
                    />
                    <div 
                      className="cursor-pointer"
                      onClick={() => {
                        markAllNotificationsAsReadForItem(item.id);
                        clearAlertsForItem(item.id);
                        handleMonitorItemClick(item);
                      }}
                    >
                      <ChevronRight className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* Add Load More button */}
        {!loading && displayedItems.length > 0 && hasMore && (
          <div className="flex justify-center py-4">
            <button
              onClick={loadMore}
              disabled={isLoadingMore}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50"
            >
              {isLoadingMore ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}

        {/* Render the new NotificationsPanel component */}
        <NotificationsPanel
          show={showNotifications}
          onClose={() => setShowNotifications(false)}
          notifications={notifications}
          onMarkAllAsRead={markAllNotificationsAsRead}
          onDeleteAll={deleteAllNotifications}
          onNotificationClick={handleMonitorItemClick}
          monitoredItems={monitoredItems}
          onMarkAsRead={markNotificationAsRead}
          onClearAlerts={clearAlertsForItem}
        />
      </div>
    </div>
  );

};

export default MonitoringDashboard;