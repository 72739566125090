// Create a new file: src/components/TeamInvites.js

import React, { useState, useEffect } from 'react';
import { Check, X, UserPlus } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { auth, db } from '../config/firebase';
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { API_URL } from '../services/api';

const TeamInvites = () => {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processingInvite, setProcessingInvite] = useState(null);

  // Fetch team invites
  useEffect(() => {
    if (!auth.currentUser?.uid) return;
    
    setLoading(true);
    
    // Set up real-time listener for team invites
    const invitesQuery = query(
      collection(db, 'teamInvites'),
      where('invitedUserId', '==', auth.currentUser.uid),
      where('status', '==', 'pending')
    );
    
    const unsubscribe = onSnapshot(invitesQuery, (snapshot) => {
      const inviteList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date()
      }));
      
      setInvites(inviteList);
      setLoading(false);
    }, (error) => {
      console.error('Error in invites listener:', error);
      setLoading(false);
      toast.error('Failed to load team invites');
    });
    
    return () => unsubscribe();
  }, []);

  // Accept an invite
  const handleAcceptInvite = async (inviteId) => {
    if (processingInvite) return;
    
    try {
      setProcessingInvite(inviteId);
      const idToken = await auth.currentUser.getIdToken();
      
      console.log('Accepting invite:', inviteId);
      
      const response = await fetch(`${API_URL}/api/accept-team-invite`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inviteId })
      });
      
      const result = await response.json();
      console.log('Response from accept-team-invite:', result);
      
      if (!response.ok) {
        throw new Error(result.error || 'Failed to accept invite');
      }
      
      if (!result.teamId) {
        throw new Error('Team ID not found in response');
      }
      
      // Update the user's team information in Firestore
      const userRef = doc(db, 'users', auth.currentUser.uid);
      console.log('Updating user document with team ID:', result.teamId);
      await updateDoc(userRef, {
        teamId: result.teamId,
        teamRole: 'member'
      });
      
      toast.success('You have joined the team');
      // Remove the invite from the local state
      setInvites(prevInvites => prevInvites.filter(invite => invite.id !== inviteId));
    } catch (err) {
      console.error('Error accepting invite:', err);
      toast.error(err.message || 'Failed to accept invite');
    } finally {
      setProcessingInvite(null);
    }
  };

  // Decline an invite
  const handleDeclineInvite = async (inviteId) => {
    if (processingInvite) return;
    
    try {
      setProcessingInvite(inviteId);
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/decline-team-invite`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inviteId })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to decline invite');
      }
      
      toast.success('Invite declined');
    } catch (err) {
      console.error('Error declining invite:', err);
      toast.error(err.message || 'Failed to decline invite');
    } finally {
      setProcessingInvite(null);
    }
  };

  if (loading) {
    return (
      <div className="p-4 flex justify-center">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (invites.length === 0) {
    return null; // Don't show anything if there are no invites
  }

  return (
    <div className="p-4 mb-4 border-b">
      <h3 className="text-lg font-medium mb-4">Team Invites</h3>
      
      <div className="space-y-4">
        {invites.map(invite => (
          <div 
            key={invite.id} 
            className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg"
          >
            <div className="flex items-center mb-2">
              <UserPlus className="w-5 h-5 text-yellow-600 mr-2" />
              <h4 className="text-md font-medium">You've been invited to join a team</h4>
            </div>
            
            <div className="mb-3">
              <p><strong>Team:</strong> {invite.teamName}</p>
              <p><strong>Invited by:</strong> {invite.invitedByName || invite.invitedByEmail}</p>
              <p><strong>Date:</strong> {invite.createdAt.toLocaleDateString()}</p>
            </div>
            
            <div className="flex space-x-3">
              <button
                onClick={() => handleAcceptInvite(invite.id)}
                disabled={processingInvite === invite.id}
                className="flex items-center px-3 py-1.5 bg-green-100 hover:bg-green-200 text-green-700 rounded-md transition-colors disabled:opacity-50"
              >
                <Check className="w-4 h-4 mr-1.5" />
                Accept
              </button>
              
              <button
                onClick={() => handleDeclineInvite(invite.id)}
                disabled={processingInvite === invite.id}
                className="flex items-center px-3 py-1.5 bg-red-100 hover:bg-red-200 text-red-700 rounded-md transition-colors disabled:opacity-50"
              >
                <X className="w-4 h-4 mr-1.5" />
                Decline
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamInvites;