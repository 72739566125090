import React, { useState, useEffect } from 'react';
import { 
  onAuthStateChanged
} from 'firebase/auth';
import { 
  logEvent, 
  setUserProperties,
  setUserId
} from 'firebase/analytics';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import SearchApp from './components/SearchApp';
import VerificationPage from './components/VerificationPage';
import { auth, analytics } from './config/firebase';
import { waitForAuthInit, checkVerificationStatus } from './services/api';
import { toast } from 'react-hot-toast';
import { API_URL } from './services/api';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState({
    emailVerified: false,
    phoneVerified: false,
    checking: true
  });
  const [isAuthTransitioning, setIsAuthTransitioning] = useState(false);
  
  const isEmulator = process.env.NODE_ENV === 'development' || 
                     window.location.hostname === 'localhost' || 
                     window.location.hostname === '127.0.0.1';

  // Add check for post-auth redirect
  const urlParams = new URLSearchParams(window.location.search);
  const isPostAuthRedirect = urlParams.has('authuser') || // Google auth param
                            (urlParams.has('verified') && !urlParams.has('signout')) || 
                            urlParams.has('code') || 
                            urlParams.has('state');

  useEffect(() => {
    let mounted = true;
    
    const handleAuthStateChange = async (user) => {
      setIsAuthTransitioning(true);
      try {
        if (user && mounted) {
          // Wait for auth initialization
          await waitForAuthInit();
          
          // Force token refresh
          await user.getIdToken(true);
          
          // Use centralized verification check
          const isVerified = checkVerificationStatus(user);
          
          // Preload subscription stats
          let subscriptionStats = null;
          try {
            // Only fetch if user is verified to avoid unnecessary API calls
            if (isVerified) {
              const token = await user.getIdToken();
              const response = await fetch(`${API_URL}/api/user-stats`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              });
              
              if (response.ok) {
                subscriptionStats = await response.json();
                console.log('Preloaded subscription status:', subscriptionStats);
              }
            }
          } catch (subError) {
            console.error('Error prefetching subscription status:', subError);
          }
          
          if (mounted) {
            setVerificationStatus({
              emailVerified: isVerified,
              phoneVerified: isVerified,
              checking: false
            });
            
            // Add subscription stats to user
            setUser({
              ...user,
              subscriptionStats // This will be passed to SearchApp
            });
            
            if (isVerified) {
              setUserId(analytics, user.uid);
              setUserProperties(analytics, {
                user_type: user.email.endsWith('.edu') ? 'academic' : 'general',
                auth_method: user.providerData[0]?.providerId || 'unknown',
                account_creation_date: user.metadata.creationTime
              });
            }
          }
        } else if (mounted) {
          setUser(null);
          setVerificationStatus({
            emailVerified: false,
            phoneVerified: false,
            checking: false
          });
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        if (mounted) {
          setUser(null);
          setVerificationStatus({
            emailVerified: false,
            phoneVerified: false,
            checking: false
          });
          toast.error('Authentication error, please sign in again');
        }
      } finally {
        if (mounted) {
          setIsAuthTransitioning(false);
          setLoading(false);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    
    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  const needsVerification = React.useMemo(() => {
    if (!user) return false;
    
    const emailVerified = isEmulator 
      ? localStorage.getItem(`user_${user.uid}_emailVerified`) === 'true'
      : user.emailVerified;
    
    const phoneVerified = isEmulator
      ? localStorage.getItem(`user_${user.uid}_phoneVerified`) === 'true'
      : Boolean(user.phoneNumber);

    return !emailVerified || !phoneVerified;
  }, [user, isEmulator]);

  // Update loading check to include post-auth redirect state
  if (loading || isAuthTransitioning || (isPostAuthRedirect && !user)) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route path="/" element={
          isPostAuthRedirect ? (
            <div className="flex items-center justify-center h-screen bg-gray-50">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
            </div>
          ) : user ? (
            needsVerification ? <Navigate to="/verify" /> : <Navigate to="/search" />
          ) : (
            <LandingPage />
          )
        } />
        
        <Route path="/verify" element={
          !user ? <Navigate to="/" /> : 
          !needsVerification ? <Navigate to="/search?verified=true" /> :
          <VerificationPage />
        } />
        
        <Route path="/search" element={
          !user ? <Navigate to="/" /> :
          needsVerification ? <Navigate to="/verify" /> :
          <SearchApp user={user} verificationStatus={verificationStatus} />
        } />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;