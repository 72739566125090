// Updated TeamSettings.js with improved layout and UI

import React, { useState, useEffect } from 'react';
import { Plus, Trash2, X, UserPlus, Users, Check, Settings, User, Building2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { auth, db } from '../config/firebase';
import { 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  getDocs,
  onSnapshot
} from 'firebase/firestore';
import { API_URL } from '../services/api';

const TeamSettings = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [team, setTeam] = useState(null);
  const [inviteEmail, setInviteEmail] = useState('');
  const [sendingInvite, setSendingInvite] = useState(false);
  const [userRole, setUserRole] = useState(null); // 'admin' or 'member'
  const [activeSettingsTab, setActiveSettingsTab] = useState('members');
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [newTeamName, setNewTeamName] = useState(`${auth.currentUser?.displayName || 'My'}'s Team`);
  const [isEditingTeamName, setIsEditingTeamName] = useState(false);

  // Fetch team data on component mount
  useEffect(() => {
    if (!auth.currentUser?.uid) return;
    
    const fetchTeamData = async () => {
      try {
        setLoading(true);
        console.log('Starting fetchTeamData for user:', auth.currentUser.uid);
        
        // Set up real-time listener for user document
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const unsubscribeUser = onSnapshot(userRef, async (userDoc) => {
          console.log('User document updated:', userDoc.exists() ? userDoc.data() : 'does not exist');
          
          if (!userDoc.exists()) {
            console.log('Creating user document...');
            await setDoc(userRef, {
              email: auth.currentUser.email,
              displayName: auth.currentUser.displayName || null,
              createdAt: new Date()
            });
            setTeam(null);
            setUserRole(null);
            setLoading(false);
            return;
          }
          
          const userData = userDoc.data();
          console.log('User data:', userData);
          
          if (userData.teamId) {
            console.log('User has team:', userData.teamId);
            const teamId = userData.teamId;
            
            // Set up real-time listener for team changes
            const teamRef = doc(db, 'teams', teamId);
            const unsubscribeTeam = onSnapshot(teamRef, (snapshot) => {
              console.log('Team snapshot:', snapshot.exists() ? snapshot.data() : 'does not exist');
              if (snapshot.exists()) {
                const teamData = snapshot.data();
                console.log('Setting team data:', teamData);
                setTeam({
                  id: snapshot.id,
                  ...teamData
                });
                
                // Set user role
                if (teamData.adminId === auth.currentUser.uid) {
                  setUserRole('admin');
                } else {
                  setUserRole('member');
                }
              } else {
                console.log('Team does not exist, resetting state');
                setTeam(null);
                setUserRole(null);
                setError(null);
              }
              setLoading(false);
            }, (err) => {
              console.error('Error in team listener:', err);
              if (err.code !== 'permission-denied') {
                setError('Failed to load team data');
              } else {
                setTeam(null);
                setUserRole(null);
                setError(null);
              }
              setLoading(false);
            });
            
            return unsubscribeTeam;
          } else {
            console.log('User has no team');
            setTeam(null);
            setUserRole(null);
            setLoading(false);
          }
        }, (err) => {
          console.error('Error in user listener:', err);
          setError('Failed to load user data');
          setLoading(false);
        });
        
        return () => {
          if (typeof unsubscribeUser === 'function') {
            unsubscribeUser();
          }
        };
      } catch (err) {
        console.error('Error fetching team data:', err);
        setError('Failed to load team data');
        setLoading(false);
      }
    };
    
    const unsubscribe = fetchTeamData();
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, []);

  // Create a new team
  const handleCreateTeam = async () => {
    if (!newTeamName.trim()) {
      toast.error('Please enter a team name');
      return;
    }
    
    try {
      setCreatingTeam(true);
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/create-team`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamName: newTeamName
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to create team');
      }
      
      const result = await response.json();
      toast.success('Team created successfully');
      
      // Immediately set a temporary team object while we wait for the real-time listener to update
      setTeam({
        id: result.teamId,
        name: newTeamName,
        adminId: auth.currentUser.uid,
        members: [{
          id: auth.currentUser.uid,
          email: auth.currentUser.email,
          name: auth.currentUser.displayName || null,
          joinedAt: new Date()
        }],
        pendingInvites: []
      });
      
      setUserRole('admin');
      setActiveSettingsTab('members');
      setCreatingTeam(false);
      
    } catch (err) {
      console.error('Error creating team:', err);
      toast.error(err.message || 'Failed to create team');
      setCreatingTeam(false);
    }
  };

  // Send an invite to a user
  const handleSendInvite = async () => {
    if (!inviteEmail.trim() || !team) return;
    
    try {
      setSendingInvite(true);
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/invite-team-member`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: team.id,
          email: inviteEmail.trim()
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send invite');
      }
      
      toast.success(`Invite sent to ${inviteEmail}`);
      setInviteEmail('');
    } catch (err) {
      console.error('Error sending invite:', err);
      toast.error(err.message || 'Failed to send invite');
    } finally {
      setSendingInvite(false);
    }
  };

  // Remove a member from the team
  const handleRemoveMember = async (memberId) => {
    if (!team || userRole !== 'admin') return;
    
    try {
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/remove-team-member`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: team.id,
          memberId
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to remove team member');
      }
      
      toast.success('Team member removed');
    } catch (err) {
      console.error('Error removing team member:', err);
      toast.error(err.message || 'Failed to remove team member');
    }
  };

  // Leave the team (for non-admin members)
  const handleLeaveTeam = async () => {
    if (!team || userRole === 'admin') return;
    
    try {
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/leave-team`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: team.id
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to leave team');
      }
      
      toast.success('You have left the team');
    } catch (err) {
      console.error('Error leaving team:', err);
      toast.error(err.message || 'Failed to leave team');
    }
  };

  // Delete the team (admin only)
  const handleDeleteTeam = async () => {
    if (!team || userRole !== 'admin') return;
    
    if (!window.confirm('Are you sure you want to delete the team? This action cannot be undone.')) {
      return;
    }
    
    try {
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/delete-team`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: team.id
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete team');
      }
      
      // Clear error state and set team to null to show the create team UI
      setError(null);
      setTeam(null);
      setUserRole(null);
      
      toast.success('Team deleted successfully');
    } catch (err) {
      console.error('Error deleting team:', err);
      toast.error(err.message || 'Failed to delete team');
    }
  };

  // Rename the team
  const handleRenameTeam = async () => {
    if (!newTeamName.trim() || !team || userRole !== 'admin') return;
    
    try {
      const idToken = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/update-team`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: team.id,
          teamName: newTeamName.trim()
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to rename team');
      }
      
      // Update team document directly in Firestore as a fallback
      // (This will be overwritten by the real-time listener when it updates)
      const teamRef = doc(db, 'teams', team.id);
      await updateDoc(teamRef, {
        name: newTeamName.trim()
      });
      
      toast.success('Team renamed successfully');
      setIsEditingTeamName(false);
    } catch (err) {
      console.error('Error renaming team:', err);
      toast.error(err.message || 'Failed to rename team');
    }
  };

  if (loading) {
    return (
      <div className="p-4 flex justify-center">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-600">
        {error}
        <button 
          onClick={() => window.location.reload()}
          className="mt-2 px-3 py-1 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
        >
          Retry
        </button>
      </div>
    );
  }

  if (!team) {
    // No team yet, show create team option
    return (
      <div className="p-0">
        <div className="bg-white rounded-lg shadow-sm border">
          <div className="p-6 space-y-4">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                <Users className="w-5 h-5 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-medium">Create a Team</h3>
                <p className="text-sm text-gray-600">
                  Collaborate with colleagues on search results and monitoring
                </p>
              </div>
            </div>
            
            <div className="space-y-4">
              <div>
                <label htmlFor="teamName" className="block text-sm font-medium text-gray-700 mb-1">
                  Team Name
                </label>
                <input 
                  id="teamName"
                  type="text"
                  value={newTeamName}
                  onChange={(e) => setNewTeamName(e.target.value)}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  placeholder="Enter team name"
                />
              </div>
              
              <button
                onClick={handleCreateTeam}
                disabled={creatingTeam || !newTeamName.trim()}
                className="w-full flex items-center justify-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {creatingTeam ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                    Creating...
                  </>
                ) : (
                  <>
                    <Plus className="w-4 h-4 mr-2" />
                    Create Team
                  </>
                )}
              </button>
            </div>
            
            <div className="border-t pt-4">
              <p className="text-sm text-gray-500">
                Creating a team allows you to share search results, collaborate on monitoring, and manage entities together with your colleagues.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-0">
      <div className="bg-white rounded-lg shadow-sm border overflow-hidden">
        {/* Main layout with sidebar and content */}
        <div className="flex h-full">
          {/* Sidebar/navigation - changed bg-gray-50 to bg-white */}
          <div className="w-48 border-r min-h-[500px] bg-white">
            <ul className="py-2">
              <li>
                <button
                  onClick={() => setActiveSettingsTab('members')}
                  className={`w-full text-left px-4 py-2 text-sm ${
                    activeSettingsTab === 'members'
                      ? 'bg-gray-100 text-gray-900 font-medium'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  Members
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveSettingsTab('general')}
                  className={`w-full text-left px-4 py-2 text-sm ${
                    activeSettingsTab === 'general'
                      ? 'bg-gray-100 text-gray-900 font-medium'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  General
                </button>
              </li>
            </ul>
          </div>
          
          {/* Main content area */}
          <div className="flex-1 p-6">
            {/* Members tab */}
            {activeSettingsTab === 'members' && (
              <div className="space-y-6">
                <div>
                  <h2 className="text-lg font-medium mb-1">Team Members</h2>
                  <div className="mb-4">
                    {userRole === 'admin' && (
                      <p className="text-sm text-gray-500">
                        Manage who has access to your team's data
                      </p>
                    )}
                  </div>
                  
                  {userRole === 'admin' && (
                    <div className="mb-6">
                      <h4 className="text-sm font-medium mb-2">Invite Team Member</h4>
                      <div className="flex">
                        <input
                          type="email"
                          placeholder="Email address"
                          className="flex-1 p-2 border rounded-l-md focus:outline-none focus:ring-1 focus:ring-blue-400"
                          value={inviteEmail}
                          onChange={(e) => setInviteEmail(e.target.value)}
                        />
                        <button
                          onClick={handleSendInvite}
                          disabled={sendingInvite || !inviteEmail.trim()}
                          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-r-md transition-colors disabled:opacity-50"
                        >
                          {sendingInvite ? 'Sending...' : 'Invite'}
                        </button>
                      </div>
                    </div>
                  )}
                  
                  <div className="space-y-3">
                    {team.members?.map(member => (
                      <div 
                        key={member.id} 
                        className="flex justify-between items-center p-3 bg-gray-50 rounded-md border"
                      >
                        <div className="flex items-center">
                          <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center mr-3">
                            {member.name ? member.name.charAt(0).toUpperCase() : 'U'}
                          </div>
                          <div>
                            <p className="font-medium">{member.name || member.email}</p>
                            <p className="text-xs text-gray-500">{member.email}</p>
                            {member.id === team.adminId && (
                              <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full mt-1 inline-block">
                                Admin
                              </span>
                            )}
                          </div>
                        </div>
                        
                        {userRole === 'admin' && member.id !== auth.currentUser.uid && (
                          <button
                            onClick={() => handleRemoveMember(member.id)}
                            className="p-1.5 rounded-full hover:bg-red-100 text-red-600 transition-colors"
                            title="Remove Member"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        )}
                      </div>
                    ))}
                    
                    {team.pendingInvites?.map(invite => (
                      <div 
                        key={invite.email} 
                        className="flex justify-between items-center p-3 bg-yellow-50 rounded-md border border-yellow-200"
                      >
                        <div className="flex items-center">
                          <div className="w-8 h-8 bg-yellow-200 rounded-full flex items-center justify-center mr-3">
                            <UserPlus className="w-4 h-4 text-yellow-700" />
                          </div>
                          <div>
                            <p className="font-medium">{invite.email}</p>
                            <span className="text-xs text-yellow-700 mt-1 inline-block">Pending Invite</span>
                          </div>
                        </div>
                        
                        {userRole === 'admin' && (
                          <button
                            onClick={() => handleRemoveMember(invite.email)}
                            className="p-1.5 rounded-full hover:bg-red-100 text-red-600 transition-colors"
                            title="Cancel Invite"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        )}
                      </div>
                    ))}
                    
                    {team.members?.length === 0 && team.pendingInvites?.length === 0 && (
                      <div className="text-center p-6 bg-gray-50 rounded-md border">
                        <Users className="w-10 h-10 text-gray-400 mx-auto mb-2" />
                        <p className="text-gray-500">No team members yet</p>
                        {userRole === 'admin' && (
                          <p className="text-sm text-gray-400 mt-1">Invite colleagues using the form above</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            
            {/* General settings tab */}
            {activeSettingsTab === 'general' && (
              <div className="space-y-6">
                <div>
                  <h2 className="text-lg font-medium mb-1">Team Settings</h2>
                  <div className="mb-4">
                    {userRole === 'admin' && (
                      <p className="text-sm text-gray-500">
                        Manage your team configuration
                      </p>
                    )}
                  </div>
                  
                  <div className="bg-gray-50 p-4 rounded-md border mb-6">
                    <div className="flex justify-between items-center">
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700">Team Name</label>
                        {isEditingTeamName ? (
                          <div className="mt-1 flex items-center">
                            <input
                              type="text"
                              value={newTeamName}
                              onChange={(e) => setNewTeamName(e.target.value)}
                              autoFocus
                              className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-400"
                              placeholder="Enter new team name"
                            />
                            <div className="flex ml-2">
                              <button
                                onClick={handleRenameTeam}
                                disabled={!newTeamName.trim()}
                                className="p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md mr-1 transition-colors disabled:opacity-50"
                              >
                                <Check className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() => setIsEditingTeamName(false)}
                                className="p-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md transition-colors"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <p className="text-lg font-medium">{team.name}</p>
                        )}
                      </div>
                      
                      {userRole === 'admin' && !isEditingTeamName && (
                        <button
                          className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                          onClick={() => {
                            setNewTeamName(team.name);
                            setIsEditingTeamName(true);
                          }}
                        >
                          Rename
                        </button>
                      )}
                    </div>
                  </div>
                  
                  {/* Danger zone */}
                  <div className="border border-red-200 rounded-md mt-8">
                    <div className="bg-red-50 p-3 rounded-t-md">
                      <h3 className="text-red-700 font-medium">Danger Zone</h3>
                    </div>
                    <div className="p-4">
                      {userRole === 'admin' ? (
                        <div>
                          <p className="text-sm text-gray-600 mb-3">
                            Deleting the team will remove all team associations. This action cannot be undone.
                          </p>
                          <button
                            onClick={handleDeleteTeam}
                            className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors"
                          >
                            Delete Team
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p className="text-sm text-gray-600 mb-3">
                            Leaving the team will remove your access to team data.
                          </p>
                          <button
                            onClick={handleLeaveTeam}
                            className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors"
                          >
                            Leave Team
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSettings;