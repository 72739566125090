import React, { useState, useEffect } from 'react';
import { logEvent, setCurrentScreen } from 'firebase/analytics';
import { Search, History, Bell } from 'lucide-react';
import { Toaster } from 'react-hot-toast';
import { searchApi, checkApiLimits, loadUserPreferences, saveUserPreferences } from '../services/api';
import { analytics, auth } from '../config/firebase';
import SearchResults from './SearchResults';
import SearchHistoryDashboard from './SearchHistoryDashboard';
import MonitoringDashboard from './MonitoringDashboard';
import UserMenu from './UserMenu';
import DatePicker from './DatePicker';
import GenderSelect from './GenderSelect';
import CountrySelect from './CountrySelect';
import { COUNTRY_MAPPING } from '../constants/countries';
import { User, Building2 } from 'lucide-react';
import toast from 'react-hot-toast';
import { collection, query, where, onSnapshot, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { formatName } from '../utils/formatters';
import SubscriptionStatus from './SubscriptionStatus';
import NotificationBubble from './ui/NotificationBubble';
import { waitForAuthInit, checkVerificationStatus } from '../services/api';
import { useNavigate } from 'react-router-dom';
import BulkImport from './BulkImport';

const SearchApp = ({ user, verificationStatus }) => {
    const [activeTab, setActiveTab] = useState('');
    const [activeView, setActiveView] = useState('search');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
      individualName: '',
      dob: '',
      gender: '',
      organization: '',
      designation: '',
      companyName: '',
      country: '🇺🇸 United States',
      matchThreshold: 75
    });
    const [stage, setStage] = useState('initial');
    const [savedResults, setSavedResults] = useState(null);
    const [textAnimationComplete, setTextAnimationComplete] = useState(false);
    const [animationKey, setAnimationKey] = useState(0);
    const [selectedMonitorItem, setSelectedMonitorItem] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [defaultMatchThreshold, setDefaultMatchThreshold] = useState(75);
    const [defaultsLoading, setDefaultsLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!user?.uid) return;

      const notificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        where('read', '==', false)
      );

      const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
        const newNotifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotifications(newNotifications);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [user?.uid]);
  
    useEffect(() => {
      const loadDefaults = async () => {
        if (!user?.uid) return;

        try {
          setDefaultsLoading(true);
          setError(null);
          
          // Wait for auth initialization
          await waitForAuthInit();
          
          // Verify user status before loading preferences
          if (!checkVerificationStatus(auth.currentUser)) {
            console.log('User not verified, redirecting');
            navigate('/verify');
            return;
          }

          console.log('Loading user preferences...');
          const preferences = await loadUserPreferences();
          
          setFormData(prev => ({
            ...prev,
            matchThreshold: preferences?.defaultMatchThreshold || 75,
            country: preferences?.defaultCountry || '🇺🇸 United States'
          }));
          
        } catch (error) {
          console.error('Error in loadDefaults:', error);
          toast.error('Failed to load preferences, using defaults');
          
          setFormData(prev => ({
            ...prev,
            country: '🇺🇸 United States',
            matchThreshold: 75
          }));
        } finally {
          setDefaultsLoading(false);
        }
      };
      
      loadDefaults();
    }, [user?.uid, navigate]);
  
    // Add screen tracking for different views
    useEffect(() => {
      let screenName;
      switch (activeView) {
        case 'history':
          screenName = 'history_page';
          break;
        case 'monitoring':
          screenName = 'monitoring_page';
          break;
        default:
          screenName = 'search_page';
      }
      setCurrentScreen(analytics, screenName);
    }, [activeView]);

    // Show welcome toast only on first ever sign in
    useEffect(() => {
      const hasShownWelcome = localStorage.getItem('shown_welcome_toast');
      const isFirstTimeUser = new URLSearchParams(window.location.search).get('firstTime') === 'true';
      
      if (!hasShownWelcome && isFirstTimeUser) {
        setTimeout(() => {
          toast.success('Welcome to Hound');
          localStorage.setItem('shown_welcome_toast', 'true');
        }, 500);
      }
    }, []);
  
    const handleTabChange = (tab) => {
      // Preserve country and matchThreshold when switching tabs
      setFormData(prev => ({
        ...formData,
        individualName: '',
        dob: '',
        gender: '',
        organization: '',
        designation: '',
        companyName: '',
        // Keep existing country and matchThreshold
        country: prev.country,
        matchThreshold: prev.matchThreshold
      }));
      setActiveTab(tab);
      setStage('form');
    };
  
    const handleMonitorItemClick = (item) => {
      setSelectedMonitorItem(item);
    };
  
    const handleMonitoringTabClick = () => {
      setActiveView('monitoring');
      setSelectedMonitorItem(null);
      setSavedResults(null);
    };
  
    const handleHistoryTabClick = () => {
      setActiveView('history');
      setSavedResults(null);
    };
  
    const handleHeaderSearchClick = () => {
      setTextAnimationComplete(false);
      setAnimationKey(prev => prev + 1);
      resetSearch();
      setSavedResults(null);
      setActiveView('search');
      setStage('initial');
    };
  
    const resetSearch = () => {
      setStage('initial');
      setActiveTab('');
      setSearchResults([]);
      setSavedResults(null);
      setFormData({
        individualName: '',
        dob: '',
        gender: '',
        organization: '',
        designation: '',
        companyName: '',
        country: formData.country,
        matchThreshold: defaultMatchThreshold || 75
      });
    };
  
    const validateForm = () => {
      if (activeTab === 'individual') {
        if (!formData.individualName?.trim()) {
          setError('Name cannot be empty');
          return false;
        }
        if (formData.individualName.trim().split(' ').length === 1) {
          setError('Please enter a full name');
          return false;
        }
      } else {
        if (!formData.companyName?.trim()) {
          setError('Company name cannot be empty');
          return false;
        }
      }
      return true;
    };
  
    const checkSearchLimit = async () => {
      try {
        const limits = await checkApiLimits(user.uid);
        // Check both daily and total limits
        return !limits.isLimited && limits.searchesRemaining.daily > 0;
      } catch (error) {
        console.error('Error checking search limits:', error);
        throw error;
      }
    };
  
    const handleSearch = async () => {
      if (!validateForm()) return;

      const searchStartTime = Date.now();
      setError(null);
      setLoading(true); // Set loading state for search button
      setSearchResults([]);

      try {
        const canSearch = await checkSearchLimit();
        if (!canSearch) {
          const limits = await checkApiLimits(user.uid);
          // Check if it's the total limit that's been reached
          if (!limits.isSubscribed && limits.searchesRemaining.total <= 0) {
            toast.error('Search limit reached. Please upgrade for more searches.');
          } else {
            toast.error('Daily search limit reached. Please try again tomorrow.');
          }
          setLoading(false); // Reset loading state when limit is reached
          return;
        }
        
        try {
          const searchData = {
            searchType: activeTab,
            individualName: activeTab === 'individual' ? formData.individualName : undefined,
            dob: activeTab === 'individual' ? formData.dob : undefined,
            gender: activeTab === 'individual' ? formData.gender : undefined,
            organization: activeTab === 'individual' ? formData.organization : undefined,
            designation: activeTab === 'individual' ? formData.designation : undefined,
            companyName: activeTab === 'company' ? formData.companyName : undefined,
            country: formData.country,
            matchThreshold: formData.matchThreshold / 100
          };
  
          logEvent(analytics, 'search_started', {
            search_type: activeTab,
            country: formData.country
          });
  
          const response = await searchApi(searchData);
          setSearchResults(response.results || []);
          setStage('results'); // Add this line
  
          logEvent(analytics, 'search_completed', {
            search_type: activeTab,
            duration: Date.now() - searchStartTime,
            result_count: response.results?.length || 0
          });
  
        } catch (error) {
          setError(error.message);
          logEvent(analytics, 'search_error', {
            search_type: activeTab,
            error_message: error.message,
            duration: Date.now() - searchStartTime
          });
        } finally {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error checking search limit:', error);
        toast.error('An error occurred while checking search limit.');
        setLoading(false); // Reset loading state on error
      }
    };
  
    const handleFormDataChange = (field, value) => {
      setFormData(prevData => ({
        ...prevData,
        [field]: field === 'individualName' || field === 'companyName' || 
        field === 'organization' || field === 'designation' 
        ? formatName(value) : value      
      }));
    };
  
    const handleSetDefaultMatchThreshold = async () => {
      if (!user?.uid || !formData.matchThreshold) return;
      try {
        await saveUserPreferences({ 
          defaultMatchThreshold: formData.matchThreshold 
        });
        setDefaultMatchThreshold(formData.matchThreshold);
        toast.success('Default match threshold updated');
      } catch (error) {
        console.error('Error setting default match threshold:', error);
        toast.error('Failed to set default match threshold');
      }
    };
  
    const renderForm = () => {
      if (!activeTab) return null;  // Only keep the activeTab check
    
      return (
        <div className="space-y-6">
          {activeTab === 'individual' ? (
            <>
              <input
                type="text"
                placeholder="Individual's Name (required)"
                className="input-field"
                value={formData.individualName}
                onChange={(e) => handleFormDataChange('individualName', e.target.value)}
              />
              <DatePicker
                value={formData.dob}
                onChange={(e) => setFormData({...formData, dob: e.target.value})}
              />
              <GenderSelect
                value={formData.gender}
                onChange={(e) => setFormData({...formData, gender: e.target.value})}
              />
              <input
                type="text"
                placeholder="Organization (improves accuracy)"
                className="input-field"
                value={formData.organization}
                onChange={(e) => handleFormDataChange('organization', e.target.value)}
              />
              <input
                type="text"
                placeholder="Designation (improves accuracy)"
                className="input-field"
                value={formData.designation}
                onChange={(e) => handleFormDataChange('designation', e.target.value)}
              />
              <CountrySelect
                value={formData.country}
                onChange={(e) => setFormData({...formData, country: e.target.value})}
                userId={user.uid}
              />
            </>
          ) : (
            <>
              <input
              type="text"
              placeholder="Company Name"
              className="input-field"
              value={formData.companyName}
              onChange={(e) => handleFormDataChange('companyName', e.target.value)}
            />
            <CountrySelect
              value={formData.country}
              onChange={(e) => setFormData({...formData, country: e.target.value})}
              userId={user.uid}
            />
          </>
          )}
  
          <div className="space-y-2">
            <div className="flex items-center justify-between min-h-[32px]">
              <label className="block text-sm font-medium text-gray-700">
                Minimum Match Score: {formData.matchThreshold}%
              </label>
              <div className="w-[88px] flex justify-end">
                {user?.uid && defaultMatchThreshold !== formData.matchThreshold && (
                  <button
                    onClick={handleSetDefaultMatchThreshold}
                    className="px-3 py-1 text-sm font-medium text-gray-600 hover:text-gray-900 bg-white border rounded-lg shadow-sm hover:shadow transition-all whitespace-nowrap"
                  >
                    Set Default
                  </button>
                )}
              </div>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={formData.matchThreshold}
              onChange={(e) => setFormData({...formData, matchThreshold: parseInt(e.target.value)})}
              className="w-full accent-gray-600"
            />
          </div>
    
          <button 
            className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-900 rounded-md transition-colors"
            onClick={handleSearch}
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-600"></div>
                <span className="ml-2">Searching...</span>
              </div>
            ) : (
              'Search'
            )}
          </button>
        </div>
      );
    };
  
    const handleHistoryItemClick = (historyItem) => {
      // Add debug logging
      console.log('SearchApp received history item:', historyItem);

      setStage('results');
      setActiveTab(historyItem.searchType);
      setFormData({
        individualName: historyItem.individualName || '',
        companyName: historyItem.companyName || '',
        organization: historyItem.organization || '',
        designation: historyItem.designation || '',
        country: historyItem.country || '🇺🇸 United States',
        matchThreshold: Math.round(historyItem.matchThreshold * 100) || 75,
        dob: historyItem.dob || '',
        gender: historyItem.gender || ''
        // Remove timestamp from formData for now
      });

      // Store timestamp separately to avoid React state issues
      const searchTimestamp = historyItem.searchTimestamp;
      setSavedResults(historyItem.results.map(result => ({
        ...result,
        searchTimestamp // Attach timestamp to each result
      })));
    };
  
    return (
      <div className="min-h-screen bg-gray-50">
        {/* Updated Header with better centering */}
        <div className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center h-16">
              {/* Logo section - hide BETA on smallest screens */}
              <div className="w-24 sm:w-40">
                <div className="flex items-center">
                  <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Hound</h1>
                  <span className="hidden sm:inline-flex ml-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                    BETA
                  </span>
                </div>
              </div>
  
              {/* Navigation - adjust padding on mobile */}
              <div className="flex-1 flex justify-center">
                <div className="bg-gray-100 rounded-lg p-1 flex">
                  <button
                    onClick={handleHeaderSearchClick}
                    className={`flex items-center px-3 sm:px-6 py-2 rounded-md text-sm focus:outline-none ${
                      activeView === 'search'
                        ? 'bg-white text-gray-900 shadow-sm'
                        : 'text-gray-700 hover:text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    <Search className="w-4 h-4 sm:mr-2" />
                    <span className="hidden sm:inline">Search</span>
                  </button>
                  <button
                    onClick={handleHistoryTabClick}
                    className={`flex items-center px-3 sm:px-6 py-2 rounded-md text-sm focus:outline-none ${
                      activeView === 'history'
                        ? 'bg-white text-gray-900 shadow-sm'
                        : 'text-gray-700 hover:text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    <History className="w-4 h-4 sm:mr-2" />
                    <span className="hidden sm:inline">History</span>
                  </button>
                  <button
                    onClick={handleMonitoringTabClick}
                    className={`relative flex items-center px-3 sm:px-6 py-2 rounded-md text-sm focus:outline-none ${
                      activeView === 'monitoring'
                        ? 'bg-white text-gray-900 shadow-sm'
                        : 'text-gray-700 hover:text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    <Bell className="w-4 h-4 sm:mr-2" />
                    <span className="hidden sm:inline">Monitor</span>
                    <NotificationBubble count={notifications?.length} />
                  </button>
                </div>
              </div>
  
              {/* User menu - adjust width on mobile */}
              <div className="w-24 sm:w-40 flex justify-end items-center gap-4">
                <SubscriptionStatus preloadedStats={user?.subscriptionStats} />
                <UserMenu user={user} onSignOut={() => {}} />
              </div>
            </div>
          </div>
        </div>
  
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* When we have saved results, show them regardless of active view */}
          {savedResults ? (
            <div className="max-w-3xl mx-auto">
              <div className="bg-white rounded-2xl border shadow-sm p-6 mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  {activeTab === 'individual' ? 'Individual Search' : 'Company Search'}
                </h2>
                <div className="space-y-4">
                  {Object.entries(formData)
                    .filter(([key, value]) => {
                      return key !== 'searchType' && value !== null && value !== '';
                    })
                    .sort((a, b) => {
                      const order = {
                        individualName: 1,
                        companyName: 1,
                        dob: 2,
                        gender: 3,
                        organization: 4,
                        designation: 5,
                        country: 6,
                        matchThreshold: 7
                      };
                      return (order[a[0]] || 99) - (order[b[0]] || 99);
                    })
                    .map(([key, value]) => {
                      const labels = {
                        individualName: 'Individual Name',
                        companyName: 'Company Name',
                        dob: 'Date of Birth',
                        organization: 'Organization',
                        designation: 'Designation',
                        matchThreshold: 'Match Threshold',
                        country: 'Country',
                        gender: 'Gender'
                      };

                      let displayValue = value;
                      if (key === 'dob' && value) {
                        try {
                          displayValue = new Date(value).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          });
                        } catch (e) {
                          displayValue = value;
                        }
                      } else if (key === 'matchThreshold') {
                        displayValue = `${parseInt(value)}%`;
                      } else if (['organization', 'designation', 'individualName', 'companyName'].includes(key)) {
                        displayValue = formatName(value);
                      }

                      return (
                        <div key={key} className="flex">
                          <span className="font-medium w-32">{labels[key]}:</span>
                          <span>{displayValue}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
              <SearchResults 
                results={savedResults}
                countryMapping={COUNTRY_MAPPING}
                onNewSearch={() => {
                  resetSearch();
                  setSavedResults(null);
                }}
                formData={{...formData, searchType: activeTab}}
              />
            </div>
          ) : (
            /* Show regular views when no saved results */
            <>
              {activeView === 'search' && (
                <div className="max-w-3xl mx-auto">
                  {stage === 'initial' && !savedResults && (
                    <div className="bg-white rounded-2xl border shadow-sm p-6 mb-8">
                      <div className="opacity-0 animate-fade-in space-y-8">
                        <h2 className="text-2xl font-semibold text-center">
                          Let's begin. Would you like to monitor an individual or company?
                        </h2>
                        <div className="grid grid-cols-2 gap-4">
                          <button
                            className={`flex items-center justify-center p-4 rounded-xl border text-left transition-colors ${
                              activeTab === 'individual' 
                                ? 'bg-gray-100 border-gray-300'
                                : 'hover:bg-gray-100'
                            }`}
                            onClick={() => handleTabChange('individual')}
                          >
                            <User className="w-6 h-6 text-gray-500 mr-3" />
                            <span className="text-lg">Individual</span>
                          </button>
                          <button
                            className={`flex items-center justify-center p-4 rounded-xl border text-left transition-colors ${
                              activeTab === 'company'
                                ? 'bg-gray-100 border-gray-300'
                                : 'hover:bg-gray-100'
                            }`}
                            onClick={() => handleTabChange('company')}
                          >
                            <Building2 className="w-6 h-6 text-gray-500 mr-3" />
                            <span className="text-lg">Company</span>
                          </button>
                        </div>
                        
                        <BulkImport 
                          onClose={() => {
                            setActiveView('history');
                          }} 
                        />
                      </div>
                    </div>
                  )}
                  
                  {stage === 'form' && !savedResults && (
                    <div className="bg-white rounded-2xl border shadow-sm p-6 mb-8">
                      {defaultsLoading ? (
                        <div className="flex justify-center items-center h-64">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                        </div>
                      ) : (
                        <div className="opacity-0 animate-fade-in space-y-8">
                          <h2 className="text-2xl font-semibold text-center">
                            {activeTab === 'individual' 
                              ? "Got it! Can you give me the following details?"
                              : "Sure thing! Tell me more about this company."}
                          </h2>
                          {renderForm()}
                        </div>
                      )}
                    </div>
                  )}
  
                  {stage === 'results' && (
                    <>
                      <div className="bg-white rounded-2xl border shadow-sm p-6 mb-8">
                        <h2 className="text-2xl font-semibold mb-4">
                          {activeTab === 'individual' ? 'Individual Search' : 'Company Search'}
                        </h2>
                        <div className="space-y-4">
                          {Object.entries(formData)
                            .filter(([key, value]) => {
                              return key !== 'searchType' && value !== null && value !== '';
                            })
                            .sort((a, b) => {
                              const order = {
                                individualName: 1,
                                companyName: 1,
                                dob: 2,
                                gender: 3,
                                organization: 4,
                                designation: 5,
                                country: 6,
                                matchThreshold: 7
                              };
                              return (order[a[0]] || 99) - (order[b[0]] || 99);
                            })
                            .map(([key, value]) => {
                              // Get properly formatted label
                              const labels = {
                                individualName: 'Individual Name',
                                companyName: 'Company Name',
                                dob: 'Date of Birth',
                                organization: 'Organization',
                                designation: 'Designation',
                                matchThreshold: 'Match Threshold',
                                country: 'Country',
                                gender: 'Gender'
                              };

                              // Format the value based on field type
                              let displayValue = value;
                              if (key === 'dob' && value) {
                                try {
                                  displayValue = new Date(value).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                  });
                                } catch (e) {
                                  displayValue = value;
                                }
                              } else if (key === 'matchThreshold') {
                                displayValue = `${parseInt(value)}%`;
                              } else if (key === 'organization' || key === 'designation' || 
                                        key === 'individualName' || key === 'companyName') {
                                displayValue = formatName(value);
                              }

                              return (
                                <div key={key} className="flex">
                                  <span className="font-medium w-32">{labels[key] || key}:</span>
                                  <span>{displayValue}</span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <SearchResults 
                        results={savedResults || searchResults}
                        countryMapping={COUNTRY_MAPPING}
                        onNewSearch={() => {
                          resetSearch();
                          setSavedResults(null);
                        }}
                        formData={{...formData, searchType: activeTab}}
                      />
                    </>
                  )}
                </div>
              )}
              
              {activeView === 'history' && (
                <SearchHistoryDashboard 
                  user={user} 
                  onHistoryItemClick={handleHistoryItemClick}
                />
              )}
              
              {activeView === 'monitoring' && (
                <>
                  {!selectedMonitorItem ? (
                    <MonitoringDashboard 
                      user={user}
                      onMonitorItemClick={handleMonitorItemClick}
                      notifications={notifications}
                    />
                  ) : (
                    <SearchResults 
                      results={selectedMonitorItem.results}
                      formData={{
                        searchType: selectedMonitorItem.searchType,
                        individualName: selectedMonitorItem.individualName,
                        companyName: selectedMonitorItem.companyName,
                        country: selectedMonitorItem.country
                      }}
                      isMonitoring={true}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
        <Toaster />
      </div>
    );
  };

export default SearchApp;