// useSubscription.js - New custom hook for subscription logic
import { useState, useEffect } from 'react';
import { auth, db } from '../config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { formatDate } from '../utils/formatters';

const getBaseUrl = () => {

  const isLocalEnvironment = 
    process.env.NODE_ENV === 'development' || 
    window.location.hostname === 'localhost' || 
    window.location.hostname === '127.0.0.1';

  const baseUrl = isLocalEnvironment
    ? process.env.REACT_APP_EMULATOR_BACKEND_URL
    : process.env.REACT_APP_CLOUD_FUNCTION_URL;

  if (!baseUrl) {
    console.error('No base URL configured for current environment');
    throw new Error('API URL configuration missing');
  }

  return baseUrl;
};

const useSubscription = (preloadedStats = null) => {
  const [stats, setStats] = useState(preloadedStats || null);
  const [loading, setLoading] = useState(!preloadedStats); // Don't show loading if we have preloaded stats
  const [error, setError] = useState(null);

  const fetchStats = async () => {
    // Skip fetch if we already have preloaded stats
    if (preloadedStats) return;
    
    try {
      setLoading(true);
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${getBaseUrl()}/api/user-stats`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user stats');
      }

      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError(error);
      toast.error('Failed to update subscription status');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSuccess = async (razorpayResponse, subscriptionId, token) => {
    console.log('Payment success handler called with:', { 
      response: razorpayResponse,
      subscriptionId: subscriptionId,
      hasToken: !!token
    });
    
    const paymentToastId = toast.loading('Processing payment...');
    
    try {
      const url = `${getBaseUrl()}/api/payment-success`;
      console.log('Sending payment verification to:', url);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          razorpay_payment_id: razorpayResponse.razorpay_payment_id,
          razorpay_signature: razorpayResponse.razorpay_signature,
          razorpay_subscription_id: subscriptionId,
          razorpay_order_id: razorpayResponse.razorpay_order_id
        })
      });

      const responseText = await response.text();
      console.log('Raw server response:', responseText);

      let result;
      try {
        result = responseText ? JSON.parse(responseText) : null;
      } catch (e) {
        console.error('Response parsing error:', e);
        throw new Error(`Server returned invalid JSON: ${responseText.substring(0, 100)}...`);
      }

      if (!response.ok) {
        throw new Error(result?.error || `Payment verification failed: ${response.status}`);
      }

      toast.dismiss(paymentToastId);
      
      if (result?.status === 'success') {
        if (!razorpayResponse.isReactivation) {
          toast.success('Subscription activated!');
        }
        await fetchStats();
      } else {
        throw new Error(result?.error || 'Payment verification failed');
      }
    } catch (error) {
      console.error('Payment verification error:', error);
      toast.dismiss(paymentToastId);
      toast.error(error.message || 'Payment verification failed');
      throw error;
    }
  };

  const handleSubscribe = async () => {
    let toastId;
    
    try {
      toastId = toast.loading('Initiating subscription...');
      const token = await auth.currentUser.getIdToken();
      
      console.log('Creating subscription...');
      const response = await fetch(`${getBaseUrl()}/api/create-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          plan_id: process.env.REACT_APP_RAZORPAY_PLAN_ID
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create subscription');
      }

      const subscription = await response.json();
      console.log('Subscription created:', subscription);

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        subscription_id: subscription.id,
        name: 'Hound',
        description: 'Pro Subscription',
        prefill: {
          name: auth.currentUser?.displayName || '',
          email: auth.currentUser?.email || '',
        },
        handler: async function(response) {
          console.log('Razorpay payment completed:', response);
          if (toastId) {
            toast.dismiss(toastId);
            toastId = null;
          }
          
          try {
            await handlePaymentSuccess(
              response,
              subscription.id,
              token
            );
          } catch (error) {
            console.error('Payment verification failed:', error);
            toast.error('Payment verification failed');
          }
        },
        modal: {
          ondismiss: function() {
            console.log('Razorpay modal dismissed');
            if (toastId) {
              toast.dismiss(toastId);
              toastId = null;
              toast.error('Payment cancelled');
            }
          },
          escape: false,
        },
        notes: {
          userId: auth.currentUser?.uid
        }
      };

      console.log('Creating Razorpay instance with options:', {
        ...options,
        key: options.key ? 'present' : 'missing'
      });

      if (!window.Razorpay) {
        throw new Error('Razorpay SDK not loaded');
      }
      
      const rzp = new window.Razorpay(options);
      console.log('Opening Razorpay payment modal...');
      rzp.open();

    } catch (error) {
      console.error('Subscription error:', error);
      if (toastId) {
        toast.dismiss(toastId);
        toast.error(error.message || 'Failed to start subscription');
      }
    }
  };

  const handleCancelSubscription = async () => {
    const toastId = toast.loading('Cancelling subscription...');
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${getBaseUrl()}/api/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subscriptionId: stats.subscriptionId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to cancel subscription');
      }

      const data = await response.json();
      await fetchStats();
      toast.success(`Subscription will end on ${formatDate(data.effectiveDate)}`, { id: toastId });
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      toast.error(error.message || 'Failed to cancel subscription', { id: toastId });
    }
  };

  const handleReactivateSubscription = async () => {
    const toastId = toast.loading('Reactivating subscription...');
    try {
      const token = await auth.currentUser.getIdToken();
      console.log('Sending reactivation request with plan ID:', process.env.REACT_APP_RAZORPAY_PLAN_ID);  // Add this

      const response = await fetch(`${getBaseUrl()}/api/reactivate-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          plan_id: process.env.REACT_APP_RAZORPAY_PLAN_ID
        })
      });

      if (!response.ok) {
        throw new Error('Failed to reactivate subscription');
      }

      const result = await response.json();
      
      // Mirror the same options as new subscription
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        subscription_id: result.subscription.id,
        name: 'Hound',
        description: 'Pro Subscription',
        prefill: {
          name: auth.currentUser?.displayName || '',
          email: auth.currentUser?.email || '',
        },
        handler: async function(response) {
          if (toastId) {
            toast.dismiss(toastId);
          }
          try {
            await handlePaymentSuccess(
              { ...response, isReactivation: true },
              result.subscription.id,
              token
            );
            await fetchStats();
            toast.success('Subscription restored successfully');
          } catch (error) {
            console.error('Payment verification failed:', error);
            toast.error('Payment verification failed');
            await fetchStats();
          }
        },
        modal: {
          ondismiss: function() {
            if (toastId) {
              toast.dismiss(toastId);
              toast.error('Subscription restoration cancelled');
            }
            fetchStats();
          },
          escape: false,
        },
        notes: {
          userId: auth.currentUser?.uid
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

    } catch (error) {
      console.error('Error reactivating subscription:', error);
      toast.error('Failed to reactivate subscription', { id: toastId });
      await fetchStats();
    }
  };

  useEffect(() => {
    if (stats === null && !preloadedStats) {
      fetchStats();
    }
  }, [preloadedStats]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      setLoading(true);
      fetchStats();
    });

    return () => unsubscribe();
  }, []);

  // Add real-time listener for usage updates
  useEffect(() => {
    if (!auth.currentUser) return;
    
    // Reference to the user's document
    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    
    // Set up real-time listener
    const unsubscribeUsage = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        
        // Update stats with the latest usage data while preserving other stats properties
        setStats((prevStats) => {
          if (!prevStats) return prevStats;
          
          return {
            ...prevStats,
            currentUsage: userData.usage || prevStats.currentUsage,
            memberUsage: userData.memberUsage || prevStats.memberUsage,
          };
        });
      }
    }, (error) => {
      console.error("Error listening to user usage updates:", error);
    });
    
    // If the user is part of a team, also listen to team usage updates
    if (stats?.teamId) {
      const teamDocRef = doc(db, 'teams', stats.teamId);
      
      const unsubscribeTeam = onSnapshot(teamDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const teamData = docSnapshot.data();
          
          // Update team-related stats
          setStats((prevStats) => {
            if (!prevStats) return prevStats;
            
            return {
              ...prevStats,
              currentUsage: {
                ...prevStats.currentUsage,
                totalSearches: teamData.totalSearches || prevStats.currentUsage?.totalSearches
              },
              memberUsage: teamData.memberUsage || prevStats.memberUsage,
            };
          });
        }
      }, (error) => {
        console.error("Error listening to team usage updates:", error);
      });
      
      return () => {
        unsubscribeUsage();
        unsubscribeTeam();
      };
    }
    
    return () => unsubscribeUsage();
  }, [auth.currentUser, stats?.teamId]);

  return {
    stats,
    loading,
    error,
    handleSubscribe,
    handleCancelSubscription,
    handleReactivateSubscription,
    handlePaymentSuccess
  };
};

export default useSubscription;