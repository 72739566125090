import React, { useState, useEffect } from 'react';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification
} from 'firebase/auth';
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics';
import { ArrowDown } from 'lucide-react';
import toast from 'react-hot-toast';
import LandingAnimation from './LandingAnimation';
import { auth, analytics } from '../config/firebase';
import { useNavigate } from 'react-router-dom';

const googleProvider = new GoogleAuthProvider();

const Footer = () => (
  <div className="text-center text-sm text-gray-400">
    <p>
      By using Hound, you agree to our{' '}
      <a 
        href="/terms"
        className="text-gray-500 hover:text-gray-900 transition-colors"
        onClick={(e) => {
          e.preventDefault();
          // Implement navigation or modal open logic here
        }}
      >
        Terms
      </a>
      {' '}and have read our{' '}
      <a 
        href="/privacy"
        className="text-gray-500 hover:text-gray-900 transition-colors"
        onClick={(e) => {
          e.preventDefault();
          // Implement navigation or modal open logic here
        }}
      >
        Privacy Policy
      </a>
    </p>
  </div>
);

const MobileLayout = ({ 
  isSignUp, 
  email, 
  password, 
  error, 
  success,
  handleAuth,
  handleGoogleSignIn,
  setEmail,
  setPassword,
  setError,
  setSuccess,
  setIsSignUp,
  setIsResetModalOpen,
  animationComplete,
  setAnimationComplete 
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Initial centered animation that moves up */}
      <div className={`transition-all duration-500 ease-out ${
        animationComplete ? 'h-[30vh] pt-16' : 'h-screen'
      }`}>
        <div className={`h-full w-full flex items-center justify-center transition-all duration-500 ${
          animationComplete ? 'translate-y-0' : 'translate-y-0'
        }`}>
          <div className="w-full px-8 text-center">
            <LandingAnimation 
              isMobile={true}
              onComplete={() => setTimeout(() => setAnimationComplete(true), 500)}
            />
          </div>
        </div>
      </div>

      {/* Auth form - slides up with less spacing */}
      <div className={`px-6 pb-8 transition-all duration-500 ${
        animationComplete ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8 pointer-events-none'
      }`}>
        <div className="max-w-md mx-auto">
          <h2 className="text-2xl font-bold text-gray-900 text-center mb-4">
            {isSignUp ? 'Create your account' : 'Sign in to your account'}
          </h2>
          
          <button
            onClick={handleGoogleSignIn}
            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            <img
              className="h-5 w-5 mr-2"
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google"
            />
            Continue with Google
          </button>

          <div className="my-4 relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-50 text-gray-500">
                Or continue with email
              </span>
            </div>
          </div>

          <form onSubmit={handleAuth} className="space-y-4">
            <input
              type="email"
              required
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            <input
              type="password"
              required
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />

            {!isSignUp && (
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsResetModalOpen(true)}
                  className="text-sm text-[#2B82DE] hover:text-[#2B82DE]/90"
                >
                  Forgot your password?
                </button>
              </div>
            )}

            {error && (
              <div className="text-red-500 text-sm text-center">{error}</div>
            )}

            <button
              type="submit"
              className="w-full px-4 py-2 bg-[#2B82DE] text-white rounded-md hover:bg-[#2B82DE]/90 focus:outline-none"
            >
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
          </form>

          <button
            onClick={() => {
              setIsSignUp(!isSignUp);
              setError('');
              setSuccess('');
            }}
            className="w-full mt-4 py-2 bg-gray-50 text-gray-900 rounded-md hover:bg-gray-100"
          >
            {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
          </button>

          <div className="mt-8">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

const LandingPage = () => {
    const navigate = useNavigate();
    const [isSignUp, setIsSignUp] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [animationComplete, setAnimationComplete] = useState(false);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      setCurrentScreen(analytics, isSignUp ? 'signup_page' : 'login_page');
    }, [isSignUp]);
  
    const handleAuth = async (e) => {
      e.preventDefault();
      setError('');
      setSuccess('');
      
      // Basic validation
      if (!email || !password) {
        setError('Email and password are required');
        toast.error('Email and password are required');
        return;
      }

      // Password length validation for signup
      if (isSignUp && password.length < 6) {
        setError('Password must be at least 6 characters long');
        toast.error('Password must be at least 6 characters long');
        return;
      }
      
      try {
        if (isSignUp) {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          await sendEmailVerification(userCredential.user);
          
          logEvent(analytics, 'sign_up', {
            method: 'email',
            success: true
          });
          
          // Always navigate to verification for new users
          navigate('/verify');
          toast.success('Account created! Please check your email for verification.');
        } else {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          
          // Check both verifications
          if (!user.emailVerified || !user.phoneNumber) {
            navigate('/verify');
            return;
          }
          
          logEvent(analytics, 'login', {
            method: 'email',
            success: true
          });
          navigate('/search');
        }
      } catch (error) {
        logEvent(analytics, isSignUp ? 'sign_up_error' : 'login_error', {
          method: 'email',
          error_code: error.code,
          error_message: error.message
        });
        
        // Handle specific Firebase auth errors with user-friendly messages
        let errorMessage;
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = 'This email is already registered. Please sign in instead.';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Please enter a valid email address.';
            break;
          case 'auth/operation-not-allowed':
            errorMessage = 'Email/password accounts are not enabled. Please contact support.';
            break;
          case 'auth/weak-password':
            errorMessage = 'Please choose a stronger password.';
            break;
          case 'auth/user-not-found':
            errorMessage = 'This account doesn\'t exist.';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Invalid email or password.';
            break;
          default:
            errorMessage = 'An error occurred. Please try again.';
        }
        setError(errorMessage);
        toast.error(errorMessage);
      }
    };
  
    const handleGoogleSignIn = async () => {
      try {
        setError('');
        setSuccess('');
        setLoading(true);
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;

        // Check both verifications
        if (!user.emailVerified || !user.phoneNumber) {
          window.location.href = '/verify';
          return;
        }

        logEvent(analytics, isSignUp ? 'sign_up' : 'login', {
          method: 'google',
          success: true
        });
        
        window.location.href = `/search?verified=true&t=${Date.now()}`;
        
      } catch (error) {
        setLoading(false);
        logEvent(analytics, 'google_sign_in_error', {
          error_code: error.code,
          error_message: error.message
        });
        setError('Failed to sign in with Google. Please try again.');
        toast.error('Failed to sign in with Google. Please try again.');
      }
    };
  
    const handleForgotPassword = async () => {
      if (!email) {
        setError('Please enter your email address to reset your password.');
        toast.error('Please enter your email address to reset your password.');
        return;
      }
  
      try {
        await sendPasswordResetEmail(auth, email);
        setSuccess('Password reset email sent! Please check your inbox.');
        toast.success('Password reset email sent! Please check your inbox.');
        setIsResetModalOpen(false);
      } catch (error) {
        let errorMessage;
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'Please enter a valid email address.';
            break;
          case 'auth/user-not-found':
            errorMessage = 'No account found with this email address.';
            break;
          default:
            errorMessage = 'Failed to send password reset email. Please try again.';
        }
        setError(errorMessage);
        toast.error(errorMessage);
      }
    };
  
    return (
      <div className="min-h-screen bg-gray-50">
        {/* Desktop Layout */}
        <div className="hidden md:flex min-h-screen">
          <div className="flex-1 flex flex-col justify-center items-center bg-white relative">
            <LandingAnimation />
          </div>
  
          {/* Center Divider */}
          <div className="w-px bg-gray-200 self-stretch my-16" />
  
          {/* Right Panel */}
          <div className="flex-1 flex items-center justify-center px-12">
            <div className="max-w-md w-full">
              <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                {isSignUp ? 'Create your account' : 'Sign in to your account'}
              </h2>
              
              <button
                onClick={handleGoogleSignIn}
                className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 mb-6"
              >
                <img
                  className="h-5 w-5 mr-2"
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                />
                Continue with Google
              </button>
  
              <div className="relative mb-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-50 text-gray-500">
                    Or continue with email
                  </span>
                </div>
              </div>
  
              <form onSubmit={handleAuth} className="space-y-4">
                <input
                  type="email"
                  required
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                />
                <input
                  type="password"
                  required
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                />
  
                {!isSignUp && (
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => setIsResetModalOpen(true)}
                      className="text-sm text-primary-600 hover:text-primary-500"
                    >
                      Forgot your password?
                    </button>
                  </div>
                )}
  
                {error && (
                  <div className="text-red-500 text-sm text-center">{error}</div>
                )}
  
                {success && (
                  <div className="text-green-500 text-sm text-center">{success}</div>
                )}
  
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {isSignUp ? 'Sign Up' : 'Sign In'}
                </button>
              </form>
  
              <button
                onClick={() => {
                  setIsSignUp(!isSignUp);
                  setError('');
                  setSuccess('');
                }}
                className="w-full mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
              </button>

              <div className="mt-8">
                <Footer />
              </div>
            </div>
          </div>
        </div>
  
        {/* Updated Mobile Layout */}
        <div className="md:hidden">
          <MobileLayout 
            isSignUp={isSignUp}
            email={email}
            password={password}
            error={error}
            success={success}
            handleAuth={handleAuth}
            handleGoogleSignIn={handleGoogleSignIn}
            setEmail={setEmail}
            setPassword={setPassword}
            setError={setError}
            setSuccess={setSuccess}
            setIsSignUp={setIsSignUp}
            setIsResetModalOpen={setIsResetModalOpen}
            animationComplete={animationComplete}
            setAnimationComplete={setAnimationComplete}
          />
        </div>
  
        {/* Password Reset Modal */}
        {isResetModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Reset Password</h3>
              <input
                type="email"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 mb-4"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setIsResetModalOpen(false)}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleForgotPassword}
                  className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  Send Reset Link
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default LandingPage;