// In UserMenu.js
import { useState, useRef, useEffect, useMemo } from 'react';
import { User, LogOut, HelpCircle, Crown, CreditCard, X, Settings, Users } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { auth, analytics, db } from '../config/firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import useSubscription from './useSubscription';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogCancel
} from '../components/ui/alert-dialog';
import SubscriptionPlansModal from './SubscriptionPlansModal';
import TeamSettings from './TeamSettings';
import TeamInvites from './TeamInvites';

const UserMenu = ({ user, onSignOut }) => {
    // State declarations
    const [isOpen, setIsOpen] = useState(false);
    const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [activeSettingsTab, setActiveSettingsTab] = useState('team');
    const [realTimeStats, setRealTimeStats] = useState(null);
    const menuRef = useRef(null);
    
    // Get subscription data and handlers from the hook
    const { 
      stats, 
      handleSubscribe, 
      handleCancelSubscription, 
      handleReactivateSubscription 
    } = useSubscription();
    
    // Fix 1: Improve initial stats loading to avoid showing 0 values
    useEffect(() => {
      if (stats) {
        setRealTimeStats(prevStats => {
          // Always update with new stats data when it becomes available
          // This ensures we don't lose data when stats changes
          return stats;
        });
      }
    }, [stats]);
    
    // Calculate team quota based on subscription data - defined before displayStats to avoid circular dependency
    const calculateTeamQuota = useMemo(() => {
      try {
        // Use baseStats from realTimeStats or stats
        const baseStats = realTimeStats || stats || {};
        
        if (!baseStats.limits) return null;
        
        const freeQuota = baseStats.limits?.free?.TOTAL_SEARCHES || 5;
        const proQuota = baseStats.limits?.paid?.MONTHLY_SEARCHES || 1000;
        
        // Get pro member count from subscription data
        const proMemberCount = baseStats.proMemberCount || 0;
        const freeMemberCount = (baseStats.teamMemberCount || 1) - proMemberCount;
        
        // Calculate quota based on verified data
        const totalQuota = (proMemberCount * proQuota) + (freeMemberCount * freeQuota);
        
        return {
          proMemberCount,
          freeMemberCount,
          totalQuota
        };
      } catch (error) {
        console.error("Error calculating team quota:", error);
        return null;
      }
    }, [realTimeStats, stats]);
    
    // Fix 3: Improve the displayStats calculation to ensure we have sensible defaults
    const displayStats = useMemo(() => {
      // Use available stats or default to empty structure
      const baseStats = realTimeStats || stats || {
        currentUsage: { totalSearches: 0 },
        isSubscribed: false,
        isTeamMember: false,
        teamMemberCount: 1,
        memberUsage: {},
        limits: { free: {}, paid: {}, team: null },
        searchesRemaining: { total: null }
      };
      
      // Calculate total quota based on subscription status
      // No daily limits - only monthly for pro and one-time for free
      
      let totalQuota = 0;
      let quotaSource = "unknown";
      
      if (baseStats.isTeamMember) {
        // Get quota values from the limits object rather than hardcoding
        const freeQuota = baseStats.limits?.free?.TOTAL_SEARCHES;
        const proQuota = baseStats.limits?.paid?.MONTHLY_SEARCHES;
        
        if (baseStats.limits?.team?.MONTHLY_SEARCHES) {
          // If server provides team limit directly, use that
          totalQuota = baseStats.limits.team.MONTHLY_SEARCHES;
          quotaSource = "server-team-monthly";
          console.log("Using server-provided team limit:", totalQuota);
        } else if (typeof baseStats.searchesRemaining?.total === 'number') {
          // Server tells us remaining searches, so add current usage to get total quota
          totalQuota = baseStats.searchesRemaining.total + (baseStats.currentUsage?.totalSearches || 0);
          quotaSource = "server-searches-remaining";
          console.log("Using searchesRemaining.total for quota:", totalQuota);
        } else {
          // If no server values, use our unified calculation from team subscription data
          const teamQuotaInfo = calculateTeamQuota;
          
          if (teamQuotaInfo && teamQuotaInfo.totalQuota) {
            totalQuota = teamQuotaInfo.totalQuota;
            quotaSource = "unified-subscription-data";
            
            console.log(`Team composition from unified source: ${teamQuotaInfo.proMemberCount} pro, ${teamQuotaInfo.freeMemberCount} free`);
            console.log("Team quota calculated:", totalQuota);
          } else {
            // Fallback to basic calculation
            totalQuota = baseStats.teamMemberCount * freeQuota;
            quotaSource = "fallback-all-free";
          }
        }
      } else {
        // For individual users, just use their tier quota (no daily limits)
        totalQuota = baseStats.isSubscribed ? 
          baseStats.limits?.paid?.MONTHLY_SEARCHES : 
          baseStats.limits?.free?.TOTAL_SEARCHES;
        quotaSource = "individual-user";
        console.log("Individual user quota:", totalQuota);
      }
      
      // Calculate user's portion and team portion for the progress bar
      // Get the user's usage from memberUsage if available, or determine a reasonable portion
      const userPortion = baseStats.memberUsage && baseStats.memberUsage[user.uid] !== undefined
        ? baseStats.memberUsage[user.uid]
        : baseStats.isSubscribed 
          // If the user is pro, attribute more searches to them
          ? Math.min(baseStats.currentUsage?.totalSearches || 0, totalQuota * 0.7) 
          // If user is free, attribute fewer searches
          : Math.min(baseStats.currentUsage?.totalSearches || 0, totalQuota * 0.3);
      
      // Team portion is total usage minus user's portion, but never more than remaining quota
      const totalUsage = baseStats.currentUsage?.totalSearches || 0;
      const teamPortion = Math.min(
        totalQuota - userPortion,  // Never use more than remaining quota
        Math.max(0, totalUsage - userPortion)  // Never negative
      );
      
      // Log usage attribution for debugging
      console.log("Usage attribution:", {
        totalSearches: totalUsage,
        userPortion,
        teamPortion,
        totalQuota,
        memberUsage: baseStats.memberUsage,
        userId: user.uid
      });

      // Simple debug log with final quota information
      console.log("Quota calculation:", {
        totalQuota,
        quotaSource,
        isTeamQuota: baseStats.isTeamMember
      });
      
      return {
        ...baseStats,
        currentUsage: {
          totalSearches: 0,
          ...baseStats.currentUsage
        },
        memberUsage: baseStats.memberUsage || {},
        teamMemberCount: baseStats.teamMemberCount || 1,
        // Add calculated quota
        totalQuota,
        quotaSource,
        // Apply the calculated portions
        userPortion,
        teamPortion,
        // Is the current user pro?
        isCurrentUserPro: baseStats.isSubscribed,
        // Does team have other pro users? Use our unified data source
        hasOtherProMembers: baseStats.teamMemberSubscriptions ? 
          // Check if any team member other than current user is subscribed
          Object.entries(baseStats.teamMemberSubscriptions)
            .some(([memberId, isSubscribed]) => memberId !== user.uid && isSubscribed === true) :
          false, // Cannot determine without subscription data
        // Add a loading flag to indicate when we're waiting for subscription data
        isLoadingSubscriptionData: baseStats.isTeamMember && 
          (!baseStats.teamMemberSubscriptions || Object.keys(baseStats.teamMemberSubscriptions).length === 0)
      };
    }, [realTimeStats, stats, user?.uid, calculateTeamQuota]); // Add calculateTeamQuota as dependency
    
    // Enhance the real-time listener to verify team member subscriptions
    useEffect(() => {
      if (!user) return;
      
      // Reference to the user's document
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribers = [];
      
      // Set up real-time listener for the user's document first
      const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const teamId = userData.teamId;
          
          // Update user's own data
          setRealTimeStats((prevStats) => {
            if (!prevStats) return stats || null;
            
            // Initial update with user's own data
            return {
              ...prevStats,
              currentUsage: {
                ...prevStats.currentUsage,
                ...(userData.usage || {})
              },
              teamId: teamId,
              isTeamMember: !!teamId,
              memberUsage: userData.memberUsage || prevStats.memberUsage || {},
              // User's own subscription status
              isSubscribed: userData.isSubscribed === true
            };
          });
          
          // If user belongs to a team, set up a listener for the team document
          // This will be our single source of truth for team data
          if (teamId) {
            // First, listen to the team document for member list
            const teamRef = doc(db, 'teams', teamId);
            const unsubscribeTeam = onSnapshot(teamRef, async (teamDoc) => {
              if (teamDoc.exists()) {
                const teamData = teamDoc.data();
                const memberIds = teamData.members.map(member => member.id);
                
                // Store team basic info immediately
                setRealTimeStats((prevStats) => {
                  if (!prevStats) return stats || null;
                  
                  return {
                    ...prevStats,
                    teamId,
                    isTeamMember: true,
                    teamMemberCount: memberIds.length,
                    // Reset subscriptions (will be populated by team-subscriptions doc)
                    teamMemberSubscriptions: {}
                  };
                });
                
                // Listen to the team-subscriptions document - this is our unified source for all subscription statuses
                const teamSubsRef = doc(db, 'team-subscriptions', teamId);
                const unsubscribeTeamSubs = onSnapshot(teamSubsRef, (teamSubsDoc) => {
                  // Get subscriptions data safely, handling different document structures
                  let subscriptions = {};
                  
                  if (teamSubsDoc.exists()) {
                    const data = teamSubsDoc.data();
                    // Support both formats: {subscriptions: {}} and direct key-value format
                    if (data.subscriptions && typeof data.subscriptions === 'object') {
                      subscriptions = data.subscriptions;
                    } else {
                      // Try to find any user ID keys in the document itself
                      Object.keys(data).forEach(key => {
                        // Check if key looks like a Firebase user ID and value is boolean
                        if (key.length > 20 && typeof data[key] === 'boolean') {
                          subscriptions[key] = data[key];
                        }
                      });
                    }
                  }
                  
                  // If there are no subscriptions yet, check individual team members
                  if (Object.keys(subscriptions).length === 0) {
                    console.log("No subscription data found in team-subscriptions document, using fallbacks");
                    
                    // We'll rely on user data for subscription status
                    // The UI will know it's loading because teamMemberSubscriptions will be empty
                    setRealTimeStats((prevStats) => {
                      if (!prevStats) return stats || null;
                      
                      return {
                        ...prevStats,
                        teamMemberSubscriptions: {},
                        proMemberCount: prevStats.isSubscribed ? 1 : 0, // Current user's status as fallback
                        hasSubscribedMember: false
                      };
                    });
                    
                    return;
                  }
                  
                  // Count pro members
                  const proMemberCount = Object.values(subscriptions).filter(Boolean).length;
                  
                  // Update with subscription data from our unified source
                  setRealTimeStats((prevStats) => {
                    if (!prevStats) return stats || null;
                    
                    // Ensure current user's subscription status is correctly reflected
                    const mergedSubscriptions = {
                      ...subscriptions,
                      // Include current user's subscription status if missing from team data
                      [user.uid]: subscriptions[user.uid] !== undefined ? 
                        subscriptions[user.uid] : 
                        prevStats.isSubscribed === true
                    };
                    
                    // Recount with merged data
                    const updatedProCount = Object.values(mergedSubscriptions).filter(Boolean).length;
                    
                    return {
                      ...prevStats,
                      teamMemberSubscriptions: mergedSubscriptions,
                      proMemberCount: updatedProCount,
                      hasSubscribedMember: Object.entries(mergedSubscriptions)
                        .some(([memberId, isSubscribed]) => memberId !== user.uid && isSubscribed === true),
                      // Flag if current user is pro (use both sources)
                      isCurrentUserPro: mergedSubscriptions[user.uid] === true || prevStats.isSubscribed === true
                    };
                  });
                }, (error) => {
                  console.error("Error listening to team subscription updates:", error);
                  
                  // On error, keep UI in loading state
                  setRealTimeStats((prevStats) => {
                    if (!prevStats) return stats || null;
                    
                    return {
                      ...prevStats,
                      teamMemberSubscriptions: {},
                      isLoadingSubscriptionData: true
                    };
                  });
                });
                
                unsubscribers.push(unsubscribeTeamSubs);
                
                // Also listen to team stats for usage data
                const teamStatsRef = doc(db, 'teamStats', teamId);
                const unsubscribeTeamStats = onSnapshot(teamStatsRef, (teamStatsDoc) => {
                  if (teamStatsDoc.exists()) {
                    const teamStatsData = teamStatsDoc.data();
                    
                    setRealTimeStats((prevStats) => {
                      if (!prevStats) return stats || null;
                      
                      return {
                        ...prevStats,
                        teamMemberCount: teamStatsData.memberCount || prevStats.teamMemberCount,
                        currentUsage: {
                          ...prevStats.currentUsage,
                          totalSearches: teamStatsData.totalSearchStats?.count || 0
                        },
                        memberUsage: teamStatsData.memberUsage || {}
                      };
                    });
                  }
                });
                
                unsubscribers.push(unsubscribeTeamStats);
              }
            });
            
            unsubscribers.push(unsubscribeTeam);
          }
        }
      });
      
      unsubscribers.push(unsubscribeUser);
      
      return () => {
        unsubscribers.forEach(unsub => unsub());
      };
    }, [user, stats]);
    
    // Close menu when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        logEvent(analytics, 'sign_out', { success: true });
        window.location.href = '/?signout=true';
      } catch (error) {
        logEvent(analytics, 'sign_out_error', {
          error_code: error.code,
          error_message: error.message
        });
        console.error('Error signing out:', error);
      }
    };
  
    const handleHelp = () => {
      window.location.href = 'mailto:support@beam.enterprises';
    };
  
    const handleSubscribeClick = async () => {
      setSubscriptionDialogOpen(false); // Close the modal first
      await handleSubscribe(); // Then initiate subscription
    };
  
    return (
      <div className="relative" ref={menuRef}>
        {/* Debug info - won't show in UI but available for debugging */}
        {process.env.NODE_ENV !== 'production' && console.log("UserMenu Debug:", {
          isTeamMember: displayStats?.isTeamMember,
          teamMemberCount: displayStats?.teamMemberCount,
          isSubscribed: displayStats?.isSubscribed,
          hasSubscribedMember: displayStats?.hasSubscribedMember,
          teamMemberSubscriptions: displayStats?.teamMemberSubscriptions,
          quotaSource: displayStats?.quotaSource,
          limits: {
            free: displayStats?.limits?.free,
            paid: displayStats?.limits?.paid,
            team: displayStats?.limits?.team
          },
          totalQuota: displayStats?.totalQuota,
          currentUsage: displayStats?.currentUsage
        })}
        
        <button
          onClick={(e) => {
            setIsOpen(!isOpen);
            // Remove focus when closing the menu
            if (isOpen) {
              e.currentTarget.blur();
            }
          }}
          className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none"
          aria-label="User menu"
        >
          <User className="w-5 h-5 text-gray-600" />
        </button>
  
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
            <div className="py-1">
              {/* User info section */}
              <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
                <div className="font-medium truncate mb-2">{user.email}</div>
                {displayStats?.isTeamMember && (
                  <p className="text-xs text-blue-600 font-medium mb-1">
                    Team: {displayStats?.teamMemberCount || 1} members
                  </p>
                )}
                <div className="space-y-1">
                  <div className="flex justify-between text-xs">
                    <span className="text-gray-500">Searches Used</span>
                    <span className="text-gray-900 font-medium">
                      {displayStats?.isLoadingSubscriptionData ? (
                        <span className="italic text-gray-400">Loading quota...</span>
                      ) : displayStats?.isTeamMember ? (
                        // Team quota display with max capping
                        `${Math.min(displayStats?.currentUsage?.totalSearches || 0, displayStats.totalQuota)}/${displayStats.totalQuota}`
                      ) : (
                        // For individual users
                        `${displayStats?.currentUsage?.totalSearches || 0}/${
                          displayStats?.isSubscribed 
                            ? displayStats?.limits?.paid?.MONTHLY_SEARCHES
                            : displayStats?.limits?.free?.TOTAL_SEARCHES
                        }`
                      )}
                    </span>
                  </div>
                  {displayStats?.isTeamMember ? (
                    <div className="space-y-1">
                      <div className="w-full bg-gray-100 rounded-full h-2.5 overflow-hidden">
                        {displayStats?.isLoadingSubscriptionData ? (
                          // Loading state for progress bar
                          <div className="h-full bg-gray-300 animate-pulse w-full"></div>
                        ) : (
                          // Normal progress bar
                          <div className="flex h-full">
                            {/* Current user's contribution - cap at 100% */}
                            <div 
                              className={`h-full ${
                                displayStats.isCurrentUserPro 
                                  ? 'bg-green-500' // Green for pro user's usage
                                  : 'bg-blue-500' // Blue for free user's usage
                              }`}
                              style={{ 
                                width: `${Math.min(100 * (displayStats.totalQuota > 0 ? 1 : 0), 
                                  (displayStats.userPortion / displayStats.totalQuota) * 100)}%` 
                              }}
                              title={`Your usage: ${Math.min(displayStats.userPortion, displayStats.totalQuota)} searches`}
                            />
                            {/* Other team members' contribution - cap to prevent overflow */}
                            <div 
                              className={`h-full ${
                                displayStats.hasOtherProMembers
                                  ? 'bg-green-400' // Lighter green for other pro team members
                                  : 'bg-blue-400' // Light blue for free team members
                              }`}
                              style={{ 
                                width: `${Math.min(
                                  100 - Math.min(100, (displayStats.userPortion / displayStats.totalQuota) * 100),
                                  (displayStats.teamPortion / displayStats.totalQuota) * 100
                                )}%` 
                              }}
                              title={`Team usage: ${Math.min(displayStats.teamPortion, 
                                displayStats.totalQuota - Math.min(displayStats.userPortion, displayStats.totalQuota))} searches`}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center text-xs">
                          <div className={`w-2 h-2 ${
                            displayStats.isCurrentUserPro
                              ? 'bg-green-500' // Green for pro user
                              : 'bg-blue-500' // Blue for free user
                          } rounded-full mr-1`}></div>
                          <span className="text-gray-600">You</span>
                        </div>
                        <div className="flex items-center text-xs">
                          <div className={`w-2 h-2 ${
                            displayStats.hasOtherProMembers
                              ? 'bg-green-400' // Lighter green for other pro team members
                              : 'bg-blue-400' // Light blue for free team members
                          } rounded-full mr-1`}></div>
                          <span className="text-gray-600">Team</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full bg-gray-100 rounded-full h-1.5">
                      <div 
                        className={`h-1.5 rounded-full ${
                          displayStats?.isSubscribed ? 'bg-green-500' : 'bg-blue-500'
                        }`}
                        style={{ 
                          width: `${Math.min(100, ((displayStats?.currentUsage?.totalSearches || 0) / 
                                 (displayStats?.isSubscribed 
                                   ? displayStats?.limits?.paid?.MONTHLY_SEARCHES
                                   : displayStats?.limits?.free?.TOTAL_SEARCHES)) * 100)}%` 
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              
              {/* Mobile subscription button */}
              <button
                onClick={() => {
                  setSubscriptionDialogOpen(true);
                  setIsOpen(false);
                }}
                className={`w-full flex items-center px-4 py-2 text-sm font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100 md:hidden ${
                  displayStats?.isSubscribed ? 'text-green-600' : 'text-[#2B82DE]'
                }`}
              >
                {displayStats?.isSubscribed ? (
                  <>
                    <Crown className="mr-2 h-4 w-4 text-green-600" />
                    Pro Subscription
                  </>
                ) : (
                  <>
                    <CreditCard className="mr-2 h-4 w-4 text-[#2B82DE]" />
                    Upgrade to Pro
                  </>
                )}
              </button>
              
              {/* Settings button */}
              <button
                onClick={() => {
                  setIsSettingsOpen(true);
                  setIsOpen(false);  // Close the dropdown menu
                }}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <Settings className="mr-2 h-4 w-4" />
                Settings
              </button>
              
              {/* Help button */}
              <button
                onClick={handleHelp}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <HelpCircle className="mr-2 h-4 w-4" />
                Help
              </button>

              {/* Sign out button */}
              <button
                onClick={handleSignOut}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Sign out
              </button>
            </div>
          </div>
        )}

        {/* Subscription dialog */}
        <AlertDialog open={subscriptionDialogOpen} onOpenChange={setSubscriptionDialogOpen}>
          {subscriptionDialogOpen && (
            <>
              <AlertDialogHeader>
                <AlertDialogTitle>Account Settings</AlertDialogTitle>
                <TeamInvites />
              </AlertDialogHeader>
              <div className="flex border-b">
                <button
                  onClick={() => setActiveSettingsTab('team')}
                  className={`px-4 py-2 text-sm ${
                    activeSettingsTab === 'team'
                      ? 'bg-gray-100 text-gray-900 font-medium'
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                  }`}
                >
                  <div className="flex items-center">
                    <Users className="w-4 h-4 mr-2" />
                    <span>Team</span>
                  </div>
                </button>
              </div>

              <div className="p-4">
                {activeSettingsTab === 'team' && <TeamSettings />}
              </div>
              
              <SubscriptionPlansModal 
                onClose={() => setSubscriptionDialogOpen(false)}
                stats={displayStats}
                onSubscribe={handleSubscribeClick}
                onCancel={handleCancelSubscription}
                onReactivate={handleReactivateSubscription}
              />
            </>
          )}
        </AlertDialog>

        {/* Settings dialog */}
        <AlertDialog open={isSettingsOpen} onOpenChange={setIsSettingsOpen}>
          <AlertDialogContent className="max-w-5xl p-0 overflow-hidden">
            <div className="flex flex-col h-[80vh] max-h-[700px]">
              <AlertDialogHeader className="p-4 border-b">
                <div className="flex items-center justify-between">
                  <AlertDialogTitle className="text-2xl font-bold text-gray-900">Settings</AlertDialogTitle>
                  <AlertDialogCancel 
                    onClick={() => setIsSettingsOpen(false)}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    <X className="w-5 h-5 text-gray-500" />
                  </AlertDialogCancel>
                </div>
              </AlertDialogHeader>

              <div className="flex flex-1 overflow-hidden">
                {/* Left sidebar for settings navigation */}
                <div className="w-48 border-r bg-white">
                  <button
                    onClick={() => setActiveSettingsTab('team')}
                    className={`w-full flex items-center px-4 py-3 text-sm ${
                      activeSettingsTab === 'team'
                        ? 'bg-gray-100 text-gray-900 font-medium'
                        : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    <Users className="w-4 h-4 mr-2" />
                    <span>Team</span>
                  </button>
                  {/* You can add more settings tabs here in the future */}
                </div>

                {/* Right content area for the active settings tab */}
                <div className="flex-1 overflow-y-auto p-4">
                  <TeamInvites />
                  {activeSettingsTab === 'team' && <TeamSettings />}
                  {/* Render other settings components based on activeSettingsTab */}
                </div>
              </div>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    );
};

export default UserMenu;