import React, { useState, useRef, useEffect } from 'react';
import { Upload, Edit, Trash, Check, X, FileSpreadsheet } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { auth, db } from '../config/firebase';
import { 
  collection, doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc, writeBatch, query, where, orderBy, onSnapshot 
} from 'firebase/firestore';
import { debounce } from 'lodash';
import { API_URL } from '../services/api';
import CountrySelect from './CountrySelect';
import GenderSelect from './GenderSelect';
import DatePicker from './DatePicker';
import { COUNTRY_MAPPING } from '../constants/countries';

const BulkImport = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState('upload'); // 'upload', 'review', 'processing'
  const [progress, setProgress] = useState(0);
  const [editingRow, setEditingRow] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [userLimits, setUserLimits] = useState(null);
  const [searchHistoryCount, setSearchHistoryCount] = useState(0);
  const [wasStopped, setWasStopped] = useState(false); // Track explicitly stopped processes
  const fileInputRef = useRef(null);
  const errorToastShownRef = useRef(false);
  const [isDragging, setIsDragging] = useState(false);
  const dropZoneRef = useRef(null);

  // Replace the useCallback debounced function with a useRef implementation
  const debouncedSaveRef = useRef(null);
  
  // Initialize the debounced function in useEffect
  useEffect(() => {
    // Create the debounced function once
    debouncedSaveRef.current = debounce((entities) => {
      saveEntitiesToFirestore(entities);
    }, 2000);
    
    // Cleanup function
    return () => {
      if (debouncedSaveRef.current?.cancel) {
        debouncedSaveRef.current.cancel();
      }
    };
  }, []);  // Empty dependency array - only run once on mount

  // Add new function to fetch user limits
  const fetchUserLimits = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/user-stats`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch user limits');
      }
      
      const data = await response.json();
      setUserLimits(data);
      return data;
    } catch (error) {
      console.error('Error fetching user limits:', error);
      return null;
    }
  };

  // Fetch user limits when component mounts
  useEffect(() => {
    fetchUserLimits();
  }, []);

  // Load any in-progress batch when the component mounts
  useEffect(() => {
    const savedBatchId = localStorage.getItem('currentBatchId');
    const savedStep = localStorage.getItem('currentImportStep');
    const savedUserId = localStorage.getItem('currentImportUserId');
    const savedStoppedState = localStorage.getItem('importWasStopped') === 'true';
    
    // Only restore the import process if it belongs to the current user
    if (savedBatchId && savedUserId && savedUserId === auth.currentUser?.uid) {
      console.log('Found saved import process:', savedBatchId);
      setBatchId(savedBatchId);
      setWasStopped(savedStoppedState);
      
      // Load entity data from Firestore if we were in the review step
      if (savedStep === 'review') {
        loadEntitiesFromFirestore(savedBatchId);
      }
      
      setCurrentStep(savedStep || 'upload');
      
      // If we're in processing step, start checking status
      if (savedStep === 'processing') {
        checkImportStatus();
      }
    } else if (savedBatchId || savedStep === 'processing') {
      // Clear invalid import data
      localStorage.removeItem('currentBatchId');
      localStorage.removeItem('currentImportStep');
      localStorage.removeItem('currentImportUserId');
      localStorage.removeItem('importWasStopped');
    }
  }, []);
  
  // Save current batch state to localStorage when it changes
  useEffect(() => {
    if (batchId) {
      localStorage.setItem('currentBatchId', batchId);
      localStorage.setItem('currentImportStep', currentStep);
      // Store the user ID with the import data
      localStorage.setItem('currentImportUserId', auth.currentUser?.uid);
      // Store the stopped state
      localStorage.setItem('importWasStopped', wasStopped.toString());
    } else {
      // Clear storage when no active batch
      localStorage.removeItem('currentBatchId');
      localStorage.removeItem('currentImportStep');
      localStorage.removeItem('currentImportUserId');
      localStorage.removeItem('importWasStopped');
    }
  }, [batchId, currentStep, wasStopped]);

  // Update the useEffect that watches parsedData changes
  useEffect(() => {
    if (batchId && parsedData.length > 0 && currentStep === 'review' && debouncedSaveRef.current) {
      debouncedSaveRef.current(parsedData);
    }
  }, [parsedData, batchId, currentStep]);

  // Real-time listeners approach: 
  // This component now uses Firestore real-time listeners for status updates
  // instead of the previous polling with intervals. This provides more immediate
  // updates and reduces server load.
  useEffect(() => {
    if (!batchId || !auth.currentUser) return;
    
    console.log('Setting up real-time listeners for batch:', batchId);
    
    // Listen for batch status updates
    const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId);
    const statusRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'status', 'current');
    
    // Create listeners for batch document and status document
    const unsubscribeBatch = onSnapshot(batchRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        console.log('Batch update:', data.status, data.progress);
        
        setProgress(data.progress || 0);
        
        // Update UI based on status
        if (data.status === 'completed') {
          if (isProcessing) {
            fetchSearchHistoryCount().then(count => {
              setSearchHistoryCount(count);
              toast.success(`${count || 'All'} entities have been processed and added to search history`);
              setIsProcessing(false);
            });
            
            // Clear localStorage
            localStorage.removeItem('currentBatchId');
            localStorage.removeItem('currentImportStep');
            localStorage.removeItem('currentImportUserId');
            localStorage.removeItem('importWasStopped');
          }
        } else if (data.status === 'failed') {
          if (isProcessing && !errorToastShownRef.current) {
            toast.error(`Processing failed: ${data.error || 'Unknown error'}`);
            errorToastShownRef.current = true;
            setIsProcessing(false);
          }
        } else if (data.status === 'stopped' || data.status === 'stopping') {
          console.log('Detected stop status change in Firestore:', data.status);
          setWasStopped(true);
          
          if (isProcessing) {
            console.log('Processing was active, stopping it now');
            setIsProcessing(false);
            
            fetchSearchHistoryCount().then(count => {
              console.log('Fetched search history count after stop:', count);
              setSearchHistoryCount(count);
              toast.success(`Import process stopped. ${count || 0} entities have been saved.`);
            }).catch(err => {
              console.error('Error fetching search history count after stop:', err);
              toast.success(`Import process stopped.`);
            });
          } else {
            console.log('Processing was already inactive when stop status arrived');
          }
        }
        
        // Update processedEntities array if present
        if (data.processedEntities && data.processedEntities.length > 0) {
          setParsedData(prev => {
            // Create a map of current entity data by ID
            const entityMap = prev.reduce((map, entity) => {
              map[entity.id] = entity;
              return map;
            }, {});
            
            // Update entities with processing results
            data.processedEntities.forEach(processedEntity => {
              if (entityMap[processedEntity.entityId]) {
                entityMap[processedEntity.entityId] = {
                  ...entityMap[processedEntity.entityId],
                  processed: true,
                  hasMatches: processedEntity.hasMatches,
                  matchCount: processedEntity.matchCount,
                  searchHistoryId: processedEntity.searchHistoryId,
                  error: processedEntity.error || null
                };
              }
            });
            
            // Convert map back to array, maintaining original order
            return prev.map(entity => entityMap[entity.id]);
          });
        }
      }
    });
    
    // Listen for more real-time entity updates
    const entitiesRef = collection(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'entities');
    const entitiesListener = onSnapshot(query(entitiesRef), (snapshot) => {
      // Track changes
      let updatedEntities = [];
      
      snapshot.docChanges().forEach((change) => {
        const entityData = change.doc.data();
        
        if (change.type === 'modified') {
          console.log('Entity updated:', entityData.id, entityData.status);
          updatedEntities.push(entityData);
        }
      });
      
      // Update parsed data if we have changes
      if (updatedEntities.length > 0) {
        setParsedData(prev => {
          // Create a new array
          const newData = [...prev];
          
          // Update modified entities
          updatedEntities.forEach(updatedEntity => {
            const index = newData.findIndex(e => e.id === updatedEntity.id);
            if (index !== -1) {
              newData[index] = {
                ...newData[index], 
                ...updatedEntity,
                // Preserve any local state not in the updated entity
                individualName: newData[index].individualName || updatedEntity.individualName,
                companyName: newData[index].companyName || updatedEntity.companyName
              };
            }
          });
          
          return newData;
        });
      }
    });
    
    // Listen for results to update entity status
    const resultsRef = collection(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'results');
    const resultsListener = onSnapshot(query(resultsRef), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          const resultData = change.doc.data();
          
          // Update the corresponding entity
          setParsedData(prev => {
            return prev.map(entity => {
              if (entity.id === resultData.entityId) {
                return {
                  ...entity,
                  processed: true,
                  hasMatches: resultData.hasMatches || false,
                  matchCount: resultData.matchCount || 0,
                  searchHistoryId: resultData.searchHistoryId,
                  error: resultData.error || null
                };
              }
              return entity;
            });
          });
        }
      });
    });
    
    // Return cleanup function
    return () => {
      console.log('Cleaning up Firestore listeners for batch:', batchId);
      unsubscribeBatch();
      entitiesListener();
      resultsListener();
      
      // Note: This cleanup function replaces the old polling interval cleanup
    };
  }, [batchId]);

  // Handle file selection
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    
    console.log('Selected file:', {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size
    });
    
    // Check user limits before proceeding
    const limits = userLimits || await fetchUserLimits();
    
    if (limits && limits.isLimited) {
      // Simplified error message
      toast.error('Search limit reached. Please upgrade for more searches.');
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }
    
    setFile(selectedFile);
    uploadFile(selectedFile);
  };

  // Upload file to backend
  const uploadFile = async (file) => {
    setIsUploading(true);
    try {
      // For CSV files, read content and send via consolidated endpoint
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        const fileContent = await file.text();
        
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${API_URL}/api/bulk-import/process-batch`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            file: fileContent,
            filename: file.name,
            action: 'upload'
          })
        });
        
        if (!response.ok) {
          throw new Error(`Upload failed: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data && data.batchId) {
          setBatchId(data.batchId);
          await structureData(data.batchId);
        } else {
          throw new Error('No batch ID returned from server');
        }
      } else {
        toast.error('Only CSV files are supported at this time');
        setFile(null);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file: ' + error.message);
      setFile(null);
    } finally {
      setIsUploading(false);
    }
  };

  // Request backend to structure data using OpenAI
  const structureData = async (id) => {
    setIsLoading(true);
    try {
      const token = await auth.currentUser.getIdToken();
      
      // Request data structuring using consolidated endpoint
      const response = await fetch(`${API_URL}/api/bulk-import/process-batch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          batchId: id,
          action: 'structure'  // Explicitly specify structure action
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to structure data');
      }
      
      const data = await response.json();
      
      // Extract entities from the response with simplified logic
      let entities = [];
      if (data.data && data.data.entities) {
        entities = data.data.entities;
      } else if (data.entities) {
        entities = data.entities;
      }
      
      if (entities.length === 0) {
        throw new Error('No entities found in the response');
      }
      
      setParsedData(entities);
      
      // Create the batch document first
      const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', id);
      await setDoc(batchRef, {
        status: 'structured',
        entityCount: entities.length,
        createdAt: new Date(),
        updatedAt: new Date(),
        userId: auth.currentUser.uid,
        fileName: file?.name || 'Imported data'
      }, { merge: true });
      
      // Save entities to Firestore via backend
      await saveEntitiesToBackend(entities);
      
      setCurrentStep('review');
      
    } catch (error) {
      console.error('Error structuring data:', error);
      toast.error('Failed to process file: ' + error.message);
      setFile(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the saveEntitiesToBackend function to ensure consistent implementation
  const saveEntitiesToBackend = async (entities) => {
    if (!batchId || !auth.currentUser) return;
    
    try {
      const token = await auth.currentUser.getIdToken();
      
      const response = await fetch(`${API_URL}/api/bulk-import/update-entities`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          batchId: batchId,
          entities: entities,
          action: 'update'  // Keeping the explicit action parameter
        })
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error saving entities:', errorText);
        // Don't throw - we don't want to interrupt the UI flow
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error saving entities to backend:', error);
      return false;
    }
  };

  // Update saveEntitiesToFirestore to also call backend API
  const saveEntitiesToFirestore = async (entities) => {
    if (!batchId || !auth.currentUser) return;
    
    try {
      // Update the batch status
      const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId);
      await updateDoc(batchRef, {
        status: 'editing',
        entityCount: entities.length,
        updatedAt: new Date()
      });
      
      // Use batched writes for Firestore
      const batchSize = 500; // Firestore batch limit is 500 operations
      let batch = writeBatch(db);
      let operationCount = 0;
      
      for (const entity of entities) {
        const entityRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'entities', entity.id.toString());
        batch.set(entityRef, {
          ...entity,
          lastEdited: new Date()
        });
        
        operationCount++;
        
        // Commit batch when it reaches the limit and start a new one
        if (operationCount >= batchSize) {
          await batch.commit();
          batch = writeBatch(db);
          operationCount = 0;
        }
      }
      
      // Commit any remaining operations
      if (operationCount > 0) {
        await batch.commit();
      }
      
      // Also save to backend for consistency
      await saveEntitiesToBackend(entities);
      
      console.log(`Saved ${entities.length} entities to Firestore and backend`);
    } catch (error) {
      console.error('Error saving entities to Firestore:', error);
      // Don't show a toast for every save as it could be annoying
    }
  };

  // Process all entities
  const processEntities = async () => {
    // Reset error toast flag when starting a new process
    errorToastShownRef.current = false;
    
    // Reset wasStopped flag when starting a new process
    setWasStopped(false);
    
    setIsProcessing(true);
    setCurrentStep('processing');
    setProgress(0);
    setSearchHistoryCount(0); // Reset search history count
    
    try {
      const token = await auth.currentUser.getIdToken();
      
      console.log('Starting batch processing:', batchId);
      
      // Update batch status to 'processing' in Firestore
      const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId);
      await updateDoc(batchRef, {
        status: 'processing',
        progress: 0,
        updatedAt: new Date(),
        stopRequested: false // Explicitly set stopRequested to false
      });
      
      console.log('Updated batch status in Firestore to processing');
      
      // Initiate processing through the backend API
      console.log('Calling backend API to start processing');
      const response = await fetch(`${API_URL}/api/bulk-import/process-batch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          batchId: batchId,
          entities: parsedData,
          action: 'process'  // Explicitly specify process action
        })
      });
      
      console.log('Backend API response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to start processing');
      }
      
      // Do a single initial status check to confirm the process started
      await checkProcessingStatus(token);
      
      // Real-time updates will now be handled by the Firestore listeners
      console.log('Processing initiated - updates will be handled by Firestore listeners');
      
    } catch (error) {
      console.error('Error processing entities:', error);
      
      if (!errorToastShownRef.current) {
        toast.error('Error processing entities: ' + error.message);
        errorToastShownRef.current = true;
      }
      
      setIsProcessing(false);
    }
  };

  // Enhance fetchSearchHistoryCount with better error handling and return value
  const fetchSearchHistoryCount = async () => {
    try {
      if (!batchId || !auth.currentUser) return 0;
      
      // Add retry mechanism to ensure we get accurate counts
      const maxRetries = 3;
      let retryCount = 0;
      let lastCount = 0;
      
      const attemptFetch = async () => {
        const token = await auth.currentUser.getIdToken();
        const response = await fetch(`${API_URL}/api/bulk-import/search-history-count?batchId=${batchId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Failed to fetch search history count: ${response.status} - ${errorText}`);
          return 0;
        }
        
        const data = await response.json();
        console.log('Search history count from backend:', data.count);
        return data.count || 0;
      };
      
      // Initial fetch
      lastCount = await attemptFetch();
      
      // Try a few more times with a delay to catch any late additions
      while (retryCount < maxRetries) {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second
        
        const newCount = await attemptFetch();
        if (newCount > lastCount) {
          lastCount = newCount;
          // Reset retry count if we got a higher count, keep trying
          retryCount = 0;
        } else {
          // Count didn't increase, increment retry counter
          retryCount++;
        }
      }
      
      return lastCount;
    } catch (error) {
      console.error('Error fetching search history count:', error);
      return 0;
    }
  };

  // Update stopImport to use the consolidated endpoint
  const stopImport = async () => {
    try {
      if (!batchId) return;
      
      // Show loading toast
      let toastId = toast.loading("Stopping import process...");
      
      // Set wasStopped to true to indicate user explicitly stopped the process
      setWasStopped(true);
      
      const token = await auth.currentUser.getIdToken();
      
      // We don't need to immediately set isProcessing to false here
      // The real-time listener will update this state when the Firestore documents change
      
      // Log the request being sent
      console.log('Sending stop request for batch:', batchId);
      
      // Send the stop request to the backend
      const response = await fetch(`${API_URL}/api/bulk-import/process-batch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          batchId: batchId,
          action: 'stop'
        })
      });
      
      // Log the response status
      console.log('Stop request response status:', response.status);
      
      // Get the response data for logging
      let responseData;
      let responseText = '';
      try {
        responseText = await response.text();
        try {
          responseData = JSON.parse(responseText);
          console.log('Stop request response data:', responseData);
        } catch (parseError) {
          console.log('Response is not valid JSON:', responseText);
        }
      } catch (e) {
        console.log('Failed to read response body:', e);
      }
      
      if (!response.ok) {
        throw new Error((responseData && responseData.error) || `Failed to stop import process: ${response.status} ${responseText}`);
      }
      
      // As a fallback, directly update the Firestore document ourselves
      // This ensures the UI updates even if the backend fails to update the document
      try {
        const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId);
        await updateDoc(batchRef, {
          status: 'stopping',
          stopRequested: true,
          updatedAt: new Date()
        });
        console.log('Updated Firestore document status to stopping');
        
        // Also update the status document in the subcollection that listeners watch
        const statusRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'status', 'current');
        try {
          await setDoc(statusRef, {
            stopRequested: true,
            status: 'stopping',
            timestamp: new Date()
          });
          console.log('Updated status subcollection document');
        } catch (statusError) {
          console.error('Failed to update status subcollection:', statusError);
        }
      } catch (dbError) {
        console.error('Failed to update Firestore document:', dbError);
        // Continue execution - don't throw here
      }
      
      // We don't need to manually update UI state here
      // The toast can stay until the real-time listener receives the update
      
      // Success toast will be shown once the listener detects the status change
      // But we can update the "loading" toast to indicate the request was sent
      toast.success(`Stop request sent. Processing will end soon.`, { id: toastId });
      
      // Manually set isProcessing to false after a timeout as fallback
      // This ensures the UI updates even if the Firestore listener fails
      setTimeout(() => {
        if (isProcessing) {
          console.log('Fallback: Setting isProcessing to false after timeout');
          setIsProcessing(false);
          fetchSearchHistoryCount().then(count => {
            setSearchHistoryCount(count);
          });
        }
      }, 5000); // 5 second fallback
      
    } catch (error) {
      console.error('Error stopping import:', error);
      toast.error('Failed to stop import: ' + error.message);
      
      // Even if there's an error, we should still try to update the UI
      setIsProcessing(false);
    }
  };

  // Component cleanup
  useEffect(() => {
    // No need to manage a poll interval anymore as we're using real-time Firestore listeners
    // The cleanup for Firestore listeners is handled in the useEffect that sets them up
    
    // This is just here as a placeholder in case we need to add any component-level cleanup later
    return () => {
      console.log('Component unmounting, cleaning up resources');
    };
  }, []);

  // Add a function to check status when returning to the page
  const checkImportStatus = async () => {
    // Reset error toast flag when checking import status
    errorToastShownRef.current = false;
    
    if (batchId) {
      try {
        const token = await auth.currentUser.getIdToken();
        
        // First, immediately fetch all entities for the batch to make sure the table is populated right away
        const entitiesResponse = await fetch(`${API_URL}/api/bulk-import/entities?batchId=${batchId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (entitiesResponse.ok) {
          const entitiesData = await entitiesResponse.json();
          if (entitiesData.entities && entitiesData.entities.length > 0) {
            console.log('Successfully loaded batch entities:', entitiesData.entities.length);
            setParsedData(entitiesData.entities);
          }
        }
        
        // Then check the overall status once
        const status = await checkProcessingStatus(token);
        
        // Check for failure first
        if (status.status === 'failed') {
          setIsProcessing(false);
          
          if (!errorToastShownRef.current) {
            toast.error(`Import failed: ${status.error || 'Unknown error'}`);
            errorToastShownRef.current = true;
          }
          
          // Clear localStorage since processing failed
          localStorage.removeItem('currentBatchId');
          localStorage.removeItem('currentImportStep');
          localStorage.removeItem('currentImportUserId');
          localStorage.removeItem('importWasStopped');
          return;
        }
        
        // If we return and the process is already completed, update UI state
        if (status.status === 'completed' || status.progress >= 100) {
          setIsProcessing(false);
        } else if (status.status === 'stopped' || status.status === 'stopping') {
          setWasStopped(true);
          setIsProcessing(false);
        } else {
          // If still in progress, set processing flag
          setIsProcessing(true);
          console.log('Process still in progress - updates will be handled by Firestore listeners');
        }
      } catch (error) {
        console.error('Error checking import status:', error);
        
        if (!errorToastShownRef.current) {
          toast.error('Error checking import status: ' + error.message);
          errorToastShownRef.current = true;
        }
        
        setIsProcessing(false);
      }
    }
  };

  // Add useEffect to check status when the component mounts
  useEffect(() => {
    if (batchId && currentStep === 'processing') {
      checkImportStatus();
    }
  }, [batchId, currentStep]);

  // Handle editing a row
  const startEditing = (rowId) => {
    const row = parsedData.find(r => r.id === rowId);
    if (row) {
      setEditingRow({...row});
    }
  };

  const saveEditing = async () => {
    if (editingRow) {
      // Update local state
      const updatedData = parsedData.map(row => 
        row.id === editingRow.id ? {...editingRow} : row
      );
      setParsedData(updatedData);
      
      // Save to Firestore immediately for this critical operation
      if (batchId && auth.currentUser) {
        try {
          const entityRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'entities', editingRow.id.toString());
          await setDoc(entityRef, {
            ...editingRow,
            lastEdited: new Date()
          });
          
          // Also save to backend API for consistency
          const token = await auth.currentUser.getIdToken();
          await fetch(`${API_URL}/api/bulk-import/update-entities`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              batchId: batchId,
              entities: [editingRow],  // Send only the edited entity
              action: 'update'  // Explicitly specify update action
            })
          });
        } catch (error) {
          console.error('Error saving entity edit:', error);
          toast.error('Failed to save entity changes');
        }
      }
      
      setEditingRow(null);
    }
  };

  const cancelEditing = () => {
    setEditingRow(null);
  };

  // Handle country selection
  const handleCountryChange = (value) => {
    if (editingRow) {
      setEditingRow({...editingRow, country: value.target.value});
    }
  };

  // Handle gender selection
  const handleGenderChange = (value) => {
    if (editingRow) {
      setEditingRow({...editingRow, gender: value.target.value});
    }
  };

  // Handle date selection
  const handleDateChange = (value) => {
    if (editingRow) {
      setEditingRow({...editingRow, dob: value.target.value});
    }
  };

  // Request backend to get processing status (simplified for one-time checks)
  const checkProcessingStatus = async (token) => {
    try {
      console.log('Checking processing status for batch:', batchId);
      
      const statusResponse = await fetch(`${API_URL}/api/bulk-import/status?batchId=${batchId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('Status response status code:', statusResponse.status);
      
      if (!statusResponse.ok) {
        console.error('Failed to get processing status, status code:', statusResponse.status);
        const errorText = await statusResponse.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to get processing status: ${statusResponse.status} - ${errorText}`);
      }
      
      const statusData = await statusResponse.json();
      
      // Update progress
      console.log('Status check:', { 
        progress: statusData.progress, 
        status: statusData.status,
        stopRequested: statusData.stopRequested,
        processed: statusData.processedCount,
        total: statusData.entityCount || parsedData.length
      });
      
      // Check if we're in a stopping state but the backend hasn't yet seen it
      if ((statusData.status === 'processing' || !statusData.status) && wasStopped) {
        console.log('Warning: Backend reports processing status but UI shows stopped - mismatch detected');
        
        // Update Firestore document to ensure status is properly set
        try {
          const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId);
          await updateDoc(batchRef, {
            status: 'stopping',
            stopRequested: true,
            updatedAt: new Date()
          });
          console.log('Updated Firestore document to enforce stopping status');
        } catch (dbError) {
          console.error('Failed to update Firestore for status mismatch:', dbError);
        }
      }
      
      // Set progress
      setProgress(statusData.progress || 0);
      
      // Update processed entities with the latest data from the server
      if (statusData.processedEntities && statusData.processedEntities.length > 0) {
        setParsedData(prev => {
          // If we don't have any data yet, don't try to update
          if (prev.length === 0) return prev;
          
          // Create a map of current entity data by ID
          const entityMap = prev.reduce((map, entity) => {
            map[entity.id] = entity;
            return map;
          }, {});
          
          // Update entities with processing results
          statusData.processedEntities.forEach(processedEntity => {
            if (entityMap[processedEntity.entityId]) {
              entityMap[processedEntity.entityId] = {
                ...entityMap[processedEntity.entityId],
                processed: true,
                hasMatches: processedEntity.hasMatches,
                matchCount: processedEntity.matchCount,
                searchHistoryId: processedEntity.searchHistoryId,
                error: processedEntity.error || null
              };
            }
          });
          
          // Convert map back to array, maintaining original order
          return prev.map(entity => entityMap[entity.id]);
        });
      }
      
      return statusData;
    } catch (error) {
      console.error('Error checking status:', error);
      throw error;
    }
  };

  // Helper function to fetch all entities for a batch
  const fetchAllEntities = async (token) => {
    try {
      // First, try to get entities directly from the database
      const response = await fetch(`${API_URL}/api/bulk-import/entities?batchId=${batchId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch entities');
      }
      
      const data = await response.json();
      console.log('Fetched initial entity data - real-time updates will be handled by Firestore listeners');
      return data.entities || [];
    } catch (error) {
      console.error('Error fetching all entities:', error);
      return [];
    }
  };

  // Function to load entities from the backend
  const loadEntitiesFromFirestore = async (batchId) => {
    if (!batchId || !auth.currentUser) return;
    
    try {
      setIsLoading(true);
      
      // Use our API to fetch entities
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/bulk-import/entities?batchId=${batchId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to load entities');
      }
      
      const data = await response.json();
      
      if (data.entities && Array.isArray(data.entities)) {
        console.log(`Loaded ${data.entities.length} entities from backend`);
        
        // Set initial entities
        setParsedData(data.entities);
        
        // Then check for processed entities in results collection
        try {
          const resultsRef = collection(db, 'users', auth.currentUser.uid, 'import-batches', batchId, 'results');
          const resultsSnapshot = await getDocs(resultsRef);
          
          if (!resultsSnapshot.empty) {
            console.log(`Found ${resultsSnapshot.size} processed results`);
            
            // Create a set of processed entity IDs
            const processedIds = new Set();
            resultsSnapshot.forEach(doc => {
              const result = doc.data();
              if (result.entityId) {
                processedIds.add(result.entityId.toString());
              }
            });
            
            // Update entities to mark as processed
            if (processedIds.size > 0) {
              setParsedData(prev => 
                prev.map(entity => 
                  processedIds.has(entity.id.toString())
                    ? { ...entity, processed: true }
                    : entity
                )
              );
            }
          }
        } catch (error) {
          console.error('Error checking processed status:', error);
        }
      } else {
        console.warn('No entities found or invalid response format');
      }
    } catch (error) {
      console.error('Error loading entities:', error);
      toast.error('Failed to load saved entities');
      
      // If we fail to load the data, reset to upload stage
      setBatchId(null);
      setCurrentStep('upload');
      
      // Clear localStorage
      localStorage.removeItem('currentBatchId');
      localStorage.removeItem('currentImportStep');
      localStorage.removeItem('currentImportUserId');
      localStorage.removeItem('importWasStopped');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Function to delete a row from the parsed data
  const deleteRow = async (rowId) => {
    try {
      // Update local state first for immediate UI feedback
      const updatedData = parsedData.filter(row => row.id !== rowId);
      setParsedData(updatedData);
      
      // Delete from the backend if we have a batch ID
      if (batchId && auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        
        const response = await fetch(`${API_URL}/api/bulk-import/entity/${batchId}/${rowId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Server error: ${response.status} - ${errorText}`);
        }
        
        console.log(`Successfully deleted entity ${rowId}`);
      }
    } catch (error) {
      console.error('Error deleting entity:', error);
      toast.error(`Failed to delete entity: ${error.message}`);
    }
  };

  // Add this new function to continue processing after it was stopped
  const continueImport = async () => {
    try {
      // Reset error toast flag when starting a new process
      errorToastShownRef.current = false;
      
      // Reset wasStopped flag when starting a new process
      setWasStopped(false);
      
      // Set processing flag to true to indicate we're processing again
      setIsProcessing(true);
      
      console.log('Continuing import for batch:', batchId);
      
      // Get the user's auth token
      const token = await auth.currentUser.getIdToken();
      
      // Update the batch status in Firestore to processing
      const batchRef = doc(db, 'users', auth.currentUser.uid, 'import-batches', batchId);
      await updateDoc(batchRef, {
        status: 'processing',
        stopRequested: false,  // Make sure stopRequested is explicitly set to false
        updatedAt: new Date()
      });
      
      console.log('Updated Firestore batch status to continue processing');
      
      // Call the API to continue processing
      console.log('Calling backend to continue processing');
      const response = await fetch(`${API_URL}/api/bulk-import/process-batch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          batchId: batchId,
          entities: parsedData.filter(entity => !entity.processed), // Only process unprocessed entities
          action: 'process'
        })
      });
      
      console.log('Continue processing response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from continue processing:', errorData);
        throw new Error(errorData.error || 'Failed to continue processing');
      }
      
      // Immediately check status once to get initial state
      await checkProcessingStatus(token);
      
      // Real-time updates will now be handled by the Firestore listeners
      console.log('Processing continued - updates will be handled by Firestore listeners');
      
    } catch (error) {
      console.error('Error continuing import:', error);
      
      if (!errorToastShownRef.current) {
        toast.error('Error continuing import: ' + error.message);
        errorToastShownRef.current = true;
      }
      
      setIsProcessing(false);
    }
  };

  // Add drag and drop event handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    // Check user limits before processing the dropped file
    const limits = userLimits || await fetchUserLimits();
    
    if (limits && limits.isLimited) {
      toast.error('Search limit reached. Please upgrade for more searches.');
      return;
    }
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const droppedFile = files[0];
      
      // Check if the file is a valid type
      if (droppedFile.type === 'text/csv' || 
          droppedFile.name.endsWith('.csv') || 
          droppedFile.name.endsWith('.xls') || 
          droppedFile.name.endsWith('.xlsx')) {
        setFile(droppedFile);
        uploadFile(droppedFile);
      } else {
        toast.error('Only CSV, XLS, and XLSX files are supported');
      }
    }
  };

  return (
    <div className="mt-6 border rounded-xl p-6 bg-white">
      {currentStep === 'upload' && (
        <div className="flex flex-col items-center justify-center p-6">
          <h3 className="text-xl font-semibold mb-4">Bulk Import Entities</h3>
          <p className="text-gray-600 mb-6 text-center">
            Upload a CSV or Excel file containing entity details for bulk processing.
            <br />
            We'll help format your data and allow you to review before processing.
          </p>
          
          <div className="w-full max-w-md">
            <div 
              ref={dropZoneRef}
              className={`border-2 border-dashed ${isDragging ? 'border-blue-400 bg-blue-50' : 'border-gray-300'} rounded-lg p-8 text-center hover:border-gray-400 transition-colors cursor-pointer`}
              onClick={async () => {
                // Check user limits before opening file picker
                const limits = userLimits || await fetchUserLimits();
                
                if (limits && limits.isLimited) {
                  toast.error('Search limit reached. Please upgrade for more searches.');
                  return;
                }
                
                fileInputRef.current.click();
              }}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <FileSpreadsheet className={`h-12 w-12 ${isDragging ? 'text-blue-500' : 'text-gray-400'} mx-auto mb-4`} />
              <p className="text-gray-700 font-medium">
                {file ? file.name : 'Click to select a file or drag and drop'}
              </p>
              <p className="text-gray-500 text-sm mt-1">
                Supports CSV, XLS, XLSX
              </p>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
                accept=".csv,.xls,.xlsx"
              />
            </div>
          </div>
          
          {(isUploading || isLoading) && (
            <div className="mt-6 flex items-center">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-500"></div>
              <span className="ml-3 text-gray-600">
                {isUploading ? 'Uploading file...' : 'Processing data with AI...'}
              </span>
            </div>
          )}
        </div>
      )}

      {currentStep === 'review' && (
        <div>
          <h3 className="text-xl font-semibold mb-4">Review Entities</h3>
          <p className="text-gray-600 mb-6 text-sm">
            Review and edit the extracted entities before processing. You can edit details or remove entries as needed.
          </p>
          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {parsedData.map((row) => (
                  <tr key={row.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button 
                        className="text-blue-600 hover:text-blue-900 mr-4"
                        onClick={() => startEditing(row.id)}
                      >
                        <Edit className="h-5 w-5" />
                      </button>
                      <button 
                        className="text-red-600 hover:text-red-900"
                        onClick={() => deleteRow(row.id)}
                      >
                        <Trash className="h-5 w-5" />
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {row.type === 'individual' ? 'Individual' : 'Company'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.type === 'individual' ? row.individualName : row.companyName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.type === 'individual' ? (
                        <span>
                          {row.organization && `${row.organization} · `}
                          {row.designation && `${row.designation} · `}
                          {row.gender && `${row.gender} · `}
                          {row.dob && `DoB: ${row.dob}`}
                        </span>
                      ) : ''}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.country}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="mt-6 flex justify-end space-x-3">
            <button
              className="px-3 py-1.5 bg-gray-100 text-gray-700 rounded border border-gray-300 hover:bg-gray-200 transition-colors duration-200 flex items-center text-sm font-medium"
              onClick={() => {
                setCurrentStep('upload');
                setFile(null);
                setParsedData([]);
                setBatchId(null);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m7 7H3" />
              </svg>
              Back
            </button>
            <button
              className="px-3 py-1.5 bg-blue-600 text-white rounded shadow-sm hover:bg-blue-700 transition-colors duration-200 flex items-center text-sm font-medium disabled:bg-blue-300 disabled:cursor-not-allowed"
              onClick={processEntities}
              disabled={parsedData.length === 0}
            >
              <Upload className="h-4 w-4 mr-1.5" />
              Process {parsedData.length} Entities
            </button>
          </div>
        </div>
      )}

      {editingRow && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Edit Entity</h3>
              <button onClick={cancelEditing} className="text-gray-500 hover:text-gray-700">
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="space-y-5 overflow-y-auto flex-1 pr-2">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Type</label>
                <div className="h-[42px]">
                  <select
                    className="w-full h-full px-3 py-2 border border-gray-300 rounded-md"
                    value={editingRow.type}
                    onChange={(e) => setEditingRow({...editingRow, type: e.target.value})}
                  >
                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                  </select>
                </div>
              </div>
              
              {editingRow.type === 'individual' ? (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <div className="h-[42px]">
                      <input
                        type="text"
                        className="w-full h-full px-3 py-2 border border-gray-300 rounded-md"
                        value={editingRow.individualName}
                        onChange={(e) => setEditingRow({...editingRow, individualName: e.target.value})}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Organization</label>
                    <div className="h-[42px]">
                      <input
                        type="text"
                        className="w-full h-full px-3 py-2 border border-gray-300 rounded-md"
                        value={editingRow.organization}
                        onChange={(e) => setEditingRow({...editingRow, organization: e.target.value})}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Designation</label>
                    <div className="h-[42px]">
                      <input
                        type="text"
                        className="w-full h-full px-3 py-2 border border-gray-300 rounded-md"
                        value={editingRow.designation}
                        onChange={(e) => setEditingRow({...editingRow, designation: e.target.value})}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                    <div className="h-[42px]">
                      <GenderSelect 
                        value={editingRow.gender} 
                        onChange={handleGenderChange} 
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
                    <div className="h-[42px]">
                      <DatePicker 
                        value={editingRow.dob} 
                        onChange={handleDateChange} 
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Company Name</label>
                  <div className="h-[42px]">
                    <input
                      type="text"
                      className="w-full h-full px-3 py-2 border border-gray-300 rounded-md"
                      value={editingRow.companyName}
                      onChange={(e) => setEditingRow({...editingRow, companyName: e.target.value})}
                    />
                  </div>
                </div>
              )}
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Country</label>
                <div className="h-[42px]">
                  <CountrySelect 
                    value={editingRow.country} 
                    onChange={handleCountryChange} 
                  />
                </div>
              </div>
            </div>
            
            <div className="mt-6 flex justify-end pt-4 border-t border-gray-200">
              <button
                className="px-3 py-1.5 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition-colors duration-200 flex items-center text-sm font-medium mr-2"
                onClick={cancelEditing}
              >
                <X className="h-4 w-4 mr-1.5" />
                Cancel
              </button>
              <button
                className="px-3 py-1.5 bg-blue-600 text-white rounded shadow-sm hover:bg-blue-700 transition-colors duration-200 flex items-center text-sm font-medium"
                onClick={saveEditing}
              >
                <Check className="h-4 w-4 mr-1.5" />
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {currentStep === 'processing' && (
        <div>
          <h3 className="text-xl font-semibold mb-4">Processing Entities</h3>
          <p className="text-gray-600 mb-6 text-sm">
            Each entity is being searched and added to your search history. 
            <span className="text-blue-600 font-medium"> You can close this window and come back later - processing will continue in the background.</span>
          </p>
          
          <div className="mb-6">
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="text-sm text-gray-600 text-center">
              {parsedData.filter(row => row.processed).length} of {parsedData.length} entities processed 
              ({progress}%)
              {!isProcessing && progress === 100 && (
                <span className="ml-2 text-green-600 font-medium">
                  ✓ Complete
                </span>
              )}
              {!isProcessing && progress < 100 && wasStopped && (
                <span className="ml-2 text-red-600 font-medium">
                  ⚠ Stopped
                </span>
              )}
            </div>
            
            {!isProcessing && wasStopped && (
              <div className="mt-4 bg-yellow-50 border border-yellow-200 p-4 rounded-md">
                <p className="text-yellow-800 text-sm">
                  Import process was stopped. {searchHistoryCount > 0 ? searchHistoryCount : parsedData.filter(row => row.processed).length} of {parsedData.length} entities were processed 
                  and saved to search history.
                </p>
              </div>
            )}
          </div>
          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {parsedData.map((row) => (
                  <tr key={row.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.processed ? (
                        row.error ? (
                          <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Error: {row.error}
                          </span>
                        ) : (
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            row.hasMatches ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'
                          }`}>
                            <Check className="h-4 w-4 mr-1" /> 
                            {row.hasMatches ? `${row.matchCount} Matches Found` : 'No Matches'}
                          </span>
                        )
                      ) : (
                        <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                          Pending
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {row.type === 'individual' ? 'Individual' : 'Company'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.type === 'individual' ? row.individualName : row.companyName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {row.country}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="mt-6 flex justify-end space-x-3">
            {wasStopped && progress < 100 ? (
              <button
                className="px-3 py-1.5 bg-green-600 text-white rounded shadow-sm hover:bg-green-700 transition-colors duration-200 flex items-center text-sm font-medium"
                onClick={continueImport}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
                Continue Processing
              </button>
            ) : (
              <button
                className="px-3 py-1.5 bg-red-600 text-white rounded shadow-sm hover:bg-red-700 transition-colors duration-200 flex items-center text-sm font-medium disabled:bg-red-300 disabled:cursor-not-allowed"
                onClick={stopImport}
                disabled={!isProcessing || progress === 100}
              >
                <X className="h-4 w-4 mr-1.5" />
                {progress === 100 ? 'Completed' : 'Stop Import'}
              </button>
            )}
            <button
              className="px-3 py-1.5 bg-blue-600 text-white rounded shadow-sm hover:bg-blue-700 transition-colors duration-200 flex items-center text-sm font-medium"
              onClick={() => {
                // Clear localStorage and state when ending the import
                if (wasStopped || progress === 100) {
                  localStorage.removeItem('currentBatchId');
                  localStorage.removeItem('currentImportStep');
                  localStorage.removeItem('currentImportUserId');
                  localStorage.removeItem('importWasStopped');
                  setBatchId(null);
                }
                onClose();
              }}
            >
              <Check className="h-4 w-4 mr-1.5" />
              {isProcessing ? 'Go to Search History' : 'Finish'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkImport;