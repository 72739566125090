import React, { useState, useEffect } from 'react';
import { 
  sendEmailVerification,
  PhoneAuthProvider,
  updatePhoneNumber,
  RecaptchaVerifier
} from 'firebase/auth';
import { auth, recaptchaConfig } from '../config/firebase';  // Import auth instance
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import PhoneCodeSelect from './PhoneCodeSelect';
import { DEFAULT_COUNTRY_CODE } from '../constants/countries';

const VerificationPage = () => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const [loading, setLoading] = useState(true);  // Add loading state
  const [resendCooldown, setResendCooldown] = useState(false);
  const COOLDOWN_TIME = 60; // 60 seconds cooldown
  const navigate = useNavigate();
  const isEmulator = process.env.NODE_ENV === 'development' || 
                    window.location.hostname === 'localhost' || 
                    window.location.hostname === '127.0.0.1';
  const [phoneCountry, setPhoneCountry] = useState(DEFAULT_COUNTRY_CODE);

  useEffect(() => {
    // Add a delay to allow auth state to settle
    const timer = setTimeout(() => {
      try {
        // Add null check and safe navigation
        if (!auth?.currentUser) {
          // Clearly log the issue
          console.log('No authenticated user, redirecting to home');
          navigate('/');
          return;
        }
        setLoading(false);
      } catch (error) {
        // Add explicit error handling
        console.error('Error in initial auth check:', error);
        // Safely redirect on error
        navigate('/');
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  // Update helper function to get reCAPTCHA site key
  const getRecaptchaSiteKey = () => {
    const siteKey = recaptchaConfig.siteKey;
    
    if (!siteKey) {
      console.error('Missing reCAPTCHA site key configuration');
      throw new Error('Missing reCAPTCHA configuration');
    }
    
    return siteKey;
  };

  // Update initializeRecaptcha function
  const initializeRecaptcha = async () => {
    if (isEmulator || !showPhoneVerification) return;

    // Clear existing verifier if present
    if (window.recaptchaVerifier) {
      try {
        await window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      } catch (e) {
        console.warn('Error clearing existing reCAPTCHA:', e);
      }
    }

    try {
      // Create a temporary container for the reCAPTCHA
      const recaptchaContainer = document.createElement('div');
      recaptchaContainer.id = 'recaptcha-container';
      document.body.appendChild(recaptchaContainer);

      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        type: 'recaptcha',
        size: 'invisible',
        callback: () => {
          console.log('reCAPTCHA verified');
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
          // Clean up expired verifier
          if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
          }
        }
      });

      await window.recaptchaVerifier.render();
      console.log('reCAPTCHA initialized successfully');

    } catch (error) {
      console.error('Detailed reCAPTCHA initialization error:', {
        error: error,
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      toast.error('Error initializing verification system');
      throw error;
    }
  };

  const getUserKey = (user) => `user_${user.uid}`;

  const checkEmailVerification = async () => {
    try {
      if (isEmulator) {
        const userKey = getUserKey(auth.currentUser);
        const verified = localStorage.getItem(`${userKey}_emailVerified`) === 'true';
        console.log('Checking email verification (emulator):', { verified });
        setEmailVerified(verified);
        if (verified) {
          setShowPhoneVerification(true);
        }
        return;
      }

      // Add check for authenticated user
      if (!auth.currentUser) {
        console.log('No authenticated user found');
        navigate('/');
        return;
      }

      // Only try to reload user in production
      await auth.currentUser?.reload();
      setEmailVerified(auth.currentUser?.emailVerified || false);
      if (auth.currentUser?.emailVerified) {
        setShowPhoneVerification(true);
      }
    } catch (error) {
      // Check if error exists before trying to log it
      if (error) {
        console.error('Error checking email verification:', error);
      }
      // If in development, don't show error toast
      if (!isEmulator) {
        toast.error('Failed to check verification status');
      }
      // Add graceful error handling
      setEmailVerified(false);
    }
  };

  useEffect(() => {
    if (!auth.currentUser) return;

    // Check immediately and set up interval if needed
    checkEmailVerification();
    
    if (!isEmulator) {
      const interval = setInterval(checkEmailVerification, 3000);
      return () => clearInterval(interval);
    }
  }, [auth, isEmulator]);

  const resendEmailVerification = async () => {
    try {
      if (resendCooldown) {
        toast.error('Please wait 60 seconds before requesting another email');
        return;
      }

      if (isEmulator) {
        const userKey = getUserKey(auth.currentUser);
        localStorage.setItem(`${userKey}_emailVerified`, 'true');
        window.dispatchEvent(new Event('storage'));
        setEmailVerified(true);
        setShowPhoneVerification(true);
        toast.success('Email verified! (Development mode)');
        return;
      }

      setResendCooldown(true);
      await sendEmailVerification(auth.currentUser);
      toast.success('Verification email sent!');

      // Start cooldown timer
      let timeLeft = COOLDOWN_TIME;
      const timer = setInterval(() => {
        timeLeft -= 1;
        if (timeLeft <= 0) {
          setResendCooldown(false);
          clearInterval(timer);
        }
      }, 1000);

    } catch (error) {
      console.error('Error sending verification email:', error);
      if (error.code === 'auth/too-many-requests') {
        toast.error('Too many requests. Please try again later.');
      } else {
        toast.error('Failed to send verification email');
      }
    }
  };

  // Update sendVerificationCode function
  const sendVerificationCode = async () => {
    try {
      if (isEmulator) {
        setVerificationId('fake-verification-id');
        toast.success('Verification code sent! (Development mode)');
        return;
      }

      if (!phoneNumber) {
        toast.error('Please enter a valid phone number');
        return;
      }

      // Make sure we have a valid auth instance
      if (!auth) {
        throw new Error('Firebase Auth not initialized');
      }

      // Initialize invisible reCAPTCHA if not already done
      if (!window.recaptchaVerifier) {
        await initializeRecaptcha();
      }

      // Split the combined value to get just the country code
      const [countryCode] = phoneCountry.split('|');
      const formattedPhoneNumber = `+${countryCode}${phoneNumber.replace(/\D/g, '')}`;
      const provider = new PhoneAuthProvider(auth);
      
      console.log('Starting phone number verification...');
      const verificationId = await provider.verifyPhoneNumber(
        formattedPhoneNumber,
        window.recaptchaVerifier
      );
      
      setVerificationId(verificationId);
      toast.success('Verification code sent!');
      
      // Clean up the reCAPTCHA container
      const container = document.getElementById('recaptcha-container');
      if (container) {
        container.remove();
      }
      
    } catch (error) {
      console.error('Detailed error in sendVerificationCode:', {
        error: error,
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      
      if (error.code === 'auth/too-many-requests') {
        toast.error('Too many attempts. Please try again later.');
      } else if (error.code === 'auth/invalid-phone-number') {
        toast.error('Invalid phone number format');
      } else {
        toast.error('Failed to send verification code');
      }

      // Clean up on error
      if (window.recaptchaVerifier) {
        try {
          await window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        } catch (e) {
          console.warn('Error clearing reCAPTCHA:', e);
        }
      }

      // Clean up container on error
      const container = document.getElementById('recaptcha-container');
      if (container) {
        container.remove();
      }
    }
  };

  // Simplify the useEffect for reCAPTCHA
  useEffect(() => {
    if (!isEmulator && showPhoneVerification) {
      initializeRecaptcha();
    }
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, [showPhoneVerification]);

  const verifyPhoneNumber = async () => {
    try {
      if (!auth.currentUser) {
        console.error('No authenticated user found');
        toast.error('Please sign in again');
        navigate('/');
        return;
      }

      // Handle emulator mode first
      if (isEmulator) {
        const userKey = getUserKey(auth.currentUser);
        localStorage.setItem(`${userKey}_phoneVerified`, 'true');
        window.dispatchEvent(new Event('storage'));
        navigate('/search?verified=true');
        return;
      }

      // Phone verification
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await updatePhoneNumber(auth.currentUser, credential);
      
      // Force reload user and wait for it
      await auth.currentUser.reload();
      
      // Update navigation to include verified and firstTime parameters
      window.location.href = `/search?verified=true&firstTime=true`;

    } catch (error) {
      console.error('Phone verification error:', {
        code: error?.code,
        message: error?.message,
        error: error
      });

      await handleVerificationError(error);
    }
  };

  // Helper function to poll verification status
  const pollVerificationStatus = async () => {
    const MAX_ATTEMPTS = 10;
    const POLL_INTERVAL = 1000;
    
    for (let attempt = 0; attempt < MAX_ATTEMPTS; attempt++) {
      try {
        if (!auth.currentUser) return false;
        
        await auth.currentUser.reload();
        const currentUser = auth.currentUser;
        
        if (currentUser.emailVerified && currentUser.phoneNumber) {
          await new Promise(resolve => setTimeout(resolve, 500));
          navigate('/search?verified=true');
          return true;
        }
        
        if (attempt < MAX_ATTEMPTS - 1) {
          await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
        }
      } catch (error) {
        console.error('Polling error:', error);
        if (attempt === MAX_ATTEMPTS - 1) {
          // On final attempt, force a page refresh
          window.location.reload();
        }
        return false;
      }
    }
    // If we've exhausted all attempts, force a page refresh
    window.location.reload();
    return false;
  };

  // Helper function to handle verification errors
  const handleVerificationError = async (error) => {
    if (!error) {
      toast.error('An unknown error occurred. Please try again.');
      return;
    }

    switch (error.code) {
      case 'auth/invalid-verification-code':
        toast.error('Invalid verification code. Please try again.');
        break;
      case 'auth/code-expired':
        toast.error('Verification code has expired. Please request a new one.');
        break;
      case 'auth/requires-recent-login':
        toast.error('Please sign out and sign in again to verify your phone number.');
        await auth.signOut();
        navigate('/');
        break;
      default:
        toast.error('Failed to verify phone number. Please try again.');
    }
  };

  // Show loading state
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-8 sm:py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-2xl sm:text-3xl font-extrabold text-gray-900">
          Account Verification
        </h2>
        {isEmulator && (
          <p className="mt-2 text-center text-xs sm:text-sm text-gray-600">
            Running in development mode - verification steps are simulated
          </p>
        )}
      </div>

      <div className="mt-6 sm:mt-8 w-full sm:mx-auto sm:max-w-md">
        <div className="bg-white py-6 sm:py-8 px-4 sm:px-10 shadow-sm sm:shadow-lg sm:rounded-lg">
          {/* Email Verification Section */}
          <div className={showPhoneVerification ? 'hidden' : ''}>
            <div className="rounded-md bg-yellow-50 p-3 sm:p-4 mb-4 sm:mb-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    {emailVerified ? 'Email verified!' : 'Please verify your email'}
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      {emailVerified 
                        ? 'Thank you for verifying your email. You can now proceed with phone verification.'
                        : 'We sent you a verification email. Please check your inbox and click the verification link.'}
                    </p>
                  </div>
                  {!emailVerified && (
                    <div className="mt-4">
                      <button
                        type="button"
                        onClick={resendEmailVerification}
                        className="text-sm font-medium text-yellow-800 hover:text-yellow-700"
                      >
                        Resend verification email
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Phone Verification Section */}
          {showPhoneVerification && (
            <div className="space-y-4 sm:space-y-6">
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2">
                  Phone Number
                </label>
                <div className="mt-1 flex gap-2">
                  <div className="w-32">
                    <PhoneCodeSelect
                      value={phoneCountry}
                      onChange={setPhoneCountry}
                    />
                  </div>
                  <input
                    type="tel"
                    id="phone"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="123-456-7890"
                    className="flex-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-sm"
                  />
                </div>
              </div>

              <button
                onClick={sendVerificationCode}
                disabled={!phoneNumber}
                className="w-full flex justify-center py-2.5 sm:py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
              >
                Send Verification Code
              </button>

              {verificationId && (
                <>
                  <div>
                    <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1 sm:mb-2">
                      Verification Code
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 text-sm"
                      />
                    </div>
                  </div>

                  <button
                    onClick={verifyPhoneNumber}
                    disabled={!verificationCode}
                    className="w-full flex justify-center py-2.5 sm:py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                  >
                    {isEmulator ? 'Simulate Verification' : 'Verify Phone Number'}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerificationPage;