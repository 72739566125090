// In MonitorButton.js - Replace the current component with this version

import React, { useState, useEffect } from 'react';
import { Bell } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { auth } from '../config/firebase';
import toast from 'react-hot-toast';
import { API_URL } from '../services/api';

const MonitorButton = ({ onClick, result, isMonitored: initialMonitored }) => {
  // Use the prop as initial state if provided
  const [isMonitored, setIsMonitored] = useState(initialMonitored || false);
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(!initialMonitored);

  // Only check monitoring status if not provided as prop
  useEffect(() => {
    // If monitoring status is already provided, skip the check
    if (initialMonitored !== undefined) {
      setInitializing(false);
      setIsMonitored(initialMonitored);
      return;
    }
    
    const checkMonitoringStatus = async () => {
      if (!auth.currentUser || !result?.id) {
        setInitializing(false);
        return;
      }
      
      try {
        // Query monitoring collection for this result ID
        const monitoringRef = collection(db, 'users', auth.currentUser.uid, 'monitoring');
        const q = query(monitoringRef, where('selectedResultId', '==', result.id));
        const snapshot = await getDocs(q);
        
        // If we found any matches, this entity is being monitored
        setIsMonitored(!snapshot.empty);
      } catch (error) {
        console.error('Error checking monitoring status:', error);
      } finally {
        // Set initializing to false when done
        setInitializing(false);
      }
    };
    
    checkMonitoringStatus();
  }, [result?.id, initialMonitored]);

  // Function to handle the toggle
  const handleClick = async () => {
    if (loading) return;
    
    setLoading(true);
    try {
      if (isMonitored) {
        // Remove from monitoring - find the monitoring document first
        const monitoringRef = collection(db, 'users', auth.currentUser.uid, 'monitoring');
        const q = query(monitoringRef, where('selectedResultId', '==', result.id));
        const snapshot = await getDocs(q);
        
        if (snapshot.empty) {
          toast.error('Could not find monitoring record');
          setLoading(false);
          return;
        }
        
        // Get the monitoring document ID
        const monitoringId = snapshot.docs[0].id;
        
        // Call API to remove from monitoring
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch(`${API_URL}/api/monitored-entity/${monitoringId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to remove from monitoring');
        }
        
        // Update local state
        setIsMonitored(false);
        toast.success('Removed from monitoring');
      } else {
        // Call the onClick handler and check its return value
        const success = await onClick();
        
        // Only update the state if the operation was successful
        if (success) {
          setIsMonitored(true);
        }
        // If not successful, we don't update the state
      }
    } catch (error) {
      console.error('Error toggling monitoring status:', error);
      toast.error('Failed to update monitoring status');
    } finally {
      setLoading(false);
    }
  };

  // Don't show until we've initialized
  if (initializing) {
    return (
      <button
        disabled={true}
        className="flex items-center px-3 py-1.5 rounded-md text-sm font-medium bg-gray-100 text-gray-400"
      >
        <Bell className="w-4 h-4 mr-1.5" />
        <span className="opacity-0">Loading</span>
      </button>
    );
  }

  return (
    <button
      onClick={handleClick}
      disabled={loading}
      className={`flex items-center px-3 py-1.5 rounded-md text-sm font-medium ${
        isMonitored
          ? 'bg-green-100 text-green-700 hover:bg-green-200'
          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
      } transition-colors`}
    >
      <Bell className="w-4 h-4 mr-1.5" />
      {loading ? 'Processing...' : (isMonitored ? 'Monitoring' : 'Monitor')}
    </button>
  );
};

export default MonitorButton;