import React from 'react';
import { Bell, X, Check, Trash2, AlertCircle } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import { getRiskTag } from '../utils/formatters';

// Helper function moved from MonitoringDashboard
const cleanAndTransformTag = (tag) => {
  // Remove both 'ai.' prefix and handle any other prefixes
  const cleanTag = tag.replace(/^(ai\.)?/, '');
  return getRiskTag(cleanTag);
};

const NotificationsPanel = ({ 
  show,
  onClose,
  notifications,
  onMarkAllAsRead,
  onDeleteAll,
  onNotificationClick,
  monitoredItems,
  onMarkAsRead,
  onClearAlerts
}) => {
  if (!show) return null;

  // Add detailed debug logging
  console.log('NotificationsPanel render:', {
    notificationsCount: notifications.length,
    notifications: notifications.map(n => ({
      id: n.id,
      entityName: n.entityName,
      entityId: n.entityId,
      read: n.read,
      timestamp: n.timestamp,
      changes: {
        hasChanges: n.changes?.hasChanges,
        messagePresent: !!n.changes?.message,
        importantUpdatesCount: n.changes?.importantUpdates?.length,
        mediaUpdatesCount: n.changes?.mediaUpdates?.length,
        relatedRiskFactorsCount: n.changes?.relatedRiskFactors?.length,
        addedCount: n.changes?.added?.length,
        removedCount: n.changes?.removed?.length
      }
    }))
  });

  // Log any notifications with missing or malformed data
  const problematicNotifications = notifications.filter(n => {
    return !n.changes || !n.entityName || !n.entityId;
  });

  if (problematicNotifications.length > 0) {
    console.warn('Found notifications with missing data:', problematicNotifications);
  }

  return (
    <div className="fixed inset-0 bg-black/20 z-50">
      <div className="absolute right-0 top-0 h-full w-full max-w-xl bg-white shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between px-6 py-4 border-b">
          <div className="flex items-center gap-3">
            <Bell className="w-5 h-5 text-gray-600" />
            <h3 className="text-lg font-semibold text-gray-900">Notifications</h3>
          </div>
          <div className="flex items-center">
            {notifications.length > 0 && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors text-red-500"
                    title="Delete all notifications"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete All Notifications</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will permanently delete all your notifications. This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel onPointerDown={(e) => e.preventDefault()}>
                      Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={onDeleteAll}
                      className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                    >
                      Delete All
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
            <button
              onClick={onMarkAllAsRead}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              title="Mark all as read"
            >
              <Check className="w-5 h-5 text-gray-500" />
            </button>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
        </div>

        {/* Notifications List */}
        <div className="overflow-y-auto h-[calc(100vh-73px)]">
          {notifications.length === 0 ? (
            <div className="h-full flex flex-col items-center justify-center text-center p-4">
              <Bell className="w-12 h-12 text-gray-400 mb-2" />
              <h3 className="text-sm font-medium text-gray-900">No new notifications</h3>
              <p className="mt-1 text-sm text-gray-500">
                When there are updates to your monitored entities, they will appear here
              </p>
            </div>
          ) : (
            notifications.map((notification) => {
              // Add debug logging for each notification
              console.log('Processing notification:', {
                id: notification.id,
                entityName: notification.entityName,
                hasChanges: notification.changes?.hasChanges,
                importantUpdatesCount: notification.changes?.importantUpdates?.length,
                importantUpdates: notification.changes?.importantUpdates,
                mediaUpdatesCount: notification.changes?.mediaUpdates?.length,
                riskFactorsCount: notification.changes?.relatedRiskFactors?.length,
                addedCount: notification.changes?.added?.length,
                removedCount: notification.changes?.removed?.length,
                timestamp: notification.timestamp
              });

              return (
                <div 
                  key={notification.id}
                  onClick={() => {
                    onMarkAsRead(notification.id);
                    if (monitoredItems) {
                      // Always use String for consistent comparison
                      const notificationEntityId = String(notification.entityId || '').trim();
                      
                      // Log entity ID for debugging
                      console.log('Notification click - looking for entity:', {
                        notificationEntityId,
                        entityName: notification.entityName
                      });
                      
                      // Check if we can find the item directly by ID
                      let monitoredItem = monitoredItems.find(item => 
                        String(item.id || '').trim() === notificationEntityId
                      );
                      
                      // If not found by ID, try to find by name
                      if (!monitoredItem) {
                        console.log('Could not find by ID, trying by name...');
                        monitoredItem = monitoredItems.find(item => 
                          (item.individualName && notification.entityName && 
                           item.individualName.toLowerCase() === notification.entityName.toLowerCase()) || 
                          (item.companyName && notification.entityName && 
                           item.companyName.toLowerCase() === notification.entityName.toLowerCase())
                        );
                      }
                      
                      if (monitoredItem) {
                        console.log('Found matching monitored item:', monitoredItem.id);
                        onClearAlerts(monitoredItem.id);
                        onClose();
                        onNotificationClick(monitoredItem);
                      } else {
                        console.error('Could not find matching monitored item:', { 
                          entityId: notification.entityId,
                          entityName: notification.entityName,
                          availableIds: monitoredItems.map(i => i.id)
                        });
                      }
                    }
                  }}
                  className="border-b last:border-b-0 hover:bg-gray-100 transition-colors cursor-pointer"
                >
                  <div className="px-6 py-4">
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 mt-1">
                        <AlertCircle className={`w-5 h-5 ${
                          !notification.read 
                            ? 'text-amber-500'  // Show amber for unread
                            : 'text-gray-400'   // Show gray for read
                        }`} />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h4 className="text-sm font-medium text-gray-900">
                          {notification.entityName || monitoredItems.find(
                            item => item.id === notification.entityId
                          )?.individualName || monitoredItems.find(
                            item => item.id === notification.entityId
                          )?.companyName}
                        </h4>
                        
                        <p className="text-xs text-gray-500 mt-1">
                          {notification.timestamp instanceof Date 
                            ? notification.timestamp.toLocaleString()
                            : notification.timestamp?.seconds 
                              ? new Date(notification.timestamp.seconds * 1000).toLocaleString()
                              : 'Unknown date'
                          }
                        </p>

                        <div className="mt-3 space-y-3">
                          {/* Debug section for development */}
                          {process.env.NODE_ENV === 'development' && (
                            <pre className="text-xs text-gray-500 whitespace-pre-wrap">
                              {JSON.stringify({
                                hasImportantUpdates: notification.changes?.importantUpdates?.length > 0,
                                importantUpdates: notification.changes?.importantUpdates,
                                changes: notification.changes
                              }, null, 2)}
                            </pre>
                          )}

                          {/* New Sanctions changes - MOVED TO TOP */}
                          {notification.changes.added?.length > 0 && (
                            <div className="rounded-md bg-red-50 p-3">
                              <h5 className="text-sm font-medium text-red-800 mb-2">New Sanctions Matches:</h5>
                              <ul className="list-disc pl-4 text-sm text-red-700">
                                {notification.changes.added.map((match, idx) => (
                                  <li key={idx}>{match.sanctionsSources?.[0]?.title || "Global Sanctions List"}</li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* Removed Sanctions - MOVED TO SECOND */}
                          {notification.changes.removed?.length > 0 && (
                            <div className="rounded-md bg-green-50 p-3">
                              <h5 className="text-sm font-medium text-green-800 mb-2">Removed Sanctions Matches:</h5>
                              <ul className="list-disc pl-4 text-sm text-green-700">
                                {notification.changes.removed.map((match, idx) => (
                                  <li key={idx}>{match.sanctionsSources?.[0]?.title || "Global Sanctions List"}</li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* Risk Updates section - NOW THIRD */}
                          {notification.changes?.relatedRiskFactors?.length > 0 && (
                            <div className="rounded-md bg-orange-50 p-3">
                              <h5 className="text-sm font-medium text-orange-800 mb-2">Risk Factors:</h5>
                              <ul className="list-disc pl-4 text-sm text-orange-700">
                                {notification.changes.relatedRiskFactors
                                  .map(cleanAndTransformTag)
                                  .filter(Boolean)
                                  .map((tag, idx) => (
                                    <li key={idx}>{tag}</li>
                                  ))}
                              </ul>
                            </div>
                          )}

                          {/* Important Updates */}
                          {(notification.changes?.significantChanges?.length > 0 || 
                            notification.changes?.importantUpdates?.length > 0) && (
                            <div className="rounded-md bg-yellow-50 p-3">
                              <h5 className="text-sm font-medium text-yellow-800 mb-2">Important Updates:</h5>
                              <ul className="list-disc pl-4 text-sm text-yellow-700">
                                {[
                                  ...(notification.changes.significantChanges || []),
                                  ...(notification.changes.importantUpdates || [])
                                ].map((update, idx) => (
                                  <li key={idx}>{update}</li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* Media Updates */}
                          {notification.changes?.mediaUpdates?.length > 0 && 
                            notification.changes.mediaUpdates[0]?.mediaChanges?.added?.length > 0 && (
                            <div className="rounded-md bg-blue-50 p-3">
                              <h5 className="text-sm font-medium text-blue-800 mb-2">New Media Coverage:</h5>
                              <ul className="space-y-2">
                                {notification.changes.mediaUpdates[0].mediaChanges.added.map((source, idx) => (
                                  <li key={idx} className="text-sm">
                                    <a 
                                      href={source.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={(e) => e.stopPropagation()}
                                      className="text-blue-600 hover:text-blue-800 hover:underline"
                                    >
                                      {source.title}
                                    </a>
                                    {source.date && (
                                      <span className="text-blue-700 text-xs ml-2">
                                        ({new Date(source.date).toLocaleDateString()})
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* No Changes Message */}
                          {notification.changes.hasChanges === false && (
                            <p className="text-sm text-gray-600">
                              {notification.changes.message}
                            </p>
                          )}

                          {/* Debug output during development */}
                          {process.env.NODE_ENV === 'development' && (
                            <pre className="text-xs text-gray-500 mt-2">
                              {JSON.stringify({
                                id: notification.id,
                                changes: notification.changes,
                                timestamp: notification.timestamp
                              }, null, 2)}
                            </pre>
                          )}

                          {/* Debug Information */}
                          {process.env.NODE_ENV === 'development' && (
                            <pre className="mt-4 text-xs text-gray-500 whitespace-pre-wrap">
                              {JSON.stringify({
                                notificationData: notification,
                                changes: notification.changes,
                                mediaUpdates: notification.changes?.mediaUpdates
                              }, null, 2)}
                            </pre>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPanel;